import React, {useState} from "react";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	Button,
} from "@mui/material";
import {fetchDocData, mergeDoc} from "../utils/firebaseFunctions";

interface DisplayTableProps {
	collectionName: string;
	documentId: string;
	data: any;
	setData: React.Dispatch<React.SetStateAction<any>>;
}

const DisplayTable: React.FC<DisplayTableProps> = ({
	collectionName,
	documentId,
	data,
	setData,
}) => {
	const [selectedRow, setSelectedRow] = useState<number | null>(null);
	const [selectedType, setSelectedType] = useState<
		"facts" | "costs" | "client_inputs" | null
	>(null);

	const handleRemove = async () => {
		if (selectedRow !== null && selectedType && data[selectedType]) {
			const updatedData = {
				...data,
				[selectedType]: data[selectedType]?.filter(
					(_: any, index: any) => index !== selectedRow
				),
			};

			await mergeDoc(collectionName, documentId, updatedData);
			setData(await fetchDocData(collectionName, documentId));

			setSelectedRow(null);
			setSelectedType(null);
		}
	};

	return (
		<div>
			{data ? (
				<TableContainer
					component={Paper}
					style={{maxHeight: "400px", overflowY: "auto", marginTop: "20px"}}
				>
					<Table style={{width: "100%", borderCollapse: "collapse"}}>
						<TableHead>
							<TableRow>
								<TableCell>Parameter</TableCell>
								<TableCell>Parameter type</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{["facts", "costs", "client_inputs"].map((type) =>
								data[type]?.map((item: any, index: any) => (
									<TableRow
										key={index}
										onClick={() => {
											setSelectedRow(index);
											setSelectedType(
												type as "facts" | "costs" | "client_inputs"
											);
										}}
										style={{
											backgroundColor:
												selectedType === type && selectedRow === index
													? "#efefef"
													: "transparent",
										}}
									>
										<TableCell>{item.name}</TableCell>
										<TableCell>
											{type === "facts"
												? "Stamgegevens"
												: type === "costs"
												? "Kosten"
												: "Klantgegevens"}
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<p>Loading data...</p>
			)}
			<div
				style={{display: "flex", justifyContent: "flex-end", marginTop: "10px"}}
			>
				{selectedRow !== null && (
					<Button
						variant="contained"
						color="primary"
						style={{
							marginTop: "10px",
							padding: "10px 20px",
							backgroundColor: "#8B0000", // Red background color
							color: "#fff",
							border: "none",
							borderRadius: "5px",
							cursor: "pointer",
							textTransform: "none",
						}}
						onClick={handleRemove}
					>
						Verwijder
					</Button>
				)}
			</div>
		</div>
	);
};

export default DisplayTable;
