import React, {useState, useEffect} from "react";
import DealSelector from "../../components/layout/deal-selector";
import {fetchComputation} from "../utils";
import Main from "../main/Main";
import {
	removeDoc,
	fetchDocData,
	fetchDocDataStream,
} from "../../components/utils/firebaseFunctions";
import StageStepper from "../../components/stage-stepper";
import MeasuresSection from "../../screens/energy-saving-measures";
import {fetchCRMDetails} from "../../components/utils/apiFunctions";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useParams, useNavigate} from "react-router-dom";
import EnergySavingComputationSpecs from "../../screens/energy-saving-computation-specs";
import SelectableProgressBar from "../../components/selectable-progressbar";
import {styles} from "../../components/utils/Styles";
import ComputationInitialize from "../../screens/computation-initialize";
import SectorComparison from "../../screens/energy-saving-sector-graph";
import EnergySavingResultSummary from "../../screens/energy-saving-result";
import EnergySavingContent from "../../screens/energy-saving-report";
import {
	fetchRooms,
	fetchAll,
	fetchInteractions,
	fetchMeasures,
} from "../../components/utils/streamingFunctions";
import EnergySavingReportPrep from "../../screens/energy-saving-report-preparation";
import {updateFilteredSteps} from "../../components/utils/computationInitialization";
import UnauthorizedPage from "../../screens/unauthorized";
import {auth} from "../../config/firebase";

interface EnergySavingProps {}
interface Template {
	[key: string]: any;
}
const steps = [
	"Algemene gegevens",
	"Sector vergelijking",
	"Maatregelen",
	"Rekengegevens",
	"Config resultaat",
	"Tekst rapportage",
	"Rapportage",
];
const pages: Record<string, number> = {
	initialize: 0,
	sector: 1,
	measures: 2,
	"computation details": 3,
	result: 4,
	text: 5,
	report: 6,
};

const EnergySaving: React.FC<EnergySavingProps> = () => {
	const [customClaims, setCustomClaims] = useState<{[key: string]: boolean}>(
		{}
	);
	const {dealId, selectedPageName} = useParams();
	const computationType = "energy_saving_computations";
	// State for usage here only
	const [clientId, setClientId] = useState<string>(dealId || "");
	const navigate = useNavigate();
	const [filteredSteps, setFilteredSteps] = useState<string[]>([]);
	const [showScreens, setShowScreens] = useState<boolean>(false);
	const [activeStep, setActiveStep] = useState<number>(
		selectedPageName ? pages?.[selectedPageName] : 0
	);
	const [generalData, setGeneralData] = useState<any[]>([]);
	const [measures, setMeasures] = useState<any[]>([]);

	const [updateId, setUpdateId] = useState<string>("");
	const [crmDetails, setCRMDetails] = useState<any | null>(null);
	const [roomId, setRoomId] = useState<string>("");
	const [listRooms, setListRooms] = useState<any[]>([]);
	const [computationData, setComputationData] = useState<any>({});
	const [progress, setProgress] = useState<any>({});
	const [loadedClientInputs, setLoadedClientInputs] = useState<any[]>([]);
	const [template, setTemplate] = useState<any[]>([]);
	const [templateText, setTemplateText] = useState<any>({});
	const [templateGeneral, setTemplateGeneral] = useState<Template>({});
	const [globalState, setGlobalState] = useState<{country: string}>({
		country: "NL",
	});
	const [selectedMeasure, setSelectedMeasure] = useState<string>("");
	const [pageReady0, setPageReady0] = useState<boolean>(false);
	const [pageReady1, setPageReady1] = useState<boolean>(false);
	const [pageReady2, setPageReady2] = useState<boolean>(false);
	const [pageReady3, setPageReady3] = useState<boolean>(false);
	const [pageReady4, setPageReady4] = useState<boolean>(false);
	const [pageReady5, setPageReady5] = useState<boolean>(false);
	const [selectedInteraction, setSelectedInteraction] = useState<string>("");
	const [interactionData, setInteractionData] = useState<any[]>([]);

	useEffect(() => {
		const fetchCustomClaims = async () => {
			if (auth.currentUser) {
				try {
					const idTokenResult = await auth.currentUser.getIdTokenResult();
					const customClaims = idTokenResult.claims;
					setCustomClaims(customClaims); // All claims including custom ones
					console.log(customClaims); // You can use these claims to adjust UI components or logic
				} catch (error) {
					console.error("Error fetching custom claims", error);
				}
			} else {
				console.log("No user is currently signed in.");
			}
		};

		fetchCustomClaims();
	}, [auth.currentUser]);

	useEffect(() => {
		if (generalData && generalData.length > 0 && generalData[0].fields) {
			setGlobalState((prevState) => ({
				...prevState,
				country: generalData[0].fields?.QQCT34RNyYnuu1UdpV9j,
			}));
		}
	}, [generalData]);

	useEffect(() => {
		fetchRooms(updateId, computationType, setListRooms);
		fetchAll(updateId, computationType, setGeneralData);
		fetchMeasures(updateId, computationType, setMeasures);
		fetchInteractions(updateId, computationType, setInteractionData);
	}, [updateId]);

	useEffect(() => {
		interactionData.length > 0 &&
			setSelectedInteraction(
				interactionData.find((item) => item.name === "Algemene berekening")?.id
			);
	}, [interactionData]);

	useEffect(() => {
		if (listRooms.length > 0) {
			setRoomId(listRooms.find((item) => item.name_clean === "all")?.id);
		} else {
			setRoomId("");
		}
	}, [listRooms, updateId]);

	useEffect(() => {
		const fetchTemplate = async () => {
			try {
				if (selectedMeasure) {
					const measureData = measures.find(
						(item) => item.id === selectedMeasure
					);
					const templateData: Record<string, any> = {};
					const updatedloadedClientInputs: Record<string, any> = {
						...loadedClientInputs,
					};
					if (measureData?.client_inputs) {
						for (const inputId of measureData?.client_inputs.map(
							(measure: any) => measure.id
						)) {
							// Check if inputId is not in templatekeys
							if (!Object.keys(loadedClientInputs).includes(inputId)) {
								const data = await fetchDocData("client_inputs", inputId);
								if (Object.keys(data).length > 0) {
									templateData[inputId] = data;
									updatedloadedClientInputs[inputId] = data;
								}
							} else {
								templateData[inputId] = loadedClientInputs[inputId];
							}
						}
						setLoadedClientInputs(updatedloadedClientInputs as any);
						setTemplate(templateData as any);
					}
				}
			} catch (error) {
				console.error("Error fetching template: ", error);
				const templateData: Record<string, any> = {};
				setTemplate(templateData as any);

				return null;
			}
		};

		fetchTemplate();
	}, [globalState, selectedMeasure, measures, updateId]);

	useEffect(() => {
		const fetchTemplateGeneral = async () => {
			try {
				let mdvGeneralData: string[] = [];
				const result = await fetchDocData("templates", "mdv_general");
				mdvGeneralData = result?.fields;
				const templateData: Record<string, any> = {};
				for (const inputId of mdvGeneralData) {
					const data = await fetchDocData(`client_inputs`, `${inputId}`);
					templateData[inputId] = data;
				}
				if (templateData) {
					setTemplateGeneral(templateData as any);
				}
			} catch (error) {
				console.error("Error fetching template: ", error);
			}
		};
		fetchTemplateGeneral();
	}, [computationType]);

	useEffect(() => {
		const fetchComputationData = async () => {
			try {
				if (clientId.trim() !== "") {
					await fetchComputation({
						computationType,
						clientId,
						setCRMDetails,
						setUpdateId,
						setRoomId,
						setComputationData,
						setShowScreens,
						setProgress,
						navigate,
					});
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchComputationData();
	}, [clientId, computationType]);

	useEffect(() => {
		const fetchCRMData = async () => {
			// Fetch updated CRM details
			const updatedCRM = await fetchCRMDetails(
				clientId.split("_")[1],
				clientId.split("_")[0]
			);
			if (
				updatedCRM?.data?.requested_deal &&
				updatedCRM?.data.requested_deal === clientId &&
				updatedCRM?.data
			) {
				setCRMDetails(updatedCRM?.data);
			}
		};
		if (clientId !== "") {
			fetchCRMData();
		}
	}, [clientId]);

	useEffect(() => {
		if (showScreens === true) {
			setActiveStep(0);
			setUpdateId(clientId);
		}
	}, [showScreens, clientId]);

	useEffect(() => {
		updateFilteredSteps(
			progress?.progress,
			steps,
			showScreens,
			updateId,
			setFilteredSteps
		);
		const pageReadyList = [
			setPageReady0,
			setPageReady1,
			setPageReady2,
			setPageReady3,
			setPageReady4,
			setPageReady5,
		];

		for (const key in progress?.progress) {
			if (progress.progress[key].done) {
				pageReadyList[parseInt(key)](true);
			} else {
				pageReadyList[parseInt(key)](false);
			}
		}
	}, [progress]);

	useEffect(() => {
		const fetchClientData = async () => {
			try {
				if (updateId) {
					// Subscribe to real-time updates on the progress document
					const unsubscribe = fetchDocDataStream(
						"energy_saving_computations",
						updateId,
						setProgress
					);
					return () => {
						unsubscribe();
					};
				}
			} catch (error) {
				console.error("Error fetching progress data:", error);
			}
		};

		fetchClientData();
	}, [updateId]);

	useEffect(() => {
		if (interactionData.length > 0) {
			interactionData
				.filter((item) => item?.name === undefined)
				.forEach((item) => {
					if (item.id) {
						removeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							item.id
						);
					}
				});
		}
	}, [interactionData.length]);

	const findKeyByValue = (object: any, value: any) => {
		return Object.keys(object).find((key) => object[key] === value);
	};

	useEffect(() => {
		const fetchTextFields = async () => {
			try {
				const templateKeys = await fetchDocData("templates", "mdv_text");
				let template: any = {}; // Initialize template as an empty object

				// Assuming templateKeys.fields is an array of field names
				for (const field of templateKeys.fields) {
					template[field] = await fetchDocData("client_inputs", field);
				}

				setTemplateText(template);
			} catch (error) {
				console.error("Error fetching text fields:", error);
				// Handle the error, e.g., display an error message to the user
			}
		};

		fetchTextFields();
	}, []);

	useEffect(() => {
		if (updateId !== "") {
			const nextPageName = findKeyByValue(pages, activeStep); // Find the name of the next page
			// Navigate to the next page
			navigate(`/energy-saving/${updateId}/${nextPageName}`);
		}
	}, [activeStep, updateId, navigate]);

	const updatedMeasureData = [
		...measures,
		{name: "Rapport", id: "report", rank: -5},
		{name: "Intro: Samenvatting", id: "intro_sector", rank: -4},
		{name: "Intro: Advies", id: "intro_advice", rank: -3},
		{name: "Intro: Impact", id: "intro_impact", rank: -2},
		{name: "Intro: Implementatie", id: "intro_phases", rank: -1},
		{name: "Intro: Appendix", id: "intro_appendix", rank: 0},
	];

	return customClaims &&
		(customClaims?.["praeter-advisor"] ||
			customClaims?.["admin"] ||
			customClaims?.["energy-saving-advisor"]) ? (
		<Main>
			<div
				style={{
					backgroundColor: "#f8971d",
					width: "100vw",
					padding: "10px",
					height: "10px",
				}}
			>
				<h2
					style={{
						textAlign: "center",
						marginTop: -9,
						color: "white",
						fontSize: "18px",
						fontFamily: "basic",
						fontWeight: "bold",
					}}
				>
					Maatwerkadvies duurzaam vastgoed
				</h2>
			</div>
			<div
				style={{
					display: "flex",
					backgroundColor: "#f0f0f0",
					height: "calc(100vh)",
				}}
			>
				<div style={{display: "flex"}}>
					<div style={{flex: 1, maxWidth: "250px"}}>
						<DealSelector
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
						/>
						<StageStepper
							steps={steps}
							activeStep={activeStep}
							handleStepChange={setActiveStep}
							filteredSteps={filteredSteps}
						/>
					</div>
					<div style={{flex: 2}}>
						{activeStep === 0 && (
							<ComputationInitialize
								updateId={updateId}
								crmDetails={crmDetails}
								computationData={computationData}
								setComputationData={setComputationData}
								generalData={generalData}
								templateGeneral={templateGeneral}
								roomId={roomId}
								setRoomId={setRoomId}
								listRooms={listRooms}
								setListRooms={setListRooms}
								pageReady={pageReady0}
								computationType={"energy_saving_computations"}
							/>
						)}
						{activeStep === 1 && (
							<SectorComparison
								updateId={updateId}
								interactionData={interactionData}
								selectedInteraction={selectedInteraction}
								pageReady={pageReady1}
								template={templateGeneral}
							/>
						)}
						{activeStep === 2 && (
							<MeasuresSection
								template={templateText}
								updateId={updateId}
								measures={measures}
								listRooms={listRooms}
								interactionData={interactionData}
								pageReady={pageReady2}
							/>
						)}
						{activeStep === 3 && measures && measures.length > 0 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={measures}
									selectedInteraction={selectedMeasure}
									setSelectedInteraction={setSelectedMeasure}
									differences={[]}
									computationType="energy_saving_computations"
								/>
								<EnergySavingComputationSpecs
									updateId={updateId}
									selectedInteraction={selectedInteraction || roomId}
									measures={measures}
									loadedClientInputs={loadedClientInputs}
									selectedMeasure={selectedMeasure}
									interactionData={interactionData}
									pageReady={pageReady3}
									template={template}
								/>
							</div>
						)}
						{activeStep === 4 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={measures}
									selectedInteraction={selectedMeasure}
									setSelectedInteraction={setSelectedMeasure}
									differences={[]}
									computationType="energy_saving_computations"
								/>
								<EnergySavingResultSummary
									updateId={updateId}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									measures={measures}
									selectedMeasure={selectedMeasure}
									generalData={generalData}
									pageReady={pageReady4}
								/>
							</div>
						)}
						{activeStep === 5 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<EnergySavingReportPrep
									updateId={updateId}
									pageReady={pageReady5}
									generalData={generalData}
									template={templateText}
								/>
							</div>
						)}
						{activeStep === 6 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={updatedMeasureData}
									selectedInteraction={selectedMeasure}
									setSelectedInteraction={setSelectedMeasure}
									differences={[]}
									computationType="energy_saving_computations"
								/>

								<EnergySavingContent
									updateId={updateId}
									generalData={generalData}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									measures={measures}
									selectedMeasure={selectedMeasure}
								/>
							</div>
						)}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginRight: "20px",
								position: "absolute",
								bottom: 50,
								right: 80,
							}}
						>
							{activeStep !== 6 && (
								<IconButton
									onClick={() => setActiveStep((prevStep) => prevStep + 1)}
									disabled={
										activeStep === steps.length - 1 ||
										showScreens === false ||
										filteredSteps.slice(activeStep + 1).length === 0
									}
									size="large"
								>
									<ArrowForwardIcon
										style={{color: "#f8971d", fontSize: "45px"}}
									/>
								</IconButton>
							)}
						</div>
					</div>
				</div>
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default EnergySaving;
