import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {styles} from "../utils/Styles";
import renderInputFields from "../utils/renderInputFields";
import {mergeDoc} from "../utils/firebaseFunctions";

interface GeneralInputPipedriveProps {
	updateId: string;
	crmDetails: {
		organization: {
			city: string;
			name: string;
			street: string;
			street_number: string;
			id: string;
		};
		person: {
			email: string;
			first_name: string;
			last_name: string;
		};
	};
	computationType: string;
	formData: Record<string, any>;
	setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
	generalData: any;
	roomId: string;
	setRoomId: React.Dispatch<React.SetStateAction<string>>;
	template: Record<string, any>;
}

const GeneralInputPipedrive: React.FC<GeneralInputPipedriveProps> = ({
	updateId,
	crmDetails,
	computationType,
	generalData,
	formData,
	setFormData,
	roomId,
	setRoomId,
	template,
}) => {
	const {t} = useTranslation();
	const [submitted, setSubmitted] = useState(false);
	const [change, setChange] = useState(false);

	const updateFormWithCRMDetails = async () => {
		setFormData((prevData) => ({
			...prevData,
			UY3UR611KOx9pSjxNlu6: crmDetails?.organization?.city || "",
			YfRokLRi9yJOY7TMKo0k: crmDetails?.organization?.name || "",
			sFPN0DboUTDB8oJr90St: crmDetails?.organization?.street || "",
			"0scJJYOAMFTyTltNXtNL": crmDetails?.organization?.street_number || "",
			sfTawMuK26GQXWtfjLcv: crmDetails?.person?.email || "",
			dq6eFy6WMh75LGoOTXDy: crmDetails?.person?.first_name || "",
			s9wXjYI6ZzLfEgmy4Ow5: crmDetails?.person?.last_name || "",
			"5xrjtBgD8t4DQsKCjOwW": crmDetails?.organization?.id || "",
		}));
	};

	useEffect(() => {
		if (updateId && Object.keys(template).length > 0) {
			const filteredRecords: {[key: string]: any} = {};

			Object.keys(template).forEach((key) => {
				const record = template[key];
				if (
					record.input_categories &&
					(record?.input_categories.includes("Contactgegevens") ||
						record?.input_categories.includes("Bedrijfsgegevens"))
				) {
					filteredRecords[key] = record;
				}
			});
			const templateKeys = Object.keys(filteredRecords);

			if (generalData.length > 0 && generalData[0].fields) {
				const allTemplateKeysPresentAndNotEmpty = templateKeys.every((key) => {
					const formDataValue = generalData[0].fields?.[key];
					return (
						formDataValue !== undefined &&
						formDataValue !== "" &&
						formDataValue !== null
					);
				});
				setSubmitted(allTemplateKeysPresentAndNotEmpty);
			} else {
				setSubmitted(false);
			}
		}
	}, [updateId, template, generalData]);

	useEffect(() => {
		const someFieldsNullOrEmpty =
			formData?.["UY3UR611KOx9pSjxNlu6"] === undefined ||
			formData?.["UY3UR611KOx9pSjxNlu6"] === "" ||
			formData?.["YfRokLRi9yJOY7TMKo0k"] === undefined ||
			formData?.["YfRokLRi9yJOY7TMKo0k"] === "" ||
			formData?.["sFPN0DboUTDB8oJr90St"] === undefined ||
			formData?.["sFPN0DboUTDB8oJr90St"] === "" ||
			formData?.["sfTawMuK26GQXWtfjLcv"] === undefined ||
			formData?.["sfTawMuK26GQXWtfjLcv"] === "" ||
			formData?.["dq6eFy6WMh75LGoOTXDy"] === undefined ||
			formData?.["dq6eFy6WMh75LGoOTXDy"] === "" ||
			formData?.["s9wXjYI6ZzLfEgmy4Ow5"] === undefined ||
			formData?.["s9wXjYI6ZzLfEgmy4Ow5"] === "";

		if (someFieldsNullOrEmpty) {
			if (crmDetails?.organization) {
				updateFormWithCRMDetails();
			}
		}
	}, [crmDetails]);

	useEffect(() => {
		if (change === true && Object.keys(formData).length > 0) {
			mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
				fields: formData,
				report_ready: false,
			});
			setChange(false);
		}
	}, [change]);

	return (
		<div
			style={{
				display: "flex",
				backgroundColor: "#f0f0f0",
				height: "calc(100vh)",
			}}
		>
			<div
				style={{
					...styles.rightPanelStyle,
					minHeight: "90vh",
					maxHeight: "90vh",
					minWidth: "300px",
					position: "relative",
				}}
			>
				<div>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						{t("contactDetails")}
					</h3>
					{renderInputFields({
						template: template,
						formData: formData,
						setFormData: setFormData,
						setChange: setChange,
						t: t,
						filter: "Contactgegevens",
					})}
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						{t("companyDetails")}
					</h3>
					{renderInputFields({
						template: template,
						formData: formData,
						setFormData: setFormData,
						setChange: setChange,
						t: t,
						filter: "Bedrijfsgegevens",
					})}
				</div>
			</div>
		</div>
	);
};

export default GeneralInputPipedrive;
