import React, {useState, useEffect} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../config/firebase";
import {
	fetchEBSReport,
	// fetchMDVTemplate,
	fetchPDFTemplate,
	saveToSharepoint,
	// fetchMDVReport
} from "../../components/utils/apiFunctions";
import {
	fetchDocData,
	fetchDocsData,
	mergeDoc,
} from "../../components/utils/firebaseFunctions";
import {removeFolder} from "../../components/utils/s3ImageOperations";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import SaveIcon from "@mui/icons-material/Save";
import {CircularProgress, IconButton} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import PDFViewer from "../../components/pdf-viewer";
import GetAppIcon from "@mui/icons-material/GetApp";
import {downloadMultipleFiles} from "../../components/pdf-viewer";
import {useTranslation} from "react-i18next";

interface InteractionData {
	id: string;
	rank: number;
	computation_present: boolean;
	pdf_present: boolean;
	fields: {
		[key: string]: any; // Define the fields' types properly
	};
	sector: any;
}

interface GeneralData {
	id: string;
	report_link: string;
	fields: {
		eLL3U03GcWipjoW0DkzU: string;
		QQCT34RNyYnuu1UdpV9j: string;
		UY3UR611KOx9pSjxNlu6: string;
		s9wXjYI6ZzLfEgmy4Ow5: string;
		vatxUDBKEj3Xf6k5vRtV: number;
		"0scJJYOAMFTyTltNXtNL": string;
		"5xrjtBgD8t4DQsKCjOwW": string;
		YfRokLRi9yJOY7TMKo0k: string;
	};
	report_ready: boolean;
}

interface ContentProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionData[];
	generalData: GeneralData[];
	measures: any[];
	selectedMeasure: string;
}

const EnergyScreeningContent: React.FC<ContentProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
	measures,
	selectedMeasure,
}) => {
	const [downloadURL, setDownloadURL] = useState("");
	const [downloadURLDocx, setDownloadURLDocx] = useState("");

	const {t} = useTranslation();
	const [scale, setScale] = useState(1.5);
	// const [numPages, setNumPages] = useState(1);
	const [loadingReport, setLoadingReport] = useState(false);
	// const [loadingMeasures, setLoadingMeasures] = useState(false);
	const [loadingIntro, setLoadingIntro] = useState(false);
	// const [reportPayload, setReportPayload] = useState({});
	const [savingFile, setSavingFile] = useState(false);
	const selectedMeasureName = measures.find(
		(item) => item.id === selectedMeasure
	)?.name_clean;
	const [freeText, setFreeText] = useState({});
	// const [isDownloading, setIsDownloading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let updateFreeText: {[key: string]: string} = {}; // Provide type annotation
				const data: any = await fetchDocData(
					`energy_screening_computations/${updateId}/text_inputs`,
					"all"
				);
				for (const key of Object.keys(data.fields)) {
					const dataInput: any = await fetchDocData(
						// Provide type annotation
						`client_inputs`,
						key
					);
					updateFreeText[dataInput.name_clean] = data.fields[key];
				}
				if (Object.keys(updateFreeText).length > 0) {
					setFreeText(updateFreeText);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [updateId]);

	useEffect(() => {
		let newUrl = "";
		if (["report"].includes(selectedMeasure)) {
			newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/energy_screening_computations/${updateId}/all/output/${selectedMeasure}.pdf`;
		} // setUrl(ref(storage, newUrl));

		const fetchDownloadURL = async () => {
			try {
				const download = await getDownloadURL(ref(storage, newUrl));
				setDownloadURL(download);
				const downloadDocx = await getDownloadURL(
					ref(storage, newUrl.replace(".pdf", ".docx"))
				);
				setDownloadURLDocx(downloadDocx);
			} catch (error) {
				console.error("Error fetching download URL:", error);
				setDownloadURL("");
			}
		};

		fetchDownloadURL();
	}, [
		selectedInteraction,
		loadingReport,
		selectedMeasure,
		updateId,
		selectedMeasureName,
	]);

	const handleSaveAction = async () => {
		setSavingFile(true);
		let pathPrefix = "02 Energieke Regio";
		if (generalData[0].fields?.eLL3U03GcWipjoW0DkzU === "Praeter") {
			pathPrefix = "01 Praeter";
		}
		const pathPrefixShort = pathPrefix === "01 Praeter" ? "PR" : "ER";
		const companyId = generalData[0].fields?.["5xrjtBgD8t4DQsKCjOwW"];
		const companyName = generalData[0].fields?.["YfRokLRi9yJOY7TMKo0k"];
		const cleanedCompanyName = companyName.replace(/[^a-zA-Z0-9\s]/g, "");

		const filesToSave = ["report.pdf", "report.docx"];

		Promise.all(
			filesToSave.map((file) =>
				saveToSharepoint(
					updateId,
					"energy_screening_computations",
					"all",
					`${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/EBS/${updateId}`,
					file
				)
			)
		)
			.then(() => {
				console.log("saved");
			})
			.catch((error) => {
				console.error("Failed to save files:", error);
			})
			.finally(() => {
				console.log(
					`https://praeter.sharepoint.com/Gedeelde documenten/02 Klanten/${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/EBS/${updateId}`
				);
				mergeDoc(
					`energy_screening_computations/${updateId}/general_inputs`,
					"all",
					{
						report_link: `https://praeter.sharepoint.com/Gedeelde documenten/02 Klanten/${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/EBS/${updateId}`,
					}
				);
				setSavingFile(false); // This ensures it's set to false regardless of success or failure
			});
	};

	useEffect(() => {
		const fetchIntroduction = async () => {
			const currentDate = new Date();
			const formattedDate = formatDate(currentDate).split(" ")[0];
			const allData: any = generalData[0];

			if (
				generalData &&
				generalData[0]?.fields &&
				generalData[0]?.report_ready !== true &&
				loadingIntro === false &&
				interactionData.filter((item) => item.computation_present === false)
					.length === 0 &&
				freeText &&
				Object.keys(freeText).length > 0
			) {
				try {
					await removeFolder(
						`/energy_screening_computations/${updateId}/all/output`
					);
				} catch {
					console.log("Folder not found");
				}
				setLoadingIntro(true);
				setLoadingReport(true);

				const payload2 = await fetchDocsData(
					`energy_screening_computations/${updateId}/measures`
				);
				const generalDataFields: any = generalData[0].fields;
				const payloadIntro: any = {
					free_text: freeText,
					layout:
						generalData[0].fields?.eLL3U03GcWipjoW0DkzU === "Praeter"
							? "PR"
							: "ER",

					measures: payload2,
					date: formattedDate,
					org_name: generalDataFields?.YfRokLRi9yJOY7TMKo0k,
					date_meeting: generalDataFields?.["5lC7RUNxnuMuLCfIDFPr"],
					org_adres:
						generalDataFields?.["sFPN0DboUTDB8oJr90St"] +
						" " +
						generalDataFields?.["0scJJYOAMFTyTltNXtNL"] +
						", " +
						generalDataFields?.["UY3UR611KOx9pSjxNlu6"],
				};

				for (const key in allData.fields) {
					if (allData.fields.hasOwnProperty(key)) {
						const fieldValue = allData.fields[key];

						try {
							const docData = await fetchDocData(`client_inputs`, key);
							if (docData) {
								payloadIntro[docData.name_clean] = fieldValue;
							} else {
								console.log(`Document with key ${fieldValue} not found.`);
							}
						} catch (error) {
							console.error(
								`Error fetching document with key ${fieldValue}:`,
								error
							);
						}
					}
				}
				console.log(JSON.stringify(payloadIntro));
				await fetchEBSReport(updateId, payloadIntro);
				await fetchPDFTemplate(
					updateId,
					"all",
					"energy_screening_computations"
				);

				setLoadingReport(false);

				await mergeDoc(
					`energy_screening_computations/${updateId}/general_inputs`,
					`all`,
					{report_ready: true}
				);
			}
		};

		fetchIntroduction();
	}, [
		interactionData,
		generalData,
		loadingIntro,
		updateId,
		freeText,
		measures,
		selectedInteraction,
	]);

	const handleZoomIn = () => {
		setScale(scale + 0.1);
	};

	const handleZoomOut = () => {
		if (scale > 0.1) {
			setScale(scale - 0.1);
		}
	};

	const formatDate = (date: Date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		const hours = String(date.getHours()).padStart(2, "0");
		const minutes = String(date.getMinutes()).padStart(2, "0");
		const seconds = String(date.getSeconds()).padStart(2, "0");

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	};

	return (
		<div
			style={{
				position: "relative",
				overflowY: "auto",
				maxWidth: "65vw",
				maxHeight: "95vh",
				minHeight: "95vh",
				minWidth: "65vw",
				marginLeft: "10px",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 10,
					right: 10,
					marginRight: "10px",
					marginTop: "10px",
					zIndex: 1,
				}}
			>
				<button
					onClick={handleZoomIn}
					style={{
						color: "#267FB8",
						fontSize: "35px",
						background: "transparent",
						border: "none",
					}}
				>
					{" "}
					+{" "}
				</button>
				<button
					onClick={handleZoomOut}
					style={{
						color: "#267FB8",
						fontSize: "40px",
						background: "transparent",
						border: "none",
					}}
				>
					-{" "}
				</button>
				<a
					href={generalData[0]?.report_link}
					target="_blank"
					rel="noreferrer"
					style={{
						textDecoration: "none",
						display: "flex",
						alignItems: "center",
						color: generalData[0]?.report_link
							? "#267FB8"
							: "rgba(0, 0, 0, 0.26)",
					}}
				>
					<LinkIcon
						style={{
							fontSize: "24px",
							marginRight: "4px",
						}}
					/>
					<span style={{fontSize: "16px"}}>Link</span>
				</a>
				<IconButton
					onClick={handleSaveAction}
					disabled={savingFile || generalData[0]?.report_ready === false}
					style={{
						color: savingFile === true ? "white" : "#267FB8",
						fontSize: "45px",
						top: -4,
						right: 60,
						position: "absolute",
						background: "transparent",
						border: "none",
						marginLeft: "10px",
						marginTop: "10px",
					}}
				>
					{" "}
					<SaveIcon />
				</IconButton>
				<IconButton
					onClick={() => downloadMultipleFiles([downloadURL, downloadURLDocx])}
					disabled={generalData[0]?.report_ready === false}
					style={{
						color: "#267FB8",
						fontSize: "45px",
						top: 28,
						right: 60,
						position: "absolute",
						background: "transparent",
						border: "none",
						marginLeft: "10px",
						marginTop: "10px",
					}}
				>
					<GetAppIcon />
				</IconButton>
			</div>
			{loadingReport === true ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "150px",
						}}
					>
						{t("computationLoading")}
					</h6>
					<CircularProgress />
				</div>
			) : (
				downloadURL && <PDFViewer downloadURL={downloadURL} scale={scale} />
			)}
		</div>
	);
};

export default EnergyScreeningContent;
