import React, {useState, useEffect} from "react";
import {
	fetchDocData,
	fetchDocsData,
	mergeDoc,
	deleteCollection,
} from "../../components/utils/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
// import renderSummary from "../../components/utils/renderSummary";
import GeneralProjectInput from "../../components/general-project-input";
import {copyFolder} from "../../components/utils/s3ImageOperations";
import {removeFolder} from "../../components/utils/s3ImageOperations";
import MWASelectorPanel from "../../components/computation-solar-selector";
import renderInputFields from "../../components/utils/renderInputFields";
import {useTranslation} from "react-i18next";
import {CircularProgress} from "@mui/material";

async function createAndUpdateComputationInputs(
	updateId: string,
	name: string,
	missingRank: number,
	generalData: any[],
	selectedInteraction: string,
	docData: any,
	results: any,
	resultsAll: any
) {
	await mergeDoc(
		`solar_rental_computations/${updateId}/computation_inputs`,
		selectedInteraction,
		{
			...docData,
			name: name,
			rank: missingRank,
		}
	);
	await mergeDoc(
		`solar_rental_computations/${updateId}/general_inputs`,
		"all",
		resultsAll
	);

	if (selectedInteraction) {
		await mergeDoc(
			`solar_rental_computations/${updateId}/results`,
			selectedInteraction,
			results
		);
		await copyFolder(
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/${selectedInteraction}/images`,
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_rental_computations/${updateId}/${selectedInteraction}/images`
		);

		await copyFolder(
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/${selectedInteraction}/output`,
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_rental_computations/${updateId}/${selectedInteraction}/output`
		);
	}
}

interface FieldInfo {
	input_categories: string[];
	data_type: string;
}

interface TemplateUnfiltered {
	fields: Record<string, FieldInfo>;
}

interface Props {
	updateId: string;
	computationData: any;
	generalData: any[];
	setComputationData: React.Dispatch<React.SetStateAction<any>>;
	roomId: string;
	setRoomId: React.Dispatch<React.SetStateAction<string>>;
	pageReady: boolean;
	computationType: string;
	interactionData: any;
	template: TemplateUnfiltered;
	templatePV: TemplateUnfiltered;
}

function areAllFieldsFilled(
	template: TemplateUnfiltered,
	data: Record<string, string>
): boolean {
	const requiredCategories = ["MWA gegevens"];
	if (template?.fields === undefined) {
		return false;
	}
	const requiredFields = Object.keys(template.fields).reduce(
		(filteredRecords, key) => {
			const record = template.fields[key];
			if (
				Array.isArray(record.input_categories) &&
				record.input_categories.some((category) =>
					requiredCategories.includes(category)
				)
			) {
				filteredRecords[key] = record;
			}
			return filteredRecords;
		},
		{} as Record<string, FieldInfo>
	);
	if (requiredFields === undefined) {
		return false;
	}
	return Object.keys(requiredFields).every((field) => {
		// Check if the field exists in data and it's not empty
		return data?.[field] && data[field] !== "";
	});
}

const RentalInitialize: React.FC<Props> = ({
	updateId,
	computationData,
	generalData,
	setComputationData,
	roomId,
	setRoomId,
	pageReady,
	computationType,
	interactionData,
	template,
	templatePV,
}) => {
	const [formDataPV, setFormDataPV] = useState<TemplateUnfiltered>({
		fields: {},
	});
	const [selectedOption, setSelectedOption] = useState<string>("");
	const [interactionDataPV, setInteractionDataPV] = useState<any>([]);
	const [interactionResults, setInteractionResults] = useState<any>({});
	// const [newInteractionResults, setNewInteractionResults] = useState<any>({});
	const [formData, setFormData] = useState<any>(
		generalData.length > 0 ? generalData[0]?.fields : {}
	);
	const [change, setChange] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const {t} = useTranslation();

	useEffect(() => {
		if (interactionData && interactionData.length > 0) {
			const mwaElement = interactionData.find((element: any) =>
				element.name.includes("MWA")
			);
			if (mwaElement) {
				setSelectedOption(mwaElement.id);
				fetchDocData(
					`solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/results`,
					mwaElement.id
				).then((result) => {
					setInteractionResults(result);
				});
			}
		}
	}, [interactionData, generalData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const data = await fetchDocData(
					`solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/general_inputs`,
					"all"
				);
				const dataInteraction = await fetchDocsData(
					`solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/computation_inputs`
				);
				setInteractionDataPV(dataInteraction as any);
				setFormDataPV(data as any);
				if (Object.keys(formData).length === 0) {
					setFormData(data?.fields);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		if (generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV) {
			fetchData();
		}
	}, [computationData, generalData]);

	useEffect(() => {
		if (computationData) {
			let status = loading;
			if (generalData.length > 0 && Object.keys(template.fields).length > 0) {
				if (status === false) {
					status = areAllFieldsFilled(template, generalData[0]?.fields);
				}
				if (!(status === pageReady)) {
					mergeDoc(computationType, updateId, {
						progress: {
							0: {
								done: status,
								name: "General information",
							},
						},
						approved: false,
					});
				}
			}
		}
	}, [
		template,
		pageReady,
		generalData,
		updateId,
		computationType,
		computationData,
		loading,
	]);

	useEffect(() => {
		if (change === true && Object.keys(formData).length > 0) {
			mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
				fields: formData,
				report_ready: false,
			});
			setChange(false);
		}
	}, [change, formData, computationType, updateId]);

	const handleChange = async (event: React.ChangeEvent<{value: unknown}>) => {
		setLoading(true);
		await deleteCollection(
			`solar_rental_computations/${updateId}/computation_inputs`
		);
		await deleteCollection(`solar_rental_computations/${updateId}/results`);
		await removeFolder(`/solar_rental_computations/${updateId}`);
		const value = event.target.value as string;
		setSelectedOption(value);
		const docData = await fetchDocData(
			`solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/computation_inputs`,
			value
		);
		const results = await fetchDocData(
			`solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/results`,
			value
		);
		setInteractionResults(results);
		const resultsAll = await fetchDocData(
			`solar_energy_computations/${generalData[0]?.fields?.ZOzZUApotNfocQBtxxUV}/general_inputs`,
			"all"
		);
		await createAndUpdateComputationInputs(
			updateId,
			"MWA " +
				computationData?.ZOzZUApotNfocQBtxxUV +
				": " +
				interactionDataPV.find((element: any) => element.id === value).name,
			0,
			generalData,
			value,
			docData,
			results,
			resultsAll
		);
		setLoading(false);
	};

	return (
		<div>
			{updateId && updateId !== undefined && (
				<div
					style={{
						overflowY: "auto",
						marginLeft: "10px",
						display: "flex",
						minHeight: "100vh",
						maxHeight: "100vh",
					}}
				>
					<div
						style={{
							flex: 1,
							maxHeight: "90vh",
							textAlign: "left",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{/* Display the list of values */}
						<GeneralProjectInput
							updateId={updateId}
							formData={computationData}
							setFormData={setComputationData}
							generalData={generalData}
							roomId={roomId}
							setRoomId={setRoomId}
							template={template.fields}
							computationType={computationType}
							filter={"MWA gegevens"}
						/>
					</div>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "10px",
							minWidth: "300px",
							maxHeight: "90vh",
							position: "relative",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("contactDetails")}
						</h3>
						{templatePV &&
							formDataPV &&
							formDataPV.fields &&
							Object.keys(templatePV).length &&
							Object.keys(formDataPV.fields).length > 0 &&
							renderInputFields({
								template: templatePV.fields as any,
								formData: formData,
								t: t,
								setFormData: setFormData,
								setChange: setChange,
								filter: "Contactgegevens",
							})}
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("companyDetails")}
						</h3>
						{templatePV &&
							formDataPV &&
							formDataPV.fields &&
							Object.keys(templatePV).length &&
							Object.keys(formDataPV.fields).length > 0 &&
							renderInputFields({
								template: templatePV.fields as any,
								formData: formData,
								t: t,
								setFormData: setFormData,
								setChange: setChange,
								filter: "Bedrijfsgegevens",
							})}
					</div>
					{loading === false && (
						<MWASelectorPanel
							selectedOption={selectedOption}
							handleChange={handleChange}
							interactionDataPV={interactionDataPV}
							interactionResults={interactionResults}
						/>
					)}

					{loading === true && <CircularProgress />}
				</div>
			)}
		</div>
	);
};

export default RentalInitialize;
