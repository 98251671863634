import React, {useState, useEffect} from "react";
import {
	TextField,
	Autocomplete,
	CircularProgress,
	Box,
	Typography,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";

interface Product {
	id: string;
	name: string;
	phase: string; // Add the phase field here
	// Add other relevant fields
}

interface ProductSelectorProps {
	products: Product[];
	onSelect: (product: Product | null) => void;
	loading: boolean;
}

const ProductSelector: React.FC<ProductSelectorProps> = ({
	products,
	onSelect,
	loading,
}) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedPhase, setSelectedPhase] = useState("");
	const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

	useEffect(() => {
		const filtered = products.filter(
			(product) =>
				product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
				(selectedPhase === "" || product.phase === selectedPhase)
		);
		setFilteredProducts(filtered);
	}, [searchTerm, selectedPhase, products]);

	const phases = [...new Set(products.map((product) => product.phase))];

	return (
		<Box>
			<ToggleButtonGroup
				value={selectedPhase}
				exclusive
				onChange={(e, newPhase) => setSelectedPhase(newPhase)}
				aria-label="phase selection"
				sx={{marginBottom: 2}}
			>
				<ToggleButton value="" aria-label="all phases">
					All
				</ToggleButton>
				{phases
					.filter((phase) => phase && phase.trim() !== "") // Filter out null, undefined, or whitespace-only phases
					.map((phase) => (
						<ToggleButton key={phase} value={phase} aria-label={phase}>
							{phase}
						</ToggleButton>
					))}
			</ToggleButtonGroup>

			<Autocomplete
				options={filteredProducts}
				getOptionLabel={(option) => option.name}
				loading={loading}
				onInputChange={(_, newInputValue) => setSearchTerm(newInputValue)}
				onChange={(_, newValue) => onSelect(newValue)}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Search Products"
						variant="outlined"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				renderOption={(props, option) => (
					<Box component="li" {...props}>
						<Typography variant="body2">{option.name}</Typography>
					</Box>
				)}
			/>
		</Box>
	);
};

export default ProductSelector;
