import React, {useEffect, useState, useCallback} from "react";
import MaterialUITable from "../../components/sortable-table";
import {styles} from "../../components/utils/Styles";
import {Switch} from "@mui/material"; // Import the Switch component from Material-UI
import {
	fetchDocsData,
	mergeDoc,
	fetchDocsSnap,
	fetchDocData,
	createDoc,
	fetchDocsDataStream,
} from "../../components/utils/firebaseFunctions";

interface DataInputProps {
	collectionName: string;
}
interface Template {}

// Define the Field type if it's not already defined
interface Field {
	// Define the properties of the Field type
}

const DataInput: React.FC<DataInputProps> = ({collectionName}) => {
	const [formData, setFormData] = useState<Record<string, any>>({});
	const [data, setData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const [selectedId, setSelectedId] = useState<string | null>(null);
	const [template, setTemplate] = useState<Record<string, any>>({});
	const [dropdownOptionsData, setDropdownOptionsData] = useState<any[]>([]);
	const [showCreateButton, setShowCreateButton] = useState<boolean>(true);
	const [showUpdateButton, setShowUpdateButton] = useState<boolean>(false);

	useEffect(() => {
		const checkExistingDocument = async () => {
			try {
				if (formData.name_clean) {
					const nameClean = formData.name_clean;
					const existingDocs = data.find((doc) => doc.name_clean === nameClean);
					if (existingDocs) {
						setShowCreateButton(false);
						setShowUpdateButton(true);
						setSelectedId(existingDocs.id);
					} else {
						setShowCreateButton(true);
						setShowUpdateButton(false);
					}
				}
			} catch (error) {
				console.error("Error checking existing document: ", error);
			}
		};

		checkExistingDocument();
	}, [formData.name_clean, collectionName, data]);

	const fetchTemplate = useCallback(async () => {
		try {
			const querySnapshot = await fetchDocsSnap(
				`templates/${collectionName}/columns`
			);
			const templateData: Record<string, any> = {};
			if (querySnapshot) {
				querySnapshot.docs.forEach((doc) => {
					const {data_type, name, order, mandatory, options} = doc.data();
					templateData[doc.id] = {data_type, name, order, mandatory, options};
				});
			}

			const sortedTemplateData: Template = Object.entries(templateData)
				.sort(([, a], [, b]) => a.order - b.order)
				.reduce((acc: Record<string, Field>, [key, value]) => {
					acc[key] = value;
					return acc;
				}, {});
			return sortedTemplateData;
		} catch (error) {
			console.error("Error fetching template: ", error);
			return null;
		}
	}, [collectionName]);

	const handleInputChange = (fieldId: string, value: any) => {
		if (template[fieldId]?.data_type === "ID") {
			const selectedOption = dropdownOptionsData.find(
				(option) => option.id === value
			);
			setFormData((prevData) => ({
				...prevData,
				[fieldId]: selectedOption?.id || "",
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[fieldId]: value,
			}));
		}
	};

	const updateDocument = async () => {
		try {
			if (!selectedId || Object.keys(formData).length === 0) {
				console.log("No document selected or no data to update");
				return;
			}

			Object.entries(template).forEach(([fieldId, fieldInfo]) => {
				if (!(fieldId in formData)) {
					formData[fieldId] = fieldInfo.data_type === "BOOLEAN" ? false : "";
				} else {
					if (
						fieldInfo.data_type === "INTEGER" ||
						fieldInfo.data_type === "DECIMAL"
					) {
						formData[fieldId] = Number(formData[fieldId]);
					}
				}
			});

			mergeDoc(collectionName, selectedId, formData);
		} catch (error) {
			console.error("Error updating document: ", error);
		}
	};

	const fetchDropdownOptions = useCallback(async () => {
		try {
			let optionsData: any = [];
			if (collectionName === "energy_saving_measures") {
				optionsData = await fetchDocsData("energy_saving_categories");
			} else if (collectionName === "energy_screening_measures") {
				optionsData = await fetchDocsData("energy_screening_categories");
			}

			setDropdownOptionsData(optionsData);
		} catch (error) {
			console.error("Error fetching dropdown options: ", error);
		}
	}, []);

	const handleStringListChange = (
		fieldId: string,
		index: number,
		value: any
	) => {
		setFormData((prevData) => {
			const updatedList = [...(prevData[fieldId] || [])];
			updatedList[index] = value;
			return {...prevData, [fieldId]: updatedList};
		});
	};

	const handleAddStringListItem = (fieldId: string) => {
		setFormData((prevData) => ({
			...prevData,
			[fieldId]: [...(prevData[fieldId] || []), ""],
		}));
	};

	const handleRemoveStringListItem = (fieldId: string, index: number) => {
		setFormData((prevData) => {
			const updatedList = [...(prevData[fieldId] || [])];
			updatedList.splice(index, 1);
			return {...prevData, [fieldId]: updatedList};
		});
	};

	const fetchSelectedDocument = useCallback(async () => {
		if (!selectedId) return;
		setFormData(await fetchDocData(collectionName, selectedId));
	}, [selectedId, collectionName]);

	useEffect(() => {
		fetchSelectedDocument();
	}, [fetchSelectedDocument]);

	const fetchData = (
		collectionName: string,
		setData: React.Dispatch<React.SetStateAction<any[]>>
	) => {
		return fetchDocsDataStream(collectionName, setData);
	};

	useEffect(() => {
		const fetchDataAndDropdown = async () => {
			fetchData(collectionName, setData);
			fetchDropdownOptions();
			const templateData = await fetchTemplate();
			if (templateData) {
				setTemplate(templateData);
			}
			setLoading(false);
		};
		fetchDataAndDropdown();
	}, [collectionName]);

	const addDocument = useCallback(async () => {
		try {
			const documentData = {...formData};

			const nameClean = formData.name_clean;
			const existingDocs = data.find((doc) => doc.name_clean === nameClean);

			if (existingDocs) {
				const existingDocId = existingDocs.id;
				mergeDoc(collectionName, existingDocId, documentData);
				console.log("Document updated successfully!");
			} else {
				Object.entries(template).forEach(([fieldId, fieldInfo]) => {
					if (!(fieldId in documentData)) {
						documentData[fieldId] =
							fieldInfo.data_type === "BOOLEAN" ? false : "";
					} else {
						if (
							fieldInfo.data_type === "INTEGER" ||
							fieldInfo.data_type === "DECIMAL"
						) {
							documentData[fieldId] = Number(documentData[fieldId]);
						}
					}
				});
				await createDoc(collectionName, documentData);
				console.log("Document added successfully!");
			}
		} catch (error) {
			console.error("Error adding/updating document: ", error);
		}
	}, [formData, template, collectionName, data]);

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (e.key === "Enter") {
				addDocument();
			}
		};
		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [addDocument]);

	const renderInputFields = () => {
		return Object.entries(template).map(([fieldId, fieldInfo]) => (
			<div key={fieldId} style={styles.inputWrapper}>
				<label>
					{fieldInfo.mandatory && <span style={{color: "red"}}>*</span>}
					{fieldInfo.name}
				</label>
				{fieldInfo.data_type === "INTEGER" ? (
					<input
						type="number"
						value={formData[fieldId] || ""}
						onChange={(e) => handleInputChange(fieldId, e.target.value)}
						style={styles.input}
					/>
				) : fieldInfo.data_type === "DECIMAL" ? (
					<input
						type="number"
						step="any"
						value={formData[fieldId] || ""}
						onChange={(e) => handleInputChange(fieldId, e.target.value)}
						style={styles.input}
					/>
				) : fieldInfo.data_type === "BOOLEAN" ? (
					<Switch
						checked={formData[fieldId] || false}
						onChange={(e) => handleInputChange(fieldId, !formData[fieldId])}
						color="primary"
					/>
				) : fieldInfo.data_type === "ID" ? (
					renderDropdown(fieldId)
				) : fieldInfo.data_type === "STRING_LIST" ? (
					<div>
						{(formData[fieldId] || []).map((item: string, index: number) => (
							<div
								key={index}
								style={{
									display: "flex",
									alignItems: "center",
									marginBottom: "4px",
								}}
							>
								<input
									type="text"
									value={item || ""}
									onChange={(e) =>
										handleStringListChange(fieldId, index, e.target.value)
									}
									style={{...styles.input, marginRight: "8px"}}
								/>
								<button
									onClick={() => handleRemoveStringListItem(fieldId, index)}
									style={{marginRight: "8px"}}
								>
									x
								</button>
							</div>
						))}
						<button onClick={() => handleAddStringListItem(fieldId)}>+</button>
					</div>
				) : (
					<input
						type="text"
						value={formData[fieldId] || ""}
						onChange={(e) => handleInputChange(fieldId, e.target.value)}
						style={styles.input}
					/>
				)}
			</div>
		));
	};

	const renderDropdown = (fieldId: string) => (
		<select
			value={formData[fieldId] || ""}
			onChange={(e) => handleInputChange(fieldId, e.target.value)}
			style={styles.input}
		>
			<option value="" disabled>
				Select an option
			</option>
			{dropdownOptionsData.map((option) => (
				<option key={option.id} value={option.id}>
					{option.name}
				</option>
			))}
		</select>
	);

	return (
		<div
			style={{display: "flex", ...styles.rightPanelStyle, marginLeft: "10px"}}
		>
			<div>
				<div style={{maxWidth: "15vw"}}>{renderInputFields()}</div>
				{showCreateButton && (
					<button
						onClick={addDocument}
						style={{
							marginLeft: "20px",
							padding: "10px 20px",
							backgroundColor: "#4CAF50",
							color: "#fff",
							border: "none",
							borderRadius: "5px",
							cursor: "pointer",
						}}
					>
						Voeg toe
					</button>
				)}
				{showUpdateButton && (
					<button
						onClick={updateDocument}
						style={{
							marginLeft: "20px",
							padding: "10px 20px",
							backgroundColor: "#4CAF50",
							color: "#fff",
							border: "none",
							borderRadius: "5px",
							cursor: "pointer",
						}}
					>
						Update
					</button>
				)}
			</div>
			<div>
				{loading ? (
					<p>Loading...</p>
				) : (
					<MaterialUITable
						data={data}
						selectedId={selectedId}
						setSelectedId={setSelectedId}
						collectionName={collectionName}
						sortedTemplateData={template}
					/>
				)}
			</div>
		</div>
	);
};

export default DataInput;
