import React, {useState, useEffect} from "react";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TextField,
	TableSortLabel,
	Select,
	MenuItem,
	InputLabel,
	Grid,
} from "@mui/material";
import {removeDoc} from "../utils/firebaseFunctions";

interface TableRowData {
	id: string;
	[key: string]: any;
}

interface MaterialUITableProps {
	data: TableRowData[];
	selectedId: string | null;
	setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
	collectionName: string;
	sortedTemplateData: Record<string, any>;
}

const DeleteButton: React.FC<{
	selectedId: string;
	collectionName: string;
	onDelete: () => void;
}> = ({selectedId, collectionName, onDelete}) => {
	const handleDelete = async () => {
		removeDoc(collectionName, selectedId);
		onDelete();
	};

	return (
		<button
			onClick={handleDelete}
			style={{
				marginTop: "10px",
				padding: "10px 20px",
				backgroundColor: "#8B0000",
				color: "#fff",
				border: "none",
				borderRadius: "5px",
				cursor: "pointer",
			}}
		>
			Delete Document
		</button>
	);
};

const MaterialUITable: React.FC<MaterialUITableProps> = ({
	data,
	selectedId,
	setSelectedId,
	collectionName,
	sortedTemplateData,
}) => {
	const [defaultColumns, setDefaultColumns] = useState<string[]>([]);
	const [filter, setFilter] = useState("");
	const [tableData, setTableData] = useState<TableRowData[]>(data);
	const [orderBy, setOrderBy] = useState<string>("");
	const [order, setOrder] = useState<"asc" | "desc">("asc");
	const [selectedColumns, setSelectedColumns] = useState<string[]>([
		"id",
		"name",
		"name_clean",
	]);

	useEffect(() => {
		const fetchDefaultColumns = async () => {
			const columns = ["id"].concat(Object.keys(sortedTemplateData));
			setDefaultColumns(columns);
			setSelectedColumns(["id", "name", "name_clean"]); // Initially, all columns are selected
		};
		fetchDefaultColumns();
	}, [collectionName]);

	useEffect(() => {
		setTableData(data);
	}, [data]);

	const handleRowClick = (id: string) => {
		setSelectedId(id);
	};

	const handleDelete = () => {
		const updatedData = tableData.filter((row) => row.id !== selectedId);
		const filteredData = updatedData.filter((item) =>
			Object.values(item).some((value) =>
				value.toString().toLowerCase().includes(filter.toLowerCase())
			)
		);
		setTableData(filteredData);
		setSelectedId(null);
	};

	const handleSort = (column: string) => {
		const isAsc = orderBy === column && order === "asc";
		setOrderBy(column);
		setOrder(isAsc ? "desc" : "asc");
		const sortedData = [...tableData].sort((a, b) => {
			if (a[column] < b[column]) {
				return isAsc ? -1 : 1;
			}
			if (a[column] > b[column]) {
				return isAsc ? 1 : -1;
			}
			return 0;
		});
		setTableData(sortedData);
	};

	const handleColumnChange = (event: React.ChangeEvent<{value: unknown}>) => {
		const selectedColumns = event.target.value as string[];
		setSelectedColumns(selectedColumns);
	};

	return (
		<div style={{marginLeft: "40px"}}>
			<Grid container spacing={2} alignItems="center">
				<Grid item>
					<InputLabel id="column-selector-label" style={{fontFamily: "Karla"}}>
						Vind waarde
					</InputLabel>

					<TextField
						label="Zoek in tabel"
						variant="outlined"
						value={filter}
						onChange={(e) => setFilter(e.target.value)}
						style={{marginTop: "0px", marginBottom: "20px"}}
					/>
				</Grid>
				<Grid item>
					<InputLabel id="column-selector-label" style={{fontFamily: "Karla"}}>
						Selecteer kolommen
					</InputLabel>
					<Select
						labelId="column-selector-label"
						id="column-selector"
						multiple
						value={selectedColumns}
						onChange={handleColumnChange as any}
						style={{marginBottom: "20px", maxWidth: "500px"}}
					>
						{["id"].concat(Object.keys(sortedTemplateData)).map((column) => (
							<MenuItem key={column} value={column}>
								{column}
							</MenuItem>
						))}
					</Select>
				</Grid>
			</Grid>

			{/* Table */}
			<TableContainer
				component={Paper}
				style={{maxHeight: "80vh", overflowY: "auto", maxWidth: "60vw"}}
			>
				<Table>
					<TableHead>
						<TableRow>
							{selectedColumns.map((column) => (
								<TableCell
									key={column}
									style={{cursor: "pointer", fontFamily: "Barlow:wght@200"}}
								>
									<TableSortLabel
										active={orderBy === column}
										direction={orderBy === column ? order : "asc"}
										onClick={() => handleSort(column)}
									>
										{column}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{tableData
							.filter((item) =>
								Object.values(item).some((value) =>
									value
										?.toString()
										?.toLowerCase()
										.includes(filter.toLowerCase())
								)
							)
							.map((row) => (
								<TableRow
									key={row.id}
									onClick={() => handleRowClick(row.id)}
									style={{
										backgroundColor:
											selectedId === row.id ? "#efefef" : "transparent",
									}}
								>
									{selectedColumns.map((column) => (
										<TableCell
											key={column}
											style={{fontFamily: "Barlow:wght@200"}}
										>
											{sortedTemplateData[column]?.data_type === "BOOLEAN"
												? row[column]
													? "true"
													: "false"
												: sortedTemplateData[column]?.data_type ===
												  "STRING_LIST"
												? Array.isArray(row[column])
													? row[column].join(", ")
													: row[column]
												: sortedTemplateData[column]?.data_type === "MAP"
												? JSON.stringify(row[column])
												: row[column]}
										</TableCell>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{selectedId && (
				<DeleteButton
					selectedId={selectedId}
					collectionName={collectionName}
					onDelete={handleDelete}
				/>
			)}
		</div>
	);
};

export default MaterialUITable;
