import React, {useState, useEffect, useCallback} from "react";
import {TextField, List, ListItem, ListItemText} from "@mui/material";
import {styles} from "../../utils/Styles";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {fetchDocsData} from "../../utils/firebaseFunctions";
import {addDocumentComputation} from "../../utils/computationInitialization";
import {useTranslation} from "react-i18next";
import _ from "lodash";

interface DealSelectorProps {
	clientId: string;
	setClientId: React.Dispatch<React.SetStateAction<string>>;
	setShowScreens: React.Dispatch<React.SetStateAction<boolean>>;
	crmDetails?: any;
	computationType: string;
}

const ClientInfo: React.FC<{clientInfo: any; clientId: string; t: any}> = ({
	clientInfo,
	clientId,
	t,
}) => {
	if (!clientInfo || clientId !== clientInfo.requested_deal) {
		return null;
	}

	const {organization, person, pipeline, stage} = clientInfo;

	const fields = [
		{label: "clientNumber", value: organization?.id},
		{label: "organization", value: organization?.name},
		{
			label: "person",
			value: person && `${person.first_name} ${person.last_name}`,
		},
		{label: "email", value: person?.email},
		{label: "pipeline", value: pipeline?.name},
		{label: "stage", value: stage?.name},
	];

	return (
		<div
			style={{
				fontFamily: "Basic",
				lineHeight: "1.25",
				marginBottom: "10px",
				color: styles.DealSelector.color,
				maxWidth: "250px",
				wordWrap: "break-word",
				fontSize: "14px",
			}}
		>
			{fields.map((field, index) => (
				<div key={index}>
					<strong>{t(field.label)}</strong>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-start",
						}}
					>
						<span style={{textAlign: "right"}}>{field.value}</span>
					</div>
				</div>
			))}
		</div>
	);
};

interface Computation {
	id: string;
}

const DealSelector: React.FC<DealSelectorProps> = ({
	clientId,
	setClientId,
	setShowScreens,
	crmDetails,
	computationType,
}) => {
	const {t} = useTranslation();
	const [showSearchBar, setShowSearchBar] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [computationsData, setComputationsData] = useState<Computation[]>([]);
	const [clientIdInput, setClientIdInput] = useState(clientId || "");

	const delayedSetClientId = useCallback(
		_.debounce((value: string) => {
			setClientId(value);
			//   setShowScreens(true);
		}, 500), // Adjust delay as needed
		[]
	);
	const handleClientIdChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setShowScreens(false);
		const regex = /^(ER|PR)_\d+$/; // Regex pattern to match "ER_{digits}" or "PR_{digits}"
		setClientIdInput(event.target.value);
		// Define debounce function for delayed setClientId

		if (regex.test(event.target.value)) {
			delayedSetClientId(event.target.value);

			// setClientId(event.target.value);
		} else {
			setClientId("");
		}
	};

	const handleToggleSearchBar = () => {
		setShowSearchBar(!showSearchBar);
	};

	useEffect(() => {
		const fetchData = async () => {
			const computationUpdate: any = await fetchDocsData(computationType);
			setComputationsData(computationUpdate);
		};

		fetchData();
	}, [computationType]);

	const handleSelectComputation = async (computation: Computation) => {
		setClientId(computation.id);
		setClientIdInput(computation.id);
		setShowSearchBar(false);
		setSearchQuery("");
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};

	const filteredComputations = (computationsData as {id: string}[]).filter(
		(computation) =>
			computation.id.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const handleAddNew = () => {
		const regex = /^(ER|PR)_\d+$/;
		if (regex.test(clientIdInput)) {
			addDocumentComputation(clientIdInput, computationType);
			setShowScreens(true);
		}
	};

	return (
		<div
			style={{
				padding: "20px",
				borderRadius: "5px",
				minHeight: "40vh",
				backgroundColor: styles.DealSelector.backgroundColor,
				minWidth: "200px",
				color: styles.DealSelector.color,
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
			}}
		>
			<TextField
				label={t("computationId")}
				value={clientIdInput}
				onChange={handleClientIdChange}
				variant="standard"
				margin="normal"
				fullWidth
				InputProps={{
					style: {
						backgroundColor: "#fff",
						borderRadius: "3px",
						padding: "10px",
						fontFamily: "Karla",
						borderColor: "blue",
						position: "relative",
					},
				}}
				InputLabelProps={{
					style: {
						color: clientIdInput ? "white": "black",
						fontWeight: clientIdInput ? "bold": "normal",
						transform: clientIdInput ? "translate(0, -10px) scale(0.8)" : "translate(10px, 30px) scale(1)", 
						fontFamily: "Karla",
						position: "absolute",
						zIndex: 1,
					},
				}}
			/>

			<div style={{display: "flex", justifyContent: "flex-end"}}>
				<div style={{display: "flex", alignItems: "center"}}>
					<SearchIcon
						onClick={handleToggleSearchBar}
						style={{cursor: "pointer", marginRight: "5px"}}
					/>
					<AddIcon onClick={handleAddNew} style={{cursor: "pointer"}} />
				</div>
			</div>
			{crmDetails !== undefined && crmDetails && !showSearchBar && (
				<ClientInfo clientId={clientId} clientInfo={crmDetails} t={t} />
			)}
			{showSearchBar && (
				<TextField
					label={t("search")}
					value={searchQuery}
					onChange={handleSearchChange}
					variant="outlined"
					margin="normal"
					fullWidth
					InputProps={{
						style: {
							backgroundColor: "#fff",
							borderRadius: "3px",
							padding: "0px",
							borderColor: "red",
						},
					}}
					InputLabelProps={{
						style: {
							color: "black",
						},
					}}
				/>
			)}
			{showSearchBar && (
				<List style={{maxHeight: "200px", overflowY: "auto"}}>
					{searchQuery &&
						showSearchBar &&
						filteredComputations.map((computation) => (
							<ListItem
								key={computation.id}
								onClick={() => handleSelectComputation(computation)}
							>
								<ListItemText primary={computation.id} />
							</ListItem>
						))}
				</List>
			)}
		</div>
	);
};

export default DealSelector;
