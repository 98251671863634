import React, {useState, useEffect, ChangeEvent} from "react";
import GeneralInputPipedrive from "../../components/general-input-pipedrive";
import {styles} from "../../components/utils/Styles";
import {
	createDoc,
	removeDoc,
	mergeDoc,
} from "../../components/utils/firebaseFunctions";
import GeneralProjectInput from "../../components/general-project-input";

interface FieldInfo {
	input_categories: string[];
	data_type: string;
	conditional_field: string;
}

interface Room {
	id: string;
	name: string;
	name_clean: string;
}

interface Props {
	updateId: string;
	crmDetails: any;
	computationData: any;
	generalData: any[];
	templateGeneral: any;
	setComputationData: React.Dispatch<React.SetStateAction<any>>;
	roomId: string;
	setRoomId: React.Dispatch<React.SetStateAction<string>>;
	listRooms: Room[];
	setListRooms: React.Dispatch<React.SetStateAction<Room[]>>;
	pageReady: boolean;
	computationType: string;
}

function areAllFieldsFilled(
	templateGeneral: any,
	data: Record<string, string>,
	requiredCategories: string[] = [
		"Contactgegevens",
		"Bedrijfsgegevens",
		"Gegevens project"]
): boolean {
	if (templateGeneral === undefined) {
		return false;
	}
	const requiredFields = Object.keys(templateGeneral).reduce(
		(filteredRecords, key) => {
			const record = templateGeneral[key];
			if (
				Array.isArray(record.input_categories) &&
				record.input_categories.some(
					(category: any) =>
						requiredCategories.includes(category) &&
						record.conditional_field === ""
				)
			) {
				filteredRecords[key] = record;
			}
			return filteredRecords;
		},
		{} as Record<string, FieldInfo>
	);
	if (requiredFields === undefined) {
		return false;
	}
	return Object.keys(requiredFields).every((field) => {
		return data?.[field] && data[field] !== "";
	});
}

const ComputationInitialize: React.FC<Props> = ({
	updateId,
	crmDetails,
	computationData,
	generalData,
	templateGeneral,
	setComputationData,
	roomId,
	setRoomId,
	listRooms,
	pageReady,
	computationType,
}) => {
	const [listRoomsValue, setListRoomsValue] = useState("");
	const filter = (computationType === "energy_saving_computations" ? "Gegevens project MDV" : "Gegevens project");


	useEffect(() => {
		if (computationData) {
			let status = false;
			if (generalData.length > 0 && Object.keys(templateGeneral).length > 0) {
				status = areAllFieldsFilled(
					templateGeneral as any,
					generalData[0]?.fields, 
					["Contactgegevens", "Bedrijfsgegevens", filter]
				);
				if (!(status === pageReady)) {
					mergeDoc(computationType, updateId, {
						progress: {
							0: {
								done: status,
								name: "General information",
							},
						},
						approved: false,
					});
				}
			}
		}
	}, [
		templateGeneral,
		generalData,
		updateId,
		pageReady,
		computationType,
		computationData,
	]);

	const handleAddToList = async () => {
		try {
			if (listRoomsValue.trim() !== "") {
				await createDoc(`${computationType}/${updateId}/rooms`, {
					name: listRoomsValue,
					name_clean: listRoomsValue.toLowerCase().replace(/[^a-z0-9]/g, "_"),
				});
				setListRoomsValue("");
			}
		} catch (error) {
			console.error("Error adding room:", error);
		}
	};

	const handleRemoveFromList = async (roomIdToRemove: string) => {
		try {
			if (updateId) {
				await removeDoc(`${computationType}/${updateId}/rooms`, roomIdToRemove);
			}
		} catch (error) {
			console.error("Error removing room:", error);
		}
	};

	const handleListInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setListRoomsValue(event.target.value);
	};

	return (
		<div>
			{updateId && updateId !== undefined && templateGeneral !== undefined && (
				<div style={{overflowY: "auto", marginLeft: "10px", display: "flex"}}>
					<div style={{flex: 1}}>
						<GeneralInputPipedrive
							updateId={updateId}
							crmDetails={crmDetails}
							computationType={computationType}
							generalData={generalData}
							formData={computationData}
							setFormData={setComputationData}
							roomId={roomId}
							setRoomId={setRoomId}
							template={templateGeneral}
						/>
					</div>
					<div style={{flex: 1, textAlign: "left", marginLeft: "10px"}}>
						<GeneralProjectInput
							updateId={updateId}
							formData={computationData}
							setFormData={setComputationData}
							generalData={generalData}
							roomId={roomId}
							setRoomId={setRoomId}
							template={templateGeneral}
							computationType={computationType}
							filter={filter}
						/>
					</div>
					<div style={{flex: 1, textAlign: "left", marginLeft: "10px"}}>
						{[
							"energy_saving_computations",
							"energy_solar_computations",
						].includes(computationType) && (
							<div
								style={{
									marginBottom: "20px",
									...styles.rightPanelStyle,
									minWidth: "200px",
									minHeight: "30vh",
									maxHeight: "30vh",
									maxWidth: "300px",
								}}
							>
								<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
									{" "}
									Ruimten{" "}
								</h3>
								<label style={{...styles.labelLower}}>Ruimte Toevoegen</label>
								<div style={{display: "flex", alignItems: "center"}}>
									<input
										type="text"
										value={listRoomsValue}
										onChange={handleListInputChange}
										style={styles.input}
									/>
									<button
										onClick={handleAddToList}
										style={{marginLeft: "10px"}}
									>
										+
									</button>
								</div>
								{listRooms &&
									listRooms.length > 0 &&
									listRooms.map((room, index) => {
										if (room.name_clean !== "all") {
											return (
												<div
													key={index}
													style={{
														display: "flex",
														alignItems: "center",
														marginBottom: "4px",
													}}
												>
													<input
														type="text"
														value={room.name}
														onChange={(e) => handleListInputChange(e)}
														style={styles.summaryValueLower}
													/>
													<button
														onClick={() => handleRemoveFromList(room.id)}
														style={{marginLeft: "8px"}}
													>
														x
													</button>
												</div>
											);
										}
										return null;
									})}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ComputationInitialize;
