import React from "react";
import {useTranslation} from "react-i18next";
import Flag from "react-world-flags";

const LanguageSwitcher: React.FC = () => {
	const {i18n} = useTranslation();

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
			<Flag
				code="GB" // or any other country code for the flag
				style={{cursor: "pointer", margin: "0 3px", width: "24px"}}
				onClick={() => changeLanguage("en")}
				alt="English"
			/>
			<Flag
				code="NL" // or any other country code for the flag
				style={{cursor: "pointer", margin: "0 3px", width: "24px"}}
				onClick={() => changeLanguage("nl")}
				alt="Nederlands"
			/>
		</div>
	);
};

export default LanguageSwitcher;
