import React, {useState, useEffect} from "react";
import {FormControl, Select, MenuItem, Box} from "@mui/material";
import {mergeDoc} from "../../components/utils/firebaseFunctions";
import BarPlot from "../../components/plots/Barplot";
import {
	fetchSectorComparison,
	fetchSectorOptions,
	fetchSurfaceOptions,
} from "../../components/utils/apiFunctions";
import InputSpec from "../../components/computation-input-specific";
import {styles} from "../../components/utils/Styles";

interface SectorComparisonProps {
	updateId: string;
	pageReady: boolean;
	interactionData: any; // Adjust type appropriately
	selectedInteraction: string;
	template: Record<string, any>;
}

interface SectorOption {
	name: string;
	min: number;
	max: number;
}

const SectorComparison: React.FC<SectorComparisonProps> = ({
	updateId,
	interactionData,
	selectedInteraction,
	pageReady,
	template,
}) => {
	const [selectedSector, setSelectedSector] = useState<string>(
		interactionData.find((item: any) => item.id === selectedInteraction)?.sector
			?.sector || ""
	);
	const [surfaceOptions, setSurfaceOptions] = useState([]);
	const [sectorOptions, setSectorOptions] = useState([]);
	// const [template, setTemplate] = useState({});
	const [sectorData, setSectorData] = useState({});
	const [sectorRequestData, setSectorRequestData] = useState({});

	useEffect(() => {
		if (
			interactionData.find((item: any) => item.id === selectedInteraction)
				?.sector
		) {
			let status = true;

			if (!(status === pageReady)) {
				mergeDoc("energy_saving_computations", updateId, {
					progress: {
						1: {
							done: status,
							name: "Sector stats",
						},
					},
				});
			}
		}
	}, [interactionData, updateId, pageReady, selectedInteraction]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				fetchSectorOptions().then((data) => {
					setSectorOptions(data?.data); // Set the fetched sector options in state
				});

				fetchSurfaceOptions().then((data) => {
					setSurfaceOptions(data?.data); // Set the fetched sector options in state
				});
			} catch (error) {
				console.error("Error fetching sector options:", error);
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchComparison = async () => {
			try {
				if (Object.keys(surfaceOptions).length > 0 && selectedSector) {
					const surfaceNum = interactionData.find(
						(item: any) => (item.id = selectedInteraction)
					)?.fields?.["a12MED5Glc3OUX9ibBdo"];
					const selectedSurface: any = surfaceOptions.find(
						(item: SectorOption) =>
							item.min < surfaceNum && item.max >= surfaceNum
					);
					if (selectedSurface) {
						const data = {
							gas_usage: interactionData.find(
								(item: any) => (item.id = selectedInteraction)
							)?.fields?.["FUqkOlbZMiD2Rcavh3F0"],
							electricity_usage: interactionData.find(
								(item: any) => (item.id = selectedInteraction)
							)?.fields?.["HCYFCWj7cfFJGmrV73Od"],
							sector: selectedSector,
							surface_category: selectedSurface.name,
							surface_m2: surfaceNum,
						};

						if (JSON.stringify(data) !== JSON.stringify(sectorRequestData)) {
							setSectorRequestData(data);
							fetchSectorComparison(data).then((response3) => {
								mergeDoc(
									`energy_saving_computations/${updateId}/computation_inputs`,
									`${selectedInteraction}`,
									{sector: response3.data}
								);
								mergeDoc(
									`energy_saving_computations/${updateId}/general_inputs`,
									`all`,
									{report_ready: false}
								);
								setSectorData(response3?.data);
							});
						}
					}
				}
			} catch (error) {
				console.error("Error fetching sector options:", error);
			}
		};

		fetchComparison();
	}, [
		selectedSector,
		surfaceOptions,
		interactionData,
		selectedInteraction,
		sectorRequestData,
		updateId,
	]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "flex-start",
			}}
		>
			<Box
				sx={{
					...styles.rightPanelStyle,
					marginLeft: "10px",
					minWidth: "250px",
					minHeight: "90vh",
					textTransform: "none", // Ensure textTransform matches the TextTransform type
				}}
			>
				<div>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						Sectorvergelijking
					</h3>
					<InputSpec
						updateId={updateId}
						interactionId={selectedInteraction}
						interactionData={interactionData}
						templateUnfiltered={template}
						filter={"Sectorvergelijking"}
						computationType={"energy_saving_computations"}
					/>
				</div>
			</Box>
			<div
				style={{
					marginLeft: "10px",
					...styles.rightPanelStyle,
					minHeight: "90vh",
					minWidth: "600px",
				}}
			>
				{sectorOptions.length > 0 && (
					<FormControl
						style={{
							fontFamily: "Barlow",
							fontSize: 16,
							minWidth: "320px",
							background: "white",
						}}
					>
						<Select
							value={selectedSector}
							defaultValue={
								selectedSector !== ""
									? selectedSector
									: "Selecteer geldige sector"
							}
							onChange={(e) => {
								const newValue = e.target.value as string;
								if (newValue) {
									mergeDoc(
										`energy_saving_computations/${updateId}/general_inputs`,
										"all",
										{
											fields: {
												GsDB70v67m9H1BPZylSC: newValue,
											},
											report_ready: false,
										}
									);
								}
								setSelectedSector(newValue);
							}}
							displayEmpty
							style={{fontFamily: "Karla", fontSize: 16}}
						>
							<MenuItem value="" disabled>
								Selecteer geldige sector
							</MenuItem>
							{sectorOptions.map((sector, index) => (
								<MenuItem key={index} value={sector}>
									{sector}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				{sectorData ? <BarPlot data={sectorData as any} /> : null}
			</div>
		</div>
	);
};

export default SectorComparison;
