import {auth} from "../../config/firebase";

let idToken: string | null = null;

async function initializeIdToken() {
	let user = auth.currentUser;
	let attempts = 0;
	const maxAttempts = 10; // Maximum number of attempts
	const delayMs = 500; // Delay between attempts in milliseconds

	// Wait for the user object to become available or until reaching maxAttempts
	while (!user && attempts < maxAttempts) {
		await new Promise((resolve) => setTimeout(resolve, delayMs)); // Wait for delayMs milliseconds
		user = auth.currentUser;
		attempts++;
	}

	if (user) {
		try {
			const token = await user.getIdToken(/* forceRefresh */ true);
			// User is signed in, obtain the authentication token
			idToken = token;
		} catch (error) {
			// Handle error
			console.error("Error obtaining ID token:", error);
			idToken = null; // Or handle the error in a different way
		}
	} else {
		// No user is signed in after maxAttempts
		console.error("No user is signed in after multiple attempts.");
		idToken = null; // Or handle the case when no user is signed in
	}
	return idToken;
}



export {initializeIdToken};
