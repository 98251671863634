import React, {useEffect, useRef} from "react";
import Chart from "chart.js/auto";

interface BarPlotProps {
	data: {
		organisation_average_gas: number;
		organisation_average_electricity: number;
		sector_gas: number;
		sector_electricity: number;
	};
}

const BarPlot: React.FC<BarPlotProps> = ({data}) => {
	const chartRef = useRef<HTMLCanvasElement>(null);
	let myChart: Chart | null = null;

	useEffect(() => {
		if (chartRef && chartRef.current) {
			if (myChart) {
				myChart.destroy(); // Destroy the existing chart instance
			}

			const context = chartRef.current.getContext("2d");
			myChart = new Chart(context!, {
				type: "bar",
				data: {
					labels: ["Gasverbruik", "Elektriciteitsverbruik"],

					datasets: [
						{
							label: "Verbruik klant",
							backgroundColor: "rgba(54, 162, 235, 0.5)", // Blue color for "My Usage"
							borderColor: "rgba(54, 162, 235, 1)",
							borderWidth: 1,
							hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
							hoverBorderColor: "rgba(54, 162, 235, 1)",
							data: [
								data.organisation_average_gas,
								data.organisation_average_electricity,
							],
						},
						{
							label: "Sector gemiddelde",
							backgroundColor: [
								"rgba(255, 159, 64, 0.5)",
								"rgba(255, 159, 64, 0.5)",
							],
							borderColor: ["rgba(255, 159, 64, 1)", "rgba(255, 159, 64, 1)"],
							borderWidth: 1,
							hoverBackgroundColor: [
								"rgba(255, 159, 64, 0.8)",
								"rgba(255, 159, 64, 0.8)",
							],
							hoverBorderColor: [
								"rgba(255, 159, 64, 1)",
								"rgba(255, 159, 64, 1)",
							],
							data: [data.sector_gas, data.sector_electricity],
						},
					],
				},
				options: {
					indexAxis: "x", // Display bars horizontally
					scales: {
						x: {
							beginAtZero: true,
						},
						y: {
							stacked: false, // Show clustered bars
							beginAtZero: true,
						},
					},
					plugins: {
						// Set global font family for Chart.js
						legend: {
							labels: {
								font: {
									family: "Karla", // Change the font family here
								},
							},
						},
						title: {
							font: {
								family: "Karla", // Change the font family here
							},
						},
					},
				},
			});
		}

		// Cleanup function
		return () => {
			if (myChart) {
				myChart.destroy(); // Destroy the chart instance on unmount
			}
		};
	}, [data]); // Cleanup function

	return <canvas ref={chartRef} />;
};

export default BarPlot;
