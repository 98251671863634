import React, {useEffect} from "react";
import {mergeDoc} from "../../components/utils/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import TextSpec from "../../components/input-text";

interface ReportPrepProps {
	updateId: string;
	pageReady: boolean;
	generalData: any; // Add clientId prop here
	template: any;
	// Add clientId prop here
}

const EnergySavingReportPrep: React.FC<ReportPrepProps> = ({
	updateId,
	pageReady,
	template,
}) => {
	useEffect(() => {
		let status = true;

		if (!(status === pageReady)) {
			mergeDoc("energy_saving_computations", updateId, {
				progress: {
					5: {
						done: status,
						name: "Free text",
					},
				},
				approved: false,
			});
		}
	}, [updateId, pageReady, template]);

	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "70vw",
				overflowY: "auto",
				marginLeft: "-10px",
			}}
		>
			<div style={{...styles.rightPanelStyle, minHeight: "90vh"}}>
				<h3 style={{fontSize: "1rem"}}>Rapport aanvullingen</h3>
				<TextSpec
					updateId={updateId}
					templateUnfiltered={template}
					filter={"Rapport aanvullingen"}
					computationType={"energy_saving_computations"}
				/>
			</div>
		</div>
	);
};
export default EnergySavingReportPrep;
