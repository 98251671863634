import React, {useState, useEffect} from "react";
import DealSelector from "../../components/layout/deal-selector";
import Main from "../main/Main";
import {
	fetchDocData,
	removeDoc,
	fetchDocDataStream,
} from "../../components/utils/firebaseFunctions";
import StageStepper from "../../components/stage-stepper";
// import SolarEnergySimultaneous from "../../screens/energy-solar-simultaneous";
import {fetchCRMDetails} from "../../components/utils/apiFunctions";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useParams, useNavigate} from "react-router-dom";
// import SolarEnergyCosts from "../../screens/energy-solar-costs";
// import Content from "../../screens/energy-solar-report";
import {styles} from "../../components/utils/Styles";
import ComputationInitialize from "../../screens/computation-initialize";
import MeasuresSectionScreening from "../../screens/energy-screening-measures";
import {
	fetchRooms,
	fetchAll,
	fetchInteractions,
	fetchMeasures,
} from "../../components/utils/streamingFunctions";
import EnergyScreeningReportPrep from "../../screens/energy-screening-report-preparation";
import EnergyScreeningReportNotes from "../../screens/energy-screening-report-notes";
import EnergyScreeningContent from "../../screens/energy-screening-report";
import SelectableProgressBar from "../../components/selectable-progressbar";
import {updateFilteredSteps} from "../../components/utils/computationInitialization";
import UnauthorizedPage from "../../screens/unauthorized";
import {auth} from "../../config/firebase";

interface EnergySavingProps {}

const steps = [
	"Algemene gegevens",
	"Huidige situatie",
	"Schouwnotities",
	"Maatregel checklist",
	"Rapportage",
];

interface Template {
	[key: string]: any;
}
const pages: Record<string, number> = {
	initialize: 0,
	text: 1,
	notes: 2,
	measures: 3,
	report: 4,
};

const EnergyScreening: React.FC<EnergySavingProps> = () => {
	const [customClaims, setCustomClaims] = useState<{[key: string]: boolean}>(
		{}
	);
	useEffect(() => {
		const fetchCustomClaims = async () => {
			if (auth.currentUser) {
				try {
					// Get the ID token and decode it to access custom claims
					const idTokenResult = await auth.currentUser.getIdTokenResult();
					const customClaims = idTokenResult.claims;
					setCustomClaims(customClaims); // All claims including custom ones
					// console.log(customClaims); // You can use these claims to adjust UI components or logic
				} catch (error) {
					console.error("Error fetching custom claims", error);
				}
			} else {
				console.log("No user is currently signed in.");
			}
		};

		fetchCustomClaims();
	}, []);

	const {dealId, selectedPageName} = useParams();
	const navigate = useNavigate();
	const computationType = "energy_screening_computations";
	// State for usage here only
	const [clientId, setClientId] = useState<string>(dealId || "");

	// const [showExistingComputations, setShowExistingComputations] =
	// 	useState<boolean>(true);
	const [filteredSteps, setFilteredSteps] = useState<string[]>([]);
	const [showScreens, setShowScreens] = useState<boolean>(false);
	const [activeStep, setActiveStep] = useState<number>(
		selectedPageName ? pages?.[selectedPageName] : 0
	);
	const [generalData, setGeneralData] = useState<any[]>([]);
	const [measures, setMeasures] = useState<any[]>([]);

	// passing to solar energy pages
	const [updateId, setUpdateId] = useState<string>("");
	const [crmDetails, setCRMDetails] = useState<any | null>(null);
	const [roomId, setRoomId] = useState<string>("");
	const [listRooms, setListRooms] = useState<any[]>([]);
	// const [clientData, setClientData] = useState<any>({});
	const [computationData, setComputationData] = useState<any>({});
	const [progress, setProgress] = useState<any>({});
	// const [differences, setDifferences] = useState<any>({});
	const [loadedClientInputs, setLoadedClientInputs] = useState<any[]>([]);
	const [template, setTemplate] = useState<any[]>([]);
	const [templateGeneral, setTemplateGeneral] = useState<Template>({});
	const [globalState, setGlobalState] = useState<{country: string}>({
		country: "NL",
	});
	const [selectedMeasure, setSelectedMeasure] = useState<string>("");
	const [templateText, setTemplateText] = useState<any[]>([]);
	const [pageReady0, setPageReady0] = useState<boolean>(false);
	const [pageReady1, setPageReady1] = useState<boolean>(false);
	const [pageReady2, setPageReady2] = useState<boolean>(false);
	const [pageReady3, setPageReady3] = useState<boolean>(false);
	const [selectedInteraction, setSelectedInteraction] = useState<string>("");
	const [interactionData, setInteractionData] = useState<any[]>([]);

	useEffect(() => {
		if (generalData && generalData.length > 0 && generalData[0].fields) {
			setGlobalState((prevState) => ({
				...prevState,
				country: generalData[0].fields?.QQCT34RNyYnuu1UdpV9j,
			}));
		}
	}, [generalData]);

	useEffect(() => {
		if (interactionData.length > 0) {
			interactionData
				.filter((item) => item?.name === undefined)
				.forEach((item) => {
					if (item.id) {
						removeDoc(
							`${computationType}/${updateId}/computation_inputs`,
							item.id
						);
					}
				});
		}
	}, [interactionData.length]);

	useEffect(() => {
		const fetchTemplateGeneral = async () => {
			try {
				let mdvGeneralData: string[] = [];
				const result = await fetchDocData("templates", "ebs_general");
				mdvGeneralData = result?.fields;

				const templateData: Record<string, any> = {};
				for (const inputId of mdvGeneralData) {
					const data = await fetchDocData(`client_inputs`, `${inputId}`);
					templateData[inputId] = data;
				}
				if (templateData) {
					setTemplateGeneral(templateData as any);
				}
			} catch (error) {
				console.error("Error fetching template: ", error);
			}
		};
		fetchTemplateGeneral();
	}, [computationType]);

	const fetchComputation = async () => {
		const computation = await fetchDocData(
			`energy_screening_computations`,
			clientId
		);
		const computationData2 = await fetchDocData(
			`energy_screening_computations/${clientId}/general_inputs`,
			"all"
		);
		setCRMDetails(null);
		if (
			Object.keys(computation).length === 0 &&
			Object.keys(computationData2).length === 0
		) {
			setUpdateId("");
			setRoomId("");
			// setClientData({fields: {}});
			setComputationData({});
			setShowScreens(false);
			setProgress({});
			navigate(`/energy-screening/${clientId}/initialize`);
		} else if (!(Object.keys(computationData2).length > 0)) {
			setUpdateId(clientId);
			setRoomId("");
			// setClientData({fields: {}});
			setComputationData({});
			setShowScreens(true);
			navigate(`/energy-screening/${clientId}/initialize`);
		} else {
			setUpdateId(clientId);
			setRoomId("");
			if (computationData2?.fields) {
				// setClientData(computationData2);
				setComputationData(computationData2?.fields);
				navigate(
					selectedPageName
						? `/energy-screening/${clientId}/${selectedPageName}`
						: `/energy-screening/${clientId}/initialize`
				);
				setShowScreens(true);
			} else {
				// setClientData({fields: {}});
				navigate(
					selectedPageName
						? `/energy-screening/${clientId}/${selectedPageName}`
						: `/energy-screening/${clientId}/initialize`
				);
				setComputationData({});
				setProgress({});
				setShowScreens(true);
			}
			setShowScreens(true);
		}
	};

	useEffect(() => {
		fetchRooms(updateId, computationType, setListRooms);
		fetchAll(updateId, computationType, setGeneralData);
		fetchMeasures(updateId, computationType, setMeasures);
		fetchInteractions(updateId, computationType, setInteractionData);
	}, [updateId]);

	useEffect(() => {
		if (listRooms.length > 0) {
			setRoomId(listRooms.find((item) => item.name_clean === "all")?.id);
		} else {
			setRoomId("");
		}
	}, [listRooms, updateId]);

	useEffect(() => {
		const fetchTemplate = async () => {
			try {
				if (selectedMeasure) {
					const measureData = measures.find(
						(item) => item.id === selectedMeasure
					);
					const templateData: Record<string, any> = {};
					const updatedloadedClientInputs: Record<string, any> = {
						...loadedClientInputs,
					};
					if (measureData?.client_inputs) {
						for (const inputId of measureData?.client_inputs.map(
							(measure: any) => measure.id
						)) {
							// Check if inputId is not in templatekeys
							if (!Object.keys(loadedClientInputs).includes(inputId)) {
								const data = await fetchDocData("client_inputs", inputId);
								if (Object.keys(data).length > 0) {
									templateData[inputId] = data;
									updatedloadedClientInputs[inputId] = data;
								}
							} else {
								templateData[inputId] = loadedClientInputs[inputId];
							}
						}
						setLoadedClientInputs(updatedloadedClientInputs as any);
						setTemplate(templateData as any);
					}
				}
			} catch (error) {
				console.error("Error fetching template: ", error);
				const templateData: Record<string, any> = {};
				setTemplate(templateData as any);

				return null;
			}
		};

		fetchTemplate();
	}, [globalState, selectedMeasure, measures, updateId]);

	useEffect(() => {
		if (interactionData.length > 0) {
			//check whether there are any orphan interactions not containing 'name' field
			//if so, remove them from firebase
			const orphanInteractions = interactionData.filter(
				(interaction) => !interaction.name
			);
			if (orphanInteractions.length > 0) {
				orphanInteractions.forEach((interaction) => {
					removeDoc(
						`${computationType}/${updateId}/computation_inputs`,
						interaction.id
					);
				});
			}

			if (
				selectedInteraction === "" ||
				(selectedInteraction === undefined &&
					interactionData.find((item) => item.name === "Algemene berekening")
						?.id)
			) {
				setSelectedInteraction(
					interactionData.find((item) => item.name === "Algemene berekening")
						?.id === undefined
						? roomId
						: interactionData.find(
								(item) => item.name === "Algemene berekening"
						  )?.id
				);
			} else {
				if (roomId !== "") {
					setSelectedInteraction(roomId);
				}
			}
		}
	}, [interactionData, roomId, updateId, listRooms]);

	useEffect(() => {
		const fetchComputationData = async () => {
			try {
				// Fetch updated CRM details
				// setClientData({});

				// Fetch computation data when clientId is not empty
				if (clientId.trim() !== "") {
					await fetchComputation();
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		fetchComputationData();
	}, [clientId]);
	
	useEffect(() => {
		if (interactionData.length > 0) {
			//check whether there are any orphan interactions not containing 'name' field
			//if so, remove them from firebase
			const orphanInteractions = interactionData.filter(
				(interaction) => !interaction.name
			);
			if (orphanInteractions.length > 0) {
				orphanInteractions.forEach((interaction) => {
					removeDoc(
						`${computationType}/${updateId}/computation_inputs`,
						interaction.id
					);
				});
			}

			if (
				selectedInteraction === "" ||
				(selectedInteraction === undefined &&
					interactionData.find((item) => item.name === "Algemene berekening")
						?.id)
			) {
				setSelectedInteraction(
					interactionData.find((item) => item.name === "Algemene berekening")
						?.id === undefined
						? roomId
						: interactionData.find(
								(item) => item.name === "Algemene berekening"
						  )?.id
				);
			} else {
				if (roomId !== "") {
					setSelectedInteraction(roomId);
				}
			}
		}
	}, [interactionData, roomId, updateId, listRooms]);

	useEffect(() => {
		const fetchCRMData = async () => {
			// Fetch updated CRM details
			const updatedCRM = await fetchCRMDetails(
				clientId.split("_")[1],
				clientId.split("_")[0]
			);
			if (
				updatedCRM?.data?.requested_deal &&
				updatedCRM?.data.requested_deal === clientId &&
				updatedCRM?.data
			) {
				setCRMDetails(updatedCRM?.data);
			}
		};
		if (clientId !== "") {
			fetchCRMData();
		}
	}, [clientId]);

	useEffect(() => {
		if (showScreens === true) {
			setActiveStep(0);
			setUpdateId(clientId);
		}
	}, [showScreens]);

	useEffect(() => {
		updateFilteredSteps(
			progress?.progress,
			steps,
			showScreens,
			updateId,
			setFilteredSteps
		);
		const pageReadyList = [
			setPageReady0,
			setPageReady1,
			setPageReady2,
			setPageReady3,
		];

		for (const key in progress?.progress) {
			if (parseInt(key) <= pageReadyList.length) {
				if (progress.progress[key].done) {
					pageReadyList[parseInt(key)](true);
				} else {
					pageReadyList[parseInt(key)](false);
				}
			}
		}
	}, [progress]);

	useEffect(() => {
		const fetchClientData = async () => {
			try {
				if (updateId) {
					// Subscribe to real-time updates on the progress document
					const unsubscribe = fetchDocDataStream(
						"energy_screening_computations",
						updateId,
						setProgress
					);
					return () => {
						unsubscribe();
					};
				}
			} catch (error) {
				console.error("Error fetching progress data:", error);
			}
		};

		fetchClientData();
	}, [updateId]); // Include any dependencies if necessary

	const findKeyByValue = (object: any, value: any) => {
		return Object.keys(object).find((key) => object[key] === value);
	};

	useEffect(() => {
		if (updateId !== "") {
			const nextPageName = findKeyByValue(pages, activeStep); // Find the name of the next page
			// Navigate to the next page
			navigate(`/energy-screening/${updateId}/${nextPageName}`);
		}
	}, [activeStep]);

	useEffect(() => {
		const fetchTextFields = async () => {
			try {
				const templateKeys = await fetchDocData("templates", "ebs_text");
				let template: any = {}; // Initialize template as an empty object

				// Assuming templateKeys.fields is an array of field names
				for (const field of templateKeys.fields) {
					// Fetch each field data and assign it to the corresponding field in the template object
					template[field] = await fetchDocData("client_inputs", field);
				}

				setTemplateText(template);
			} catch (error) {
				console.error("Error fetching text fields:", error);
				// Handle the error, e.g., display an error message to the user
			}
		};

		fetchTextFields();
	}, []);

	const updatedMeasureData = [{name: "Rapport", id: "report", rank: -5}];

	return customClaims &&
		(customClaims?.["praeter-advisor"] ||
			customClaims?.["admin"] ||
			customClaims?.["energy-screening-advisor"]) ? (
		<Main>
			<div
				style={{
					backgroundColor: "#91c259",
					width: "100vw",
					padding: "10px",
					height: "10px",
				}}
			>
				<h2
					style={{
						textAlign: "center",
						marginTop: -9,
						color: "white",
						fontSize: "18px",
						fontFamily: "basic",
						fontWeight: "bold",
					}}
				>
					Energie besparingsscan
				</h2>
			</div>
			<div
				style={{
					display: "flex",
					backgroundColor: "#f0f0f0",
					height: "calc(100vh)",
				}}
			>
				<div style={{display: "flex"}}>
					<div style={{flex: 1, maxWidth: "250px"}}>
						<DealSelector
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
						/>
						<StageStepper
							steps={steps}
							activeStep={activeStep}
							handleStepChange={setActiveStep}
							filteredSteps={filteredSteps}
						/>
					</div>
					<div style={{flex: 2}}>
						{activeStep === 0 && (
							<ComputationInitialize
								updateId={updateId}
								crmDetails={crmDetails}
								computationData={computationData}
								setComputationData={setComputationData}
								generalData={generalData}
								templateGeneral={templateGeneral}
								roomId={roomId}
								setRoomId={setRoomId}
								listRooms={listRooms}
								setListRooms={setListRooms}
								pageReady={pageReady0}
								computationType={"energy_screening_computations"}
							/>
						)}
						{activeStep === 1 && (
							<div
								style={{
									...styles.container,
									marginLeft: "10px",
								}}
							>
								<EnergyScreeningReportPrep
									updateId={updateId}
									pageReady={pageReady3}
									generalData={generalData}
									template={templateText}
								/>
							</div>
						)}
						{activeStep === 2 && (
							<div
								style={{
									...styles.container,

									marginLeft: "10px",
								}}
							>
								<EnergyScreeningReportNotes
									updateId={updateId}
									pageReady={pageReady1}
									generalData={generalData}
									template={templateText}
								/>
							</div>
						)}
						{activeStep === 3 && (
							<MeasuresSectionScreening
								template={templateText}
								updateId={updateId}
								measures={measures}
								listRooms={listRooms}
								interactionData={interactionData}
								pageReady={pageReady2}
							/>
						)}

						{activeStep === 4 && (
							<div
								style={{
									...styles.container,
									marginLeft: "20px",
									flexDirection: "row",
									maxHeight: "90vh",
								}}
							>
								<SelectableProgressBar
									updateId={updateId}
									interactionData={updatedMeasureData}
									selectedInteraction={selectedMeasure}
									setSelectedInteraction={setSelectedMeasure}
									differences={[]}
									computationType="energy_saving_computations"
								/>

								<EnergyScreeningContent
									updateId={updateId}
									generalData={generalData}
									selectedInteraction={selectedInteraction}
									interactionData={interactionData}
									measures={measures}
									selectedMeasure={selectedMeasure}
								/>
							</div>
						)}
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginRight: "20px",
								position: "absolute",
								bottom: 50,
								right: 80,
							}}
						>
							{activeStep !== 4 && (
								<IconButton
									onClick={() => setActiveStep((prevStep) => prevStep + 1)}
									disabled={
										activeStep === steps.length - 1 || showScreens === false
									}
									size="large"
								>
									<ArrowForwardIcon
										style={{color: "#91c259", fontSize: "45px"}}
									/>
								</IconButton>
							)}
						</div>
					</div>
				</div>
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default EnergyScreening;
