import React, {useState, useEffect} from "react";
import {getFunctions, httpsCallable} from "firebase/functions";
import Main from "../../layout/main/Main";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Checkbox,
	Box,
	TextField,
	Alert,
} from "@mui/material";
import UnauthorizedPage from "../unauthorized";
import {auth, sendSignInLinkToEmail} from "../../config/firebase";
import {styles} from "../../components/utils/Styles";

interface User {
	uid: string;
	displayName?: string;
	email?: string;
	customClaims?: {[key: string]: boolean};
}

const potentialCustomClaims = [
	"admin",
	"praeter-advisor",
	"energy-screening-advisor",
	"solar-energy-advisor",
	"energy-saving-advisor",
	"data-manager",
]; // Define potential custom claims

const SettingsPage: React.FC = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [selectedClaims, setSelectedClaims] = useState<{
		[key: string]: boolean;
	}>({});
	const [newClaimKey, setNewClaimKey] = useState<string>("");
	const functions = getFunctions(undefined, "europe-west3");
	const [newUserEmail, setNewUserEmail] = useState("");
	// const [newUserPassword, setNewUserPassword] = useState("");
	const [creationError, setCreationError] = useState<string | null>(null);
	const [customClaims, setCustomClaims] = useState<{[key: string]: boolean}>(
		{}
	);
	const [claimsForDeletion, setClaimsForDeletion] = useState<string[]>([]);
	const [claimsForAddition, setClaimsForAddition] = useState<string[]>([]);

	useEffect(() => {
		setClaimsForAddition([]);
		setClaimsForDeletion([]);
	}, [selectedUser]);

	useEffect(() => {
		const fetchCustomClaims = async () => {
			if (auth.currentUser) {
				try {
					// Get the ID token and decode it to access custom claims
					const idTokenResult = await auth.currentUser.getIdTokenResult();
					const customClaims = idTokenResult.claims; // All claims including custom ones
					setCustomClaims(customClaims);
					console.log(customClaims); // You can use these claims to adjust UI components or logic
				} catch (error) {
					console.error("Error fetching custom claims", error);
				}
			} else {
				console.log("No user is currently signed in.");
			}
		};

		fetchCustomClaims();
	}, [auth.currentUser]);

	useEffect(() => {
		const fetchUsers = async () => {
			const listUsers = httpsCallable<{region: string}, {users: User[]}>(
				functions,
				"listUsers"
			);
			const {data} = await listUsers({region: "europe-west3"});
			setUsers(data.users);
		};

		fetchUsers();
	}, [functions]);

	const handleRowClick = (user: User) => {
		setSelectedUser(user);
		setSelectedClaims(user.customClaims || {});
	};

	const handleClaimToggle = (claim: string) => {
		if (!selectedClaims[claim]) {
			setClaimsForAddition((prevClaimsForAddition) => [
				...prevClaimsForAddition,
				claim,
			]);
			setClaimsForDeletion((prevClaimsForDeletion) =>
				prevClaimsForDeletion.filter((c) => c !== claim)
			);
		} else if (selectedClaims[claim]) {
			setClaimsForDeletion((prevClaimsForDeletion) => [
				...prevClaimsForDeletion,
				claim,
			]);
			setClaimsForAddition((prevClaimsForAddition) =>
				prevClaimsForAddition.filter((c) => c !== claim)
			);
		}
		setSelectedClaims((prevSelectedClaims) => ({
			...prevSelectedClaims,
			[claim]: !prevSelectedClaims[claim],
		}));
	};

	const handleAddClaim = async () => {
		if (!selectedUser) return;

		if (!claimsForAddition.length && !claimsForDeletion.length) return;
		if (claimsForDeletion.length > 0) {
			const removeCustomClaim = httpsCallable(functions, "withdrawCustomClaim");
			await removeCustomClaim({
				uid: selectedUser.uid,
				claims: claimsForDeletion,
			});
			setClaimsForDeletion([]);
		}
		if (claimsForAddition.length > 0) {
			const addCustomClaim = httpsCallable(functions, "addCustomClaim");
			await addCustomClaim({uid: selectedUser.uid, claims: claimsForAddition});
			setClaimsForAddition([]);
		}

		const fetchUsers = async () => {
			const listUsers = httpsCallable<{region: string}, {users: User[]}>(
				functions,
				"listUsers"
			);
			const {data} = await listUsers({region: "europe-west3"});
			setUsers(data.users);
		};

		setNewClaimKey("");
		// setSelectedClaims({});
		fetchUsers();
	};

	const handleCreateUser = async () => {
		if (!newUserEmail) {
			setCreationError("Please fill in both email and password fields.");
			return;
		}
		const actionCodeSettings = {
			url: `http://dash.praeter.nl/signup`, // URL where the user will be redirected after clicking the link
			handleCodeInApp: true, // Whether to open the link in the app directly or through a web browser
			expiresIn: 86400, // 24 hours
		};

		try {
			await sendSignInLinkToEmail(auth, newUserEmail, actionCodeSettings);
			console.log("Login link sent successfully!");
		} catch (error) {
			console.error("Error sending login link:", error);
		}
	};

	return customClaims &&
		(customClaims?.["praeter-advisor"] ||
			customClaims?.["admin"] ||
			customClaims?.["energy-saving-advisor"]) ? (
		<Main>
			<div style={{...styles.container, display: "flex", minWidth: "100vw"}}>
				<div style={{flex: 1, maxWidth: "50vw", marginLeft: "10px"}}>
					<TableContainer component={Paper} style={{...styles.rightPanelStyle}}>
						<Table aria-label="user table">
							<TableHead>
								<TableRow>
									<TableCell>User ID</TableCell>
									<TableCell align="right">Naam</TableCell>
									<TableCell align="right">E-mail adres</TableCell>
									<TableCell align="right">Rollen</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map((user) => (
									<TableRow
										key={user.uid}
										sx={{"&:last-child td, &:last-child th": {border: 0}}}
										onClick={() => handleRowClick(user)}
										selected={selectedUser?.uid === user.uid}
										style={{
											cursor: "pointer",
											backgroundColor:
												selectedUser?.uid === user.uid
													? "#f5f5f5"
													: "transparent",
										}}
									>
										<TableCell component="th" scope="row">
											{user.uid}
										</TableCell>
										<TableCell align="right">{user.displayName}</TableCell>
										<TableCell align="right">{user.email}</TableCell>
										<TableCell align="right">
											<div>
												{user.customClaims
													? Object.keys(user.customClaims).map((claim) => (
															<div key={claim}>{claim}</div>
													  ))
													: "None"}
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>

				<div>
					{selectedUser && (
						<Paper
							style={{
								...styles.rightPanelStyle,
								maxWidth: "200px",
								maxHeight: "400px",
								marginLeft: "50px",
								marginBottom: "10px",
							}}
						>
							<div style={{}}>
								<div style={{}}>
									{potentialCustomClaims.map((claim) => (
										<div key={claim}>
											<Checkbox
												checked={selectedClaims[claim] || false}
												onChange={() => handleClaimToggle(claim)}
											/>
											{claim}
										</div>
									))}
								</div>
								<Button
									variant="contained"
									onClick={handleAddClaim}
									style={styles.updateButton}
								>
									Rollen updaten
								</Button>
							</div>
						</Paper>
					)}

					<Box
						component="form"
						noValidate
						autoComplete="off"
						sx={{
							...styles.rightPanelStyle,
							maxWidth: "200px",
							maxHeight: "120px",
							marginLeft: "50px",
						}}
					>
						<TextField
							label="Email"
							variant="outlined"
							value={newUserEmail}
							onChange={(e) => setNewUserEmail(e.target.value)}
							required
							sx={{mr: 2}}
						/>
						<Button
							onClick={handleCreateUser}
							sx={{...styles.updateButton, marginTop: "10px"}}
							variant="contained"
						>
							Stuur invite link
						</Button>
					</Box>
					{creationError && <Alert severity="error">{creationError}</Alert>}
				</div>
			</div>
		</Main>
	) : (
		<UnauthorizedPage />
	);
};

export default SettingsPage;
