import React, {useState, useEffect} from "react";
import {
	Typography,
	ListItem,
	ListItemIcon,
	Collapse,
	List,
	ListItemText,
	Checkbox,
} from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import {styles} from "../../components/utils/Styles";
import {
	fetchDocsSnap,
	mergeDoc,
	fetchDocData,
	queryCollection,
	removeDoc,
} from "../../components/utils/firebaseFunctions";
import MeasureManagement from "../../components/drag-table";
// import InfoButton from "../../components/info-button";

interface Measure {
	id: string;
	rank: number;
	name: string;
	selected: boolean;
	energy_screening_category_id: string;
}

interface MeasuresSectionProps {
	template: any;
	updateId: string;
	listRooms: {[roomId: string]: any};
	measures: Measure[];
	interactionData: any[];
	pageReady: boolean;
}

const MeasuresSectionScreening: React.FC<MeasuresSectionProps> = ({
	template,
	updateId,
	listRooms,
	measures,
	interactionData,
	pageReady,
}) => {
	const [groupedMeasures, setGroupedMeasures] = useState<{
		[categoryId: string]: Measure[];
	}>({});
	const [expanded, setExpanded] = useState<{[categoryId: string]: boolean}>({});
	const [energySavingMeasures, setEnergySavingMeasures] = useState<Measure[]>(
		[]
	);
	const [categoryNames, setCategoryNames] = useState<{
		[categoryId: string]: string;
	}>({});
	const [checkedDict, setCheckDict] = useState<{[key: string]: boolean}>({});
	// const [loading, setLoading] = useState(true);
	const [change, setChange] = useState(false);
	const [loaded, setLoaded] = useState(false);

	const handleCopyToUpdated = async (measureId: string) => {
		try {
			const measureData = await fetchDocData(
				"energy_screening_measures",
				measureId
			);
			await mergeDoc(
				`energy_screening_computations/${updateId}/measures`,
				measureId,
				{...measureData, room_split: false}
			);
		} catch (error) {
			console.error("Error copying measure:", error);
		}
	};

	useEffect(() => {
		if (loaded === false && measures.length > 0) {
			setLoaded(true);
		}
		// console.log("change", change, measures.length, loaded)
		if (change === true && measures.length > 0 && loaded === true) {
			mergeDoc(
				`energy_screening_computations/${updateId}/general_inputs`,
				"all",
				{report_ready: false}
			);
			setChange(false);
		}
	}, [measures, loaded, updateId, change]);

	useEffect(() => {
		const generalInteractionId: string = interactionData.find(
			(item) => item.name === "Algemene berekening"
		)?.id;

		if (generalInteractionId) {
			const measureIds = measures.map((item) => item.id);
			mergeDoc(
				`energy_screening_computations/${updateId}/computation_inputs`,
				`${generalInteractionId}`,
				{measureIds: measureIds}
			);
		}
	}, [measures, updateId, interactionData]);

	useEffect(() => {
		const status = measures.every((measure) =>
			measure.hasOwnProperty("phase_id")
		);
		if (!(status === pageReady)) {
			mergeDoc("energy_screening_computations", updateId, {
				progress: {
					2: {
						done: status,
						name: "Measures",
					},
				},
			});
		}
	}, [measures, pageReady, updateId]);

	useEffect(() => {
		const fetchEnergySavingMeasures = async () => {
			try {
				const measuresAll: any = await queryCollection(
					"energy_screening_measures",
					{ready: true}
				);
				setEnergySavingMeasures(measuresAll);
				// setLoading(false);
			} catch (error) {
				console.error("Error fetching energy saving measures:", error);
			}
		};
		fetchEnergySavingMeasures();
	}, []);

	const handleMeasureToggle = async (measureId: string) => {
		if (!measures.find((item) => measureId === item.id)) {
			handleCopyToUpdated(measureId);
			setChange(true);
		} else {
			removeDoc(
				`energy_screening_computations/${updateId}/measures`,
				`${measureId}`
			);
			setChange(true);
		}
	};

	useEffect(() => {
		const fetchEnergySavingCategories = async () => {
			const CategoryData = await fetchDocsSnap("energy_screening_categories");

			if (CategoryData) {
				const names: {[key: string]: string} = {}; // Specify type for names object
				CategoryData.docs.forEach((doc) => {
					const data = doc.data() as {name: string}; // Assert type of data
					names[doc.id] = data.name;
				});
				setCategoryNames(names);
			} else {
				// Handle the case when CategoryData is null
				// For example, set categoryNames to an empty object
				setCategoryNames({});
			}
		};
		fetchEnergySavingCategories();
	}, []);

	useEffect(() => {
		const buildCheckDict = async () => {
			const newCheckDict = {...checkedDict};
			measures
				.filter((measure) => measure.selected)
				.map((selectedMeasure) => selectedMeasure.id)
				.forEach((measureId) => {
					Object.keys(listRooms).forEach((roomId) => {
						const checkboxKey = `${roomId}_${measureId}`;
						const defaultValue =
							listRooms[roomId]?.energy_screening_measures?.includes(
								measureId
							) || false;
						newCheckDict[checkboxKey] = defaultValue;
					});
				});
			setCheckDict(newCheckDict);
		};

		if (energySavingMeasures.length > 0 && Object.keys(listRooms).length > 0) {
			buildCheckDict();
		}
	}, [measures, listRooms, updateId]);

	const handleExpand = (categoryId: string) => {
		setExpanded((prevExpanded) => ({
			...prevExpanded,
			[categoryId]: !prevExpanded[categoryId],
		}));
	};

	useEffect(() => {
		const updateGroupedMeasures = () => {
			const sortedMeasures = energySavingMeasures.sort(
				(a, b) => a.rank - b.rank
			);
			const updatedGroupedMeasures: {[categoryId: string]: Measure[]} =
				sortedMeasures.reduce((acc: any, measure) => {
					const {id, name, energy_screening_category_id, rank} = measure;
					const category = energy_screening_category_id || "Uncategorized";
					if (!acc[category]) {
						acc[category] = [];
					}
					acc[category].push({id, name, rank});
					return acc;
				}, {});

			// Sort measures within each category based on the rank property
			setGroupedMeasures(updatedGroupedMeasures);
		};
		if (energySavingMeasures.length > 0) {
			updateGroupedMeasures();
		}
	}, [energySavingMeasures, categoryNames, updateId]);

	return (
		<div
			style={{
				minHeight: "100vh",
				maxHeight: "100vh",
				overflowY: "auto",
				display: "flex",
				minWidth: "70vw",
				marginLeft: "10px",
			}}
		>
			<div style={{flex: 1}}>
				<div style={{display: "flex", flex: "1"}}>
					<div
						style={{
							...styles.rightPanelStyle,
							minHeight: "90vh",
							minWidth: "15vw",
							maxWidth: "15vw",
							fontFamily: "Barlow:wght@200",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							Maatregelen voor selectie
						</h3>

						{Object.entries(groupedMeasures).map(
							([categoryId, measuresAll]) => (
								<div key={categoryId}>
									<ListItem
										disablePadding
										onClick={() => handleExpand(categoryId)}
									>
										<ListItemIcon>
											<CategoryIcon />
										</ListItemIcon>
										<Typography
											variant="subtitle1"
											gutterBottom
											style={styles.labelLower}
										>
											{categoryNames[categoryId]}
											{` (${
												measuresAll.filter((measure) => {
													const selectedMeasure = measuresAll.find(
														(item) => item.id === measure.id
													);
													return (
														selectedMeasure &&
														measures.find((item) => item.id === measure.id)
													);
												}).length
											}/${measuresAll.length})`}
										</Typography>
									</ListItem>
									<Collapse
										in={expanded[categoryId]}
										timeout="auto"
										unmountOnExit
									>
										<List dense>
											{measuresAll.map((measure) => (
												<ListItem key={measure.id} style={styles.labelLower}>
													<ListItemText primary={measure.name} />
													<Checkbox
														checked={
															Object.keys(
																measures.find(
																	(item) => item.id === measure.id
																) || {}
															).length > 0
														}
														onChange={() => handleMeasureToggle(measure.id)}
														color="primary"
													/>
												</ListItem>
											))}
										</List>
									</Collapse>
								</div>
							)
						)}
					</div>

					<div
						style={{
							flex: 1,
							textAlign: "left",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							minWidth: "700px",
							maxWidth: "40vw",
							marginLeft: "10px",
						}}
					>
						<div
							style={{
								...styles.rightPanelStyle,
								maxWidth: "18vw",
								position: "relative",
							}}
						>
							<h3 style={{fontSize: "1rem"}}>Mate van urgentie</h3>
							<div>
								<em> Direct; dit kan je morgen toepassen</em>
								<em>
									{" "}
									Hoog; No brainer, maar hier zijn kosten mee gemoeid. Ofwel
									door terugverdientijd of omdat het een groot probleem oplost.
								</em>
								<em>
									{" "}
									Medium; Dit zou verstandig zijn om te doen en de
									terugverdientijd is iets langer.
								</em>
								<em>
									{" "}
									Laag; Dit wil je op lange termijn toepassen. Dus vaak een
									natuurlijk moment.
								</em>
							</div>
							<MeasureManagement
								updateId={updateId}
								measures={measures}
								computationType={"energy_screening_computations"}
								phasesPreset={[
									{id: 1, name: "Direct"},
									{id: 2, name: "Hoog"},
									{id: 3, name: "Medium"},
									{id: 4, name: "Laag"},
								]}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeasuresSectionScreening;
