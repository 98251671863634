import React, {useState, useEffect} from "react";
import {Container, Box, Typography} from "@mui/material";
import {fetchDataBigquery} from "../../components/utils/apiFunctions";
import Main from "../../layout/main/Main";
import {useTranslation} from "react-i18next";
import {styles} from "../../components/utils/Styles";
import ClientSelector from "../../components/layout/client-search";
import UnauthorizedPage from "../unauthorized";
import {auth} from "../../config/firebase";
import ClientSummary from "./ClientSummary";
import EstimateTable from "./EstimateTable";
import {useParams, useNavigate} from "react-router-dom";
import ProductSection from "./ProductSection";

import {
	Paid as PaidIcon,
	Receipt as ReceiptIcon,
	CheckCircle as CheckCircleIcon,
	Error as ErrorIcon,
} from "@mui/icons-material";

interface CustomClaims {
	[key: string]: boolean;
}

interface EstimateData {
	url: string;
	reference: string;
	total_price_incl_tax: string;
	state: string;
	due_date: string;
	accepted_at: string | null;
}

const CustomerSuccessPage: React.FC = () => {
	const {t} = useTranslation();
	const {organizationId} = useParams();
	const [searchingEstimates, setSearchingEstimates] = useState(false);
	const [dataResultsEstimates, setDataResultsEstimates] = useState<any[]>([]);
	const [clientId, setClientId] = useState(organizationId || "");
	const [clientData, setClientData] = useState<any[]>([]);
	const [lifetimeValue, setLifetimeValue] = useState(0);
	const [openEstimatesCount, setOpenEstimatesCount] = useState(0);
	const [customClaims, setCustomClaims] = useState<CustomClaims>({});
	const [searchingProducts, setSearchingProducts] = useState(false);
	const [dataResultsProducts, setDataResultsProducts] = useState<any[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (clientId !== "") {
			// Navigate to the next page
			navigate(`/customer-success/${clientId}`);
		}
	}, [clientId, navigate]);

	useEffect(() => {
		const fetchCustomClaims = async () => {
			if (auth.currentUser) {
				try {
					const idTokenResult = await auth.currentUser.getIdTokenResult();
					setCustomClaims(idTokenResult.claims as CustomClaims);
				} catch (error) {
					console.error("Error fetching custom claims", error);
				}
			}
		};

		fetchCustomClaims();
	}, []);

	const handleButtonClick = () => {
		// Add your button click logic here
	};

	useEffect(() => {
		if (clientId && clientData.length === 1) {
			setSearchingEstimates(true);
			const clientRowkey = clientData[0]?.rowkey;
			fetchDataBigquery(
				"app.moneybird_estimates_contacts",
				"client_id",
				clientRowkey
			)
				.then((data: any) => {
					if (data && data.data) {
						const estimates: EstimateData[] = data.data.map((estimate: any) =>
							JSON.parse(estimate.data)
						);
						setDataResultsEstimates(estimates);

						const newLifetimeValue = estimates.reduce(
							(acc, estimate) =>
								estimate.accepted_at
									? acc + parseFloat(estimate.total_price_incl_tax)
									: acc,
							0
						);
						setLifetimeValue(newLifetimeValue);

						const newOpenEstimatesCount = estimates.filter(
							(estimate) => !estimate.accepted_at
						).length;
						setOpenEstimatesCount(newOpenEstimatesCount);
					} else {
						setDataResultsEstimates([]);
						setLifetimeValue(0);
						setOpenEstimatesCount(0);
					}
					setSearchingEstimates(false);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
					setDataResultsEstimates([]);
					setLifetimeValue(0);
					setOpenEstimatesCount(0);
					setSearchingEstimates(false);
				});
		}
	}, [clientId, clientData]);

	useEffect(() => {
		if (clientId && clientData.length === 1) {
			setSearchingProducts(true);
			const clientRowkey = clientData[0]?.rowkey;
			fetchDataBigquery(
				"app.pipedrive_products",
				"rowkey",
				clientRowkey.toString().split("_")[0],
				false,
				500
			)
				.then((data: any) => {
					if (data && data.data) {
						const estimates: EstimateData[] = data.data.map((estimate: any) => {
							const parsedData = JSON.parse(estimate.data);
							return {
								...parsedData,
								phase: estimate.phase,
							} as EstimateData;
						});
						setDataResultsProducts(estimates);
					} else {
						setDataResultsProducts([]);
					}
					setSearchingProducts(false);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
					setDataResultsProducts([]);
					setSearchingProducts(false);
				});
		}
	}, [clientId, clientData]);

	const fields = [
		{
			label: "LTV",
			value: lifetimeValue > 0 ? `€${lifetimeValue.toFixed(2)}` : "-",
			icon: <PaidIcon />,
		},
		{label: "Oppty", value: "-", icon: <ReceiptIcon />},
		{
			label: "To accept",
			value: openEstimatesCount > 0 ? openEstimatesCount : "-",
			icon: <ErrorIcon />,
		},
		{label: "To pay", value: "-", icon: <CheckCircleIcon />},
	];

	if (!customClaims?.["praeter-advisor"] && !customClaims?.["admin"]) {
		return <UnauthorizedPage />;
	}

	return (
		<Main>
			<Container maxWidth="md" style={{...styles.container, minWidth: "100vw"}}>
				<div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
					<div style={{flex: 1, marginRight: "10px"}}>
						<ClientSelector
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={() => {}}
							dataResults={clientData}
							setDataResults={setClientData as any}
						/>
						<ClientSummary fields={fields} />
						<Box
							sx={{
								padding: "20px",
								marginTop: "10px",
								borderRadius: "5px",
								minHeight: "40vh",
								backgroundColor: "white",
								minWidth: "20vw",
								maxWidth: "20vw",
								color: "black",
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
								maxHeight: "50vh",
								position: "relative",
								marginLeft: "-25px",
							}}
						>
							<Typography
								variant="h6"
								component="h2"
								sx={{
									fontFamily: "basic",
									fontWeight: "bold",
									fontSize: "16px",
									color: styles.rightPanelStyle.color,
									margin: 0,
								}}
							>
								Products made
							</Typography>
						</Box>
					</div>
					<div
						style={{
							flex: 1,
							display: "flex",
							minHeight: "90vh",
							maxHeight: "90vh",
							flexDirection: "column",
							minWidth: "70vw",
							marginLeft: "-10px",
						}}
					>
						<EstimateTable
							searching={searchingEstimates}
							estimates={dataResultsEstimates}
						/>
						<ProductSection
							products={dataResultsProducts}
							loading={searchingProducts}
							selectedProduct={selectedProduct}
							onProductSelect={setSelectedProduct}
							onButtonClick={handleButtonClick}
						/>
					</div>
				</div>
			</Container>
		</Main>
	);
};

export default CustomerSuccessPage;
