import {initializeApp} from "firebase/app";
import {
	getAuth,
	signInWithPopup,
	OAuthProvider,
	signInWithEmailAndPassword,
	sendSignInLinkToEmail,
	sendEmailVerification,
	createUserWithEmailAndPassword,
	deleteUser,
} from "firebase/auth"; // Import OAuthProvider instead of AuthProvider
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {firebaseConfig} from "./firebase.config";
import {getFunctions} from "firebase/functions";

console.log("react app env", process.env.REACT_APP_ENV);
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "europe-west3");
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

// Create Microsoft OAuth provider instance
const microsoftProvider = new OAuthProvider("microsoft.com"); // Use OAuthProvider instead of AuthProvider

// Set custom parameters or scopes if needed
microsoftProvider.setCustomParameters({
	// Custom parameters here if required
	tenant: "9593e3be-446e-4a82-93d5-b78f71028516",
});

// Function to handle Microsoft sign-in with a popup
const signInWithMicrosoft = async () => {
	try {
		const result = await signInWithPopup(auth, microsoftProvider);
		// Handle successful login
		return {result};

		// Access and use the tokens or user data
	} catch (error: any) {
		// Handle errors
		console.error("Error signing in with Microsoft:", error.message);
	}
};

export {
	auth,
	signInWithMicrosoft,
	sendSignInLinkToEmail,
	signInWithEmailAndPassword,
	sendEmailVerification,
	createUserWithEmailAndPassword,
	deleteUser,
	firestore,
	storage,
	functions,
};
