import {
	mergeDoc,
	createDoc,
	fetchDocsData,
	fetchDocData,
} from "./firebaseFunctions";
import {auth} from "../../config/firebase";

const fetchComputation = async (
	clientId: string,
	computationType: string,
	setUpdateId: (updateId: string) => void,
	setComputationData: (computationData: Record<string, any>) => void,
	setShowScreens: (showScreens: boolean) => void
) => {
	const computation = await fetchDocData(computationType, clientId);
	const computationData2 = await fetchDocData(
		`${computationType}/${clientId}/general_inputs`,
		"all"
	);

	if (
		Object.keys(computation).length === 0 &&
		Object.keys(computationData2).length === 0
	) {
		setUpdateId("");
		setComputationData({});
		setShowScreens(false);
	} else if (!(Object.keys(computationData2).length > 0)) {
		setUpdateId(clientId || "");
		setComputationData({});
		setShowScreens(true);
	} else {
		setUpdateId(clientId === null ? "" : clientId);
		if (computationData2?.fields) {
			setComputationData(computationData2?.fields);
			setShowScreens(true);
		} else {
			setComputationData({});
			setShowScreens(true);
		}
	}
};

const updateFilteredSteps = (
	progress: any,
	steps: string[],
	showScreens: boolean,
	updateId: string,
	setFilteredSteps: (filteredSteps: []) => void
) => {
	const filteredSteps: any = steps.filter((step, index) => {
		// Check all preceding steps to ensure they are done
		const allPreviousStepsDone = Array.from({length: index}).every((_, i) => {
			return progress?.[i.toString()]?.done;
		});

		if (index > 0) {
			return allPreviousStepsDone;
		}

		// For the first step, apply the original condition
		if (showScreens && updateId && index === 0) {
			return true;
		}

		return false;
	});
	if (updateId === "") {
		setFilteredSteps([]);
		return;
	}
	setFilteredSteps(filteredSteps);
};

const addDocumentComputation = async (
	updateId: string,
	computationType: string
): Promise<void> => {
	await mergeDoc(`${computationType}`, updateId, {
		created_by: auth.currentUser?.uid,
	});
	await mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
		name: "Algemene berekening",
		name_en:  "General computation"
	});
	const listRooms = await fetchDocsData(`${computationType}/${updateId}/rooms`);
	const allRoom = listRooms.filter((room: any) => room.name_clean === "all");
	const interactionDataUpdate = await fetchDocsData(
		`${computationType}/${updateId}/computation_inputs`
	);
	if (allRoom.length > 0 && interactionDataUpdate.length === 0) {
		await mergeDoc(
			`${computationType}/${updateId}/computation_inputs`,
			allRoom[0].id,
			{fields: {}, 
			name: "Algemene berekening", rooms: [], rank: 0,
			name_en:  "General computation"
		}
		);
	} else if (allRoom.length === 0) {
		const roomIdUpdate = await createDoc(
			`${computationType}/${updateId}/rooms`,
			{name: "Alle ruimten", name_clean: "all"}
		);
		if (roomIdUpdate) {
			await mergeDoc(
				`${computationType}/${updateId}/computation_inputs`,
				roomIdUpdate.id,
				{fields: {}, 
				name: "Algemene berekening",			
				name_en:  "General computation",
				rooms: [], rank: 0}
			);
		} else {
			console.error("Failed to create room ID");
		}
	}
};

export {addDocumentComputation, fetchComputation, updateFilteredSteps};
