import React, {useEffect, useState} from "react";
import {styles} from "../utils/Styles";

interface DataModelOptionsProps {
	interactionData: {id: string; name: string; input_ready: boolean}[];
	selectedInteraction: string;
	setSelectedInteraction: (id: string) => void;
}
const pagesNames: {[key: string]: string} = {
	"client-inputs": "Klantgegevens",
	facts: "Stamgegevens",
	costs: "Kosten",
	"energy-saving-measures": "Maatregelen MDV",
	"energy-saving-categories": "Categorieen MDV",
	"energy-screening-measures": "Maatregelen EBS",
	"energy-screening-categories": "Categorieen EBS",
	"energy-saving-linking": "Koppelingen",
};
const DataModelOptions: React.FC<DataModelOptionsProps> = ({
	interactionData,
	selectedInteraction,
	setSelectedInteraction,
}) => {
	const [sortedInteractionData, setSortedInteractionData] = useState<
		{id: string; name: string; input_ready: boolean}[]
	>([]);

	useEffect(() => {
		if (interactionData) {
			const sortedData = [...interactionData].sort((a, b) => {
				if (a?.name && b?.name) {
					return a.name.localeCompare(b.name);
				}
				return 0;
			});
			setSortedInteractionData(sortedData);
		}
	}, [interactionData]);

	return (
		<div
			style={{
				minHeight: "90vh",
				overflowY: "auto",
				minWidth: "150px",
				maxWidth: "150px",
				...styles.rightPanelStyle,
				backgroundColor: styles.DealSelector.backgroundColor,
				color: styles.DealSelector.color,
				marginTop: "-00px",
			}}
		>
			{sortedInteractionData.map((measure) => (
				<div
					key={measure.id}
					onClick={() => setSelectedInteraction(measure.id)}
					style={{
						marginTop: "20px",
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
						backgroundColor:
							selectedInteraction === measure.id ? "#4CAF50" : "#267FB8",
						fontSize: "12px",
						padding: "4px",
						minHeight: "20px",
					}}
				>
					<p style={{margin: 0, wordWrap: "break-word", color: "white"}}>
						{pagesNames?.[measure.id]}
					</p>
					{measure.input_ready && (
						<span style={{marginLeft: "8px", color: "green"}}>&#10003;</span>
					)}
				</div>
			))}
		</div>
	);
};

export default DataModelOptions;
