import React, {useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {
	auth,
	signInWithEmailAndPassword,
	// createUserWithEmailAndPassword,
	signInWithMicrosoft,
} from "../../config/firebase";
import {useNavigate} from "react-router-dom";
import "./login.css";
const typescriptLogo = require("../../assets/images/typescript-logo-240.png");

const LoginScreen: React.FC = () => {
	const navigate = useNavigate();
	const [user, loading, error] = useAuthState(auth);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	// Function to handle Microsoft login
	const handleMicrosoftLogin = async () => {
		try {
			await signInWithMicrosoft();
			console.log("User logged in");
		} catch (error: any) {
			console.error("Error signing in with Microsoft:", error.message);
			setErrorMessage("Error signing in with Microsoft. Please try again.");
		}
	};

	// Function to handle email and password login
	const handleEmailAndPasswordLogin = async () => {
		try {
			await signInWithEmailAndPassword(auth, email, password);
			console.log("User logged in with email and password");
		} catch (error: any) {
			console.error("Error signing in with email and password:", error.message);
			setErrorMessage(
				"Error signing in with email and password. Please try again."
			);
		}
	};

	return (
		<div
			className="container"
			style={{
				backgroundImage: `url(https://praeter.nl/wp-content/uploads/2024/02/2023_ENERGIEKEREGIO_STILL-24-scaled.jpg)`,
			}}
		>
			{loading && <p>Loading...</p>}
			{!loading && user ? (
				(navigate("/home"), null) // Return null after navigating
			) : (
				<div
					className="subcontainer"
					style={{
						backgroundColor: "white",
						borderRadius: "20px",
						minWidth: "20vw",
						minHeight: "36vh",
					}}
				>
					<img
						src={typescriptLogo}
						alt="Logo"
						className="logo"
						style={{maxWidth: "10vw", alignItems: "center"}}
					/>
					<input
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="inputField"
					/>
					<input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						className="inputField"
					/>
					<button
						className="signupButton"
						onClick={handleEmailAndPasswordLogin}
						style={{maxWidth: "15vw"}}
					>
						Login
					</button>
					<button
						className="signupButton"
						onClick={handleMicrosoftLogin}
						style={{maxWidth: "15vw"}}
					>
						Microsoft login (Praeter)
						{/* <img src="../../assets/images/microsoft-logo.png" style={{ width: "20px", marginRight: "5px" }} /> */}
					</button>
					{/* <button
                        // className="signupButton"
                        onClick={handleSignup}
                        style={{ maxWidth: "15vw", fontFamily: "Karla" }}
                    >
                        Signup
                    </button> */}
					{errorMessage && <p className="errorMessage">{errorMessage}</p>}
				</div>
			)}

			{error && <p className="errorMessage">{error.message}</p>}
		</div>
	);
};

export default LoginScreen;
