import React, {useEffect, useState} from "react";
import InputSpec from "../../components/computation-input-specific";
import MWASelectorPanel from "../../components/computation-solar-selector";
import {
	mergeDoc,
	docRef,
	fetchDocsData,
	fetchDocData,
} from "../../components/utils/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import {Box} from "@mui/system"; // Import Box from @mui/system for better style handling
import {setDoc} from "firebase/firestore";

interface EnergySavingComputationSpecsProps {
	updateId: string;
	selectedMeasure: any;
	measures: any[];
	interactionData: any[];
	loadedClientInputs: any;
	selectedInteraction: string;
	pageReady: boolean;
	template: any;
}

function areAllFieldsFilled(template: any, data: any) {
	const requiredCategories = [
		"Verbruiksgegevens",
		"Gegevens constructie",
		"Gegevens pand",
	];
	if (template) {
		const requiredFields = Object.keys(template).reduce(
			(filteredRecords: any, key: string) => {
				const record = template[key];
				if (
					record.input_categories &&
					(record?.conditional_field === "" ||
						record?.conditional_field === undefined) &&
					requiredCategories.includes(record.input_categories)
				) {
					filteredRecords[key] = record;
				}
				return filteredRecords;
			},
			{}
		);

		return Object.keys(requiredFields).every(
			(field) => data[field] && data[field] !== ""
		);
	} else {
		return false;
	}
}

const EnergySavingComputationSpecs: React.FC<
	EnergySavingComputationSpecsProps
> = ({
	updateId,
	selectedMeasure,
	interactionData,
	selectedInteraction,
	pageReady,
	template,
}) => {
	const [text, setText] = useState("");
	const [selectedOption, setSelectedOption] = useState<string>("");
	const [interactionDataPV, setInteractionDataPV] = useState<any>([]);
	const [interactionResults, setInteractionResults] = useState<any>({});

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const ref = docRef(
			`energy_saving_computations/${updateId}/measures`,
			selectedMeasure
		);

		const newText = event.target.value;
		setText(newText);
		setDoc(ref, {template_text: newText}, {merge: true}); // Update the text in Firestore
	};

	useEffect(() => {
		const fetchData = async () => {
			if (selectedMeasure) {
				const docData = await fetchDocData(
					`energy_saving_computations/${updateId}/measures`,
					selectedMeasure
				);
				const newText = docData?.template_text || ""; // Get the text from Firestore
				setText(newText);
			}
		};

		fetchData();
	}, [updateId, selectedMeasure]);

	const handleChangePV = async (event: React.ChangeEvent<{value: unknown}>) => {
		const selectedOption = event.target.value as string;
		setSelectedOption(selectedOption);
		const selectedInteractionData: any = interactionData.find(
			(item) => item.id === selectedInteraction
		);
		fetchDocData(
			`solar_energy_computations/${selectedInteractionData?.fields?.ZOzZUApotNfocQBtxxUV}/results`,
			selectedOption
		).then((data) => {
			setInteractionResults(data);
			mergeDoc(
				`energy_saving_computations/${updateId}/computation_inputs`,
				selectedInteraction,
				{
					solar_results: data,
					fields: {
						rIvMYj49iV1FT5h4x0Zt: selectedOption,
					},
				}
			);
		});
	};

	useEffect(() => {
		if (interactionData) {
			const status = areAllFieldsFilled(template, interactionData);
			if (status !== pageReady) {
				mergeDoc("energy_saving_computations", updateId, {
					progress: {
						3: {
							done: status,
							name: "Computation specs",
						},
					},
				});
			}
		}
	}, [template, interactionData, updateId, pageReady]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const selectedInteractionData: any = interactionData.find(
					(item) => item.id === selectedInteraction
				);
				const dataInteraction = await fetchDocsData(
					`solar_energy_computations/${selectedInteractionData?.fields?.ZOzZUApotNfocQBtxxUV}/computation_inputs`
				);
				setInteractionDataPV(dataInteraction as any);
				setInteractionResults(selectedInteractionData?.solar_results);
				setSelectedOption(
					selectedInteractionData?.fields?.rIvMYj49iV1FT5h4x0Zt || ""
				);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};
		if (selectedInteraction) {
			fetchData();
		}
	}, [selectedInteraction, interactionData]);

	return (
		<div
			style={{
				...styles.container,
				marginLeft: "20px",
				minHeight: "90vh",
				display: "flex",
			}}
		>
			<div style={{height: "100%"}}>
				{selectedInteraction !== "" && (
					<textarea
						placeholder="Samenvatting (diagnose o.b.v. ALECOBO/schouw) + voorgestelde maatregel"
						value={text}
						onChange={handleChange}
						style={{
							margin: "0 0 10px",
							padding: "8px",
							borderRadius: "4px",
							fontSize: "14px",
							marginLeft: "-10px",
							marginBottom: "10px",
							marginRight: "10px",
							fontFamily: "Karla",
							minHeight: "150px",
							maxHeight: "150px",
							minWidth: "900px",
							resize: "vertical",
							border: "none",
							outline: "none",
							boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
						}}
					/>
				)}
				{selectedInteraction !== "" && Object.keys(template).length > 0 && (
					<div
						style={{
							display: "flex",
							justifyContent: "",
							marginLeft: "-10px",
						}}
					>
						{Object.keys(template).filter((key) =>
							template[key]?.input_categories?.includes("Verbruiksgegevens")
						).length > 0 && (
							<Box
								sx={{
									...styles.rightPanelStyle,
									maxWidth: "250px",
									marginRight: "10px",
									minHeight: `calc(90vh - 180px)`,
									maxHeight: `calc(90vh - 180px)`,
									textTransform: "none",
								}}
							>
								<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
									Verbruiksgegevens
								</h3>
								<InputSpec
									updateId={updateId}
									interactionId={selectedInteraction}
									interactionData={interactionData}
									templateUnfiltered={template}
									filter={"Verbruiksgegevens"}
									computationType={"energy_saving_computations"}
								/>
							</Box>
						)}
						{Object.keys(template).filter((key) =>
							template[key]?.input_categories?.includes("Gegevens constructie")
						).length > 0 && (
							<Box
								sx={{
									...styles.rightPanelStyle,
									marginRight: "10px",
									maxWidth: "250px",
									minHeight: `calc(90vh - 180px)`,
									maxHeight: `calc(90vh - 180px)`,
									textTransform: "none",
								}}
							>
								<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
									Gegevens constructie
								</h3>
								<InputSpec
									updateId={updateId}
									interactionId={selectedInteraction}
									interactionData={interactionData}
									templateUnfiltered={template}
									filter={"Gegevens constructie"}
									computationType={"energy_saving_computations"}
								/>
							</Box>
						)}
						{interactionData
							.find((item) => item.id === selectedInteraction)
							?.measureIds.includes("m3xeVR6gJwhjrbfn9iCj") &&
						selectedMeasure === "m3xeVR6gJwhjrbfn9iCj" ? (
							<div style={{marginLeft: "-10px"}}>
								<MWASelectorPanel
									selectedOption={selectedOption}
									handleChange={handleChangePV}
									interactionDataPV={interactionDataPV}
									interactionResults={interactionResults}
								/>
							</div>
						) : (
							Object.keys(template).some((key) =>
								template[key]?.input_categories?.includes("Gegevens pand")
							) && (
								<Box
									sx={{
										...styles.rightPanelStyle,
										marginRight: "10px",
										minHeight: `calc(90vh - 180px)`,
										maxHeight: `calc(90vh - 180px)`,
										maxWidth: "250px",
										textTransform: "none",
									}}
								>
									<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
										Gegevens pand
									</h3>
									<InputSpec
										updateId={updateId}
										interactionId={selectedInteraction}
										interactionData={interactionData}
										templateUnfiltered={template}
										filter={"Gegevens pand"}
										computationType={"energy_saving_computations"}
									/>
								</Box>
							)
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default EnergySavingComputationSpecs;
