import WelcomePage from "../screens/home/Home";
// import PV from "../screens/pv";
import Login from "../screens/login/Login";
// import SolarEnergyInitialize from '../screens/energy-solar-initialize'
import SolarEnergy from "../layout/solar-energy";

import Data from "../layout/data";
import SettingsPage from "../screens/management-users";
import EnergySaving from "../layout/energy-saving";
import InfoPage from "../screens/site-information";
import CustomerSuccessPage from "../screens/customer-success-info";
import EnergyScreening from "../layout/energy-screening";
import SolarRental from "../layout/solar-rental";
import SignInPage from "../screens/signup.tsx/Signup";

const routes = [
	{
		path: "/home",
		component: WelcomePage,
		name: "Home Page",
		protected: true,
		args: {},
	},
	{
		path: "/",
		component: WelcomePage,
		name: "Home Page",
		protected: true,
		args: {},
	},
	{
		path: "/info",
		component: InfoPage,
		name: "Information page",
		protected: true,
		args: {},
	},

	{
		path: "/customer-success/:organizationId?",
		component: CustomerSuccessPage,
		name: "Information page",
		protected: true,
		args: {},
	},
	{
		path: "/solar-energy/:dealId?/:selectedPageName?",
		component: SolarEnergy,
		name: "Initialize PV",
		protected: true,
		args: {},
	},
	{
		path: "/solar-rental/:dealId?/:selectedPageName?",
		component: SolarRental,
		name: "Rental advise",
		protected: true,
		args: {},
	},

	{
		path: "/energy-screening/:dealId?/:selectedPageName?",
		component: EnergyScreening,
		name: "Initialize Energy Screening",
		protected: true,
		args: {},
	},
	{
		path: "/energy-saving/:dealId?/:selectedPageName?",
		component: EnergySaving,
		name: "Initialize MDV",
		protected: true,
		args: {},
	},
	{
		path: "/data/:collection?",
		component: Data,
		name: "Data",
		protected: true,
		args: {},
	},
	{
		path: "/settings",
		component: SettingsPage,
		name: "Settingspage",
		protected: true,
		args: {},
	},
	{
		path: "/login",
		component: Login,
		name: "Login Screen",
		protected: false,
		args: {},
	},
	{
		path: "/signup",
		component: SignInPage,
		name: "SignIn Screen",
		protected: false,
		args: {},
	},
];

export default routes;
