import React, {useEffect, useState} from "react";
import {styles} from "../../components/utils/Styles";
import CostsEditableTable from "../../components/costs-edit-table";
import {fetchPVCosts} from "../../components/utils/apiFunctions";
import {mergeDoc} from "../../components/utils/firebaseFunctions";
import {useTranslation} from "react-i18next";

interface Cost {
	id: string;
	name: string;
	value: number;
	frequency: string;
	// Define other properties of Cost
}

interface SolarEnergyCostsProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: any[]; // Define the type of interactionData appropriately
	pageReady: boolean;
}

const SolarEnergyCosts: React.FC<SolarEnergyCostsProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	pageReady,
}) => {
	const {t} = useTranslation();
	const [costsInvest, setCostsInvest] = useState(
		(
			interactionData.find(
				(interaction) => interaction.id === selectedInteraction
			)?.costs || []
		).filter((cost: Cost) => cost.frequency === "invest") || []
	);
	const [costsOneTime, setCostsOneTime] = useState(
		(
			interactionData.find(
				(interaction) => interaction.id === selectedInteraction
			)?.costs || []
		).filter((cost: Cost) => cost.frequency === "one time") || []
	);
	const [costsRecurring, setCostsRecurring] = useState(
		(
			interactionData.find(
				(interaction) => interaction.id === selectedInteraction
			)?.costs || []
		).filter((cost: Cost) => cost.frequency === "yearly") || []
	);
	const [costPayload, setCostPayload] = useState<any>({});
	const [submittedInvest, setSubmittedInvest] = useState<boolean>(
		costsInvest.length > 0 || true
	);
	const [submittedOneTime, setSubmittedOneTime] = useState<boolean>(
		costsOneTime.length > 0 || true
	);
	const [submittedYearly, setSubmittedYearly] = useState<boolean>(
		costsRecurring.length > 0 || true
	);
	const [action, setAction] = useState<boolean>(false);
	const kwpValue =
		interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		)?.fields["vatxUDBKEj3Xf6k5vRtV"] *
		interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		)?.fields["7tMOFAmxA926fCMQi96X"];
	const userType = interactionData.find(
		(interaction) => interaction.id === selectedInteraction
	)?.fields["fwceCp2oXft0jHzJlJxu"];

	useEffect(() => {
		// Filter and initialize costs based on selected interaction
		const selectedInteractionData = interactionData.find(
			(interaction) => interaction.id === selectedInteraction
		);
		if (selectedInteractionData && selectedInteractionData.costs) {
			setCostsInvest(
				selectedInteractionData.costs.filter(
					(cost: Cost) => cost.frequency === "invest"
				) || []
			);
			setCostsOneTime(
				selectedInteractionData.costs.filter(
					(cost: Cost) => cost.frequency === "one time"
				) || []
			);
			setCostsRecurring(
				selectedInteractionData.costs.filter(
					(cost: Cost) => cost.frequency === "yearly"
				) || []
			);
		} else {
			// If selected interaction doesn't exist, reset costs
			setCostsInvest([]);
			setCostsOneTime([]);
			setCostsRecurring([]);
		}
	}, [selectedInteraction, interactionData]);

	// useEffect(() => {
	// 	if (!interactionData || (interactionData && interactionData.length === 0))
	// 		return;
	// 	const costsInteraction =
	// 		interactionData.find(
	// 			(interaction) => interaction.id === selectedInteraction
	// 		)?.costs || [];
	// 	const status = costsInteraction.length > 0;
	// 	mergeDoc("solar_energy_computations", updateId, {
	// 		progress: {
	// 			4: {
	// 				done: status,
	// 				name: "Costs",
	// 			},
	// 		},
	// 	});
	// }, [interactionData, selectedInteraction, updateId]);

	useEffect(() => {
		const fetchCosts = async () => {
			try {
				if (
					interactionData.filter(
						(interaction) => interaction.id === selectedInteraction
					).length > 0
				) {
					const dataInteraction = interactionData.find(
						(interaction) => interaction.id === selectedInteraction
					)?.fields;
					const data = {
						type_user:
							dataInteraction?.fwceCp2oXft0jHzJlJxu === "Grootverbruiker"
								? "GV"
								: "KV",
						wp_per_panel: dataInteraction?.["7tMOFAmxA926fCMQi96X"] || 0,
						total_pv: dataInteraction?.["vatxUDBKEj3Xf6k5vRtV"] || 0,
						type_roof: dataInteraction?.["YZD9APtUiugNC2zJCsBU"] || "",
					};
					setCostPayload(data);
					if (
						costsRecurring.length === 0 &&
						costsOneTime.length === 0 &&
						costsInvest.length === 0 &&
						(interactionData.find(
							(interaction) => interaction.id === selectedInteraction
						)?.costs === undefined ||
							interactionData.find(
								(interaction) => interaction.id === selectedInteraction
							)?.costs?.length === 0)
					) {
						const costData = await fetchPVCosts(data);

						const investment = costData.data.filter(
							(record: Cost) => record.frequency === "invest"
						);
						const oneTime = costData.data.filter(
							(record: Cost) => record.frequency === "one time"
						);
						const recurring = costData.data.filter(
							(record: Cost) => record.frequency === "yearly"
						);
						setCostsInvest(investment);
						setCostsOneTime(oneTime);
						setCostsRecurring(recurring);
						setSubmittedInvest(false);
						setSubmittedOneTime(false);
						setSubmittedYearly(false);
						setAction(true);
					}
				}
			} catch (error) {
				console.error("Error fetching costs:", error);
			}
		};
		fetchCosts();
	}, [interactionData, selectedInteraction]);

	useEffect(() => {
		// console.log(interactionData.filter(
		// 	(interaction) => interaction.id === selectedInteraction
		// ).length >0)
		// console.log(pageReady)
		if (
			interactionData.filter(
				(interaction) => interaction.id === selectedInteraction
			).length > 0
		) {
			const status =
				interactionData.find(
					(interaction) => interaction.id === selectedInteraction
				)?.costs?.length > 0;
			if (!(status === pageReady)) {
				mergeDoc("solar_energy_computations", updateId, {
					progress: {
						4: {
							done: status,
							name: "Costs",
						},
					},
				});
			}
		}
	}, [interactionData, pageReady, selectedInteraction, updateId]);

	useEffect(() => {
		const handleAddCosts = () => {
			const updatedCosts = costsInvest
				.concat(costsOneTime)
				.concat(costsRecurring);
			if (action === true) {
				mergeDoc(
					`solar_energy_computations/${updateId}/computation_inputs`,
					selectedInteraction,
					{
						costs: updatedCosts,
						computation_present: false,
					}
				);
				setAction(false);
			}
		};
		handleAddCosts();
	}, [
		action,
		costsInvest,
		costsOneTime,
		costsRecurring,
		interactionData,
		selectedInteraction,
		submittedInvest,
		submittedOneTime,
		submittedYearly,
		updateId,
	]);

	return (
		<div style={{display: "flex", gap: "16px"}}>
			<div
				style={{
					...styles.rightPanelStyle,
					marginLeft: "10px",
					maxWidth: "400px",
					maxHeight: "90vh",
					position: "relative",
				}}
			>
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t("investmentCosts")}
				</h3>
				<CostsEditableTable
					context={costPayload}
					submitted={submittedInvest}
					setSubmitted={setSubmittedInvest}
					data={costsInvest}
					setData={setCostsInvest}
					type={"invest"}
					editMode={true}
					setAction={setAction}
					kwpValue={kwpValue}
					userType={userType}
				/>
			</div>
			<div
				style={{
					...styles.rightPanelStyle,
					maxWidth: "400px",
					position: "relative",
					marginLeft: "-10px",
				}}
			>
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t("oneTimeCosts")} {/* Translation key for "Eenmalige kosten" */}
				</h3>
				<CostsEditableTable
					context={costPayload}
					submitted={submittedOneTime}
					setSubmitted={setSubmittedOneTime}
					data={costsOneTime}
					setData={setCostsOneTime}
					type={"one time"}
					editMode={true}
					setAction={setAction}
					kwpValue={kwpValue}
					userType={userType}
				/>
			</div>
			<div
				style={{
					...styles.rightPanelStyle,
					maxWidth: "400px",
					position: "relative",
					marginLeft: "-10px",
				}}
			>
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t("annualCosts")} {/* Translation key for "Eenmalige kosten" */}
				</h3>
				<CostsEditableTable
					context={costPayload}
					submitted={submittedYearly}
					setSubmitted={setSubmittedYearly}
					data={costsRecurring}
					setData={setCostsRecurring}
					type={"yearly"}
					editMode={true}
					setAction={setAction}
					kwpValue={kwpValue}
					userType={userType}
				/>
			</div>
		</div>
	);
};

export default SolarEnergyCosts;
