import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {auth} from "./config/firebase";
import routes from "./config/routes";
import Center from "./components/utils/Center";
import AuthChecker from "./components/auth/AuthChecker";
// import { ThemeProvider } from '@material-ui';
// import theme from './theme'; // Import your theme file
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

function App() {
	console.log(process.env.REACT_APP_ENV);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				console.info("User detected.");
			} else {
				console.info("No user detected");
			}
			setLoading(false);
		});
	}, []);

	if (loading)
		return (
			// <ThemeProvider theme={theme}>
			<Center>
				<CircularProgress />
			</Center>
			// </ThemeProvider>
		);

	return (
		<DndProvider backend={HTML5Backend}>
			<div>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<Routes>
						{routes.map((route, index) => (
							<Route
								key={index}
								path={route.path}
								element={
									route.protected ? (
										<AuthChecker>
											<route.component {...route.args} />
										</AuthChecker>
									) : (
										<route.component />
									)
								}
							/>
						))}
					</Routes>
				</BrowserRouter>
			</div>
		</DndProvider>
	);
}

export default App;