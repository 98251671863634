import React from "react";
import {Box, Button, Typography} from "@mui/material";
import ProductSelector from "../../components/product-selector";

interface Product {
	id: string;
	phase: string;
	name: string;
	description: string;
	prices: {currency: string; price: number}[];
}

interface ProductSectionProps {
	products: Product[];
	loading: boolean;
	selectedProduct: Product | null;
	onProductSelect: (product: Product | null) => void;
	onButtonClick: () => void;
}

const ProductSection: React.FC<ProductSectionProps> = ({
	products,
	loading,
	selectedProduct,
	onProductSelect,
	onButtonClick,
}) => {
	return (
		<Box
			sx={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				flexGrow: 1,
				borderRadius: "5px",
				minHeight: "90%",
				flex: 1,
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				padding: "10px",
			}}
		>
			<div style={{maxWidth: "500px"}}>
				<h2 style={{marginLeft: "10px"}}>Create estimate</h2>
				<ProductSelector
					products={products}
					onSelect={onProductSelect as any}
					loading={loading}
				/>
			</div>
			{selectedProduct && (
				<Box
					sx={{
						position: "relative",
						padding: "20px",
						backgroundColor: "#f9f9f9",
						fontSize: "0.6rem",
						borderRadius: "5px",
						boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
						maxWidth: "500px",
						fontFamily: "karla",
					}}
				>
					<Button
						sx={{
							position: "absolute",
							top: "10px",
							right: "10px",
							fontSize: "0.8rem",
							backgroundColor: "#007bff",
							color: "#fff",
							"&:hover": {backgroundColor: "#0056b3"},
						}}
						onClick={onButtonClick}
					>
						Button Text
					</Button>
					<Typography
						variant="subtitle1"
						sx={{
							marginBottom: "2px",
							fontWeight: "bold",
							fontFamily: "karla",
							fontSize: "0.9rem",
						}}
					>
						Name:
					</Typography>
					<Typography
						variant="body1"
						sx={{marginBottom: "12px", fontFamily: "karla", fontSize: "0.9rem"}}
					>
						{selectedProduct.name}
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							marginBottom: "2px",
							fontWeight: "bold",
							fontFamily: "karla",
							fontSize: "0.9rem",
						}}
					>
						Description:
					</Typography>
					<Typography
						variant="body1"
						sx={{
							marginBottom: "12px",
							fontFamily: "karla",
							maxHeight: "150px",
							overflow: "auto",
							fontSize: "0.9rem",
						}}
					>
						{selectedProduct.description || "No description available"}
					</Typography>
					<Typography
						variant="subtitle1"
						sx={{
							marginBottom: "2px",
							fontWeight: "bold",
							fontFamily: "karla",
							fontSize: "0.9rem",
						}}
					>
						Price:
					</Typography>
					<Typography
						variant="body1"
						sx={{
							marginBottom: "2px",
							fontWeight: "bold",
							fontFamily: "karla",
							fontSize: "0.9rem",
						}}
					>
						{`${
							selectedProduct.prices[0].currency
						} ${selectedProduct.prices[0].price.toFixed(2)}`}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default ProductSection;
