import {fetchDocsDataStream} from "./firebaseFunctions";

export const fetchInteractions = (
	updateId: string,
	computationType: string,
	setInteractionData: any
) => {
	if (updateId) {
		return fetchDocsDataStream(
			`${computationType}/${updateId}/computation_inputs`,
			setInteractionData
		);
	}
	return () => {}; // No-op function for cleanup
};

export const fetchRooms = (
	updateId: string,
	computationType: string,
	setListRooms: any
) => {
	if (updateId) {
		return fetchDocsDataStream(
			`${computationType}/${updateId}/rooms`,
			setListRooms
		);
	}
	return () => {}; // No-op function for cleanup
};

export const fetchAll = (
	updateId: string,
	computationType: string,
	setGeneralData: any
) => {
	if (updateId) {
		return fetchDocsDataStream(
			`${computationType}/${updateId}/general_inputs`,
			setGeneralData
		);
	}
	return () => {}; // No-op function for cleanup
};

export const fetchMeasures = (
	updateId: string,
	computationType: string,
	setMeasures: any
) => {
	if (updateId) {
		return fetchDocsDataStream(
			`${computationType}/${updateId}/measures`,
			setMeasures
		);
	}
	return () => {}; // No-op function for cleanup
};
