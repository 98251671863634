import React, {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./styles.css"; // Import the CSS file

// Set the workerSrc property
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

interface PDFViewerProps {
	downloadURL: string;
	scale?: number;
}

export const downloadMultipleFiles = (files: any) => {
	// Dynamically create anchor tags and trigger click event for each
	files.forEach((file: any) => {
		const link = document.createElement("a");
		link.href = file;
		link.download = "";
		link.target = "_blank";
		link.style.display = "none";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
};

const PDFViewer: React.FC<PDFViewerProps> = ({downloadURL, scale = 1.0}) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const onLoadSuccess = ({numPages}: {numPages: number}) => {
		setNumPages(numPages);
	};

	return (
		<Document file={downloadURL} onLoadSuccess={onLoadSuccess}>
			{Array.from(new Array(numPages || 0), (el, index) => (
				<React.Fragment key={`page_${index + 1}`}>
					<Page pageNumber={index + 1} scale={scale} renderTextLayer={false} />
					{index < (numPages || 1) - 1 && <hr className="page-break-line" />}
				</React.Fragment>
			))}
			<div className="end-of-document"></div>{" "}
			{/* Add a div to indicate the end of the document */}
		</Document>
	);
};

export default PDFViewer;
