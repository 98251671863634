import React, {useState, useEffect} from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
	Checkbox,
} from "@mui/material";
import {mergeDoc} from "../utils/firebaseFunctions";
import {useTranslation} from "react-i18next";

interface FieldData {
	VXVu21OSMDROEVjGguuO: any;
	TQ5rMwQ6srDuTuZCM5Cy: string;
	selected: boolean; // Add a selected field to track the checkbox state
}

interface RowData {
	name: string;
	fields: FieldData;
	rank: number;
	id: string;
	selected: boolean; // Add a selected field to track the checkbox state
}

interface TariffTableProps {
	initialRows: RowData[];
	updateId: string;
}

const TariffTable: React.FC<TariffTableProps> = ({updateId, initialRows}) => {
	const [rows, setRows] = useState<RowData[]>(initialRows);
	const [selectedRow, setSelectedRow] = useState<number | null>(
		initialRows.findIndex((row) => row?.selected)
	);
	const {t} = useTranslation();
	useEffect(() => {
		setRows(initialRows);
	}, [initialRows]);

	const sortedRows = [...rows].sort((a, b) => a.rank - b.rank);

	const handleChange = (
		value: string,
		rowIndex: number,
		colKey: keyof FieldData
	) => {
		const updatedRows: any = [...sortedRows];
		updatedRows[rowIndex].fields[colKey] = value;
		setRows(updatedRows);
		mergeDoc(
			`solar_rental_computations/${updateId}/computation_inputs`,
			updatedRows[rowIndex].id,
			{
				fields: {[colKey]: value},
			}
		);
	};

	const handleCheckboxChange = (rowIndex: number) => {
		const updatedRows = sortedRows.map((row, index) => ({
			...row,
			fields: {...row.fields},
			selected: index === rowIndex,
		}));
		// setRows(updatedRows);
		setSelectedRow(rowIndex);
		updatedRows.forEach((row, index) => {
			mergeDoc(
				`solar_rental_computations/${updateId}/computation_inputs`,
				row.id,
				{
					selected: index === rowIndex,
				}
			);
		});
	};

	return (
		<div>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{t("name")}</TableCell>
							<TableCell>{t("currentTariff")}</TableCell>
							<TableCell>{t("newTariff")}</TableCell>
							<TableCell>Select</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedRows.map((row: any, index) => (
							<TableRow key={index}>
								<TableCell style={{minWidth: "200px"}}>
									{t("language") === "Language" && row?.name_en
										? row.name_en
										: row.name}
								</TableCell>
								<TableCell style={{height: "20px", padding: "4px 8px"}}>
									{row.fields?.["PO2qBk43f6faOOheeQz8"] === "investor" ? (
										<TextField
											type="number"
											style={{
												width: "100%",
												boxSizing: "border-box",
												fontSize: "12px",
											}}
											value={row.fields?.["VXVu21OSMDROEVjGguuO"]}
											onChange={(e) =>
												handleChange(
													e.target.value,
													index,
													"VXVu21OSMDROEVjGguuO"
												)
											}
											inputProps={{step: 0.01}}
										/>
									) : (
										<span>{row.fields?.["VXVu21OSMDROEVjGguuO"]}</span>
									)}
								</TableCell>
								<TableCell style={{height: "20px", padding: "4px 8px"}}>
									{row.fields?.["PO2qBk43f6faOOheeQz8"] === "investor" ? (
										<TextField
											type="number"
											style={{
												width: "100%",
												boxSizing: "border-box",
												fontSize: "12px",
											}}
											value={row.fields?.["TQ5rMwQ6srDuTuZCM5Cy"]}
											onChange={(e) =>
												handleChange(
													e.target.value,
													index,
													"TQ5rMwQ6srDuTuZCM5Cy"
												)
											}
											inputProps={{step: 0.01}}
										/>
									) : (
										<span>{row.fields?.["TQ5rMwQ6srDuTuZCM5Cy"]}</span>
									)}
								</TableCell>
								<TableCell>
									{index > 0 &&
										row.fields?.["PO2qBk43f6faOOheeQz8"] === "investor" && (
											<Checkbox
												checked={
													index === sortedRows.findIndex((row) => row?.selected)
												}
												onChange={() => handleCheckboxChange(index)}
											/>
										)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default TariffTable;
