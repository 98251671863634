import React from "react";
import {styled} from "@mui/system";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TextField,
} from "@mui/material";
import {useTranslation} from "react-i18next"; // Import for translations

// import { styles } from '../../components/utils/Styles';

interface RowData {
	month: string;
	peak: number | null;
	non_peak: number | null;
	total: number;
}

interface EditableTableProps {
	data: RowData[];
	setData: React.Dispatch<React.SetStateAction<RowData[]>>;
	setDataChanged: React.Dispatch<React.SetStateAction<boolean>>;
	editType: string;
	total: number;
}

const coefs: Record<string, {peak: number; non_peak: number; total: number}> = {
	"0": {
		peak: 0.0666590043972105,
		non_peak: 0.0299725667426191,
		total: 0.0966315711398296,
	},
	"1": {
		peak: 0.0637656457803341,
		non_peak: 0.0275039349761289,
		total: 0.0912695807564629,
	},
	"2": {
		peak: 0.0617560516139169,
		non_peak: 0.0230432087242086,
		total: 0.0847992603381255,
	},
	"3": {
		peak: 0.0535901797664119,
		non_peak: 0.0238287799896277,
		total: 0.0774189597560396,
	},
	"4": {
		peak: 0.0452297926297922,
		non_peak: 0.0174537508429408,
		total: 0.062683543472733,
	},
	"5": {
		peak: 0.054346460764553,
		non_peak: 0.0266937358912519,
		total: 0.0810401966558049,
	},
	"6": {
		peak: 0.0614106984876482,
		non_peak: 0.0261033971391943,
		total: 0.0875140956268424,
	},
	"7": {
		peak: 0.0708414560073909,
		non_peak: 0.0240056763104663,
		total: 0.0948471323178572,
	},
	"8": {
		peak: 0.0708414560073909,
		non_peak: 0.0240056763104663,
		total: 0.0948471323178572,
	},
	"9": {
		peak: 0.0471574804661581,
		non_peak: 0.016223408403515,
		total: 0.0633808888696731,
	},
	"10": {
		peak: 0.0595598225920807,
		non_peak: 0.0238834135330419,
		total: 0.0834432361251226,
	},
	"11": {
		peak: 0.0588499044115677,
		non_peak: 0.0232744982120842,
		total: 0.0821244026236519,
	},
};

const StyledTable = styled(Table)({
	maxWidth: 200,
	maxHeight: 20,
});

const StyledTableCell = styled(TableCell)`
	font-size: 14px;
	font-family: Karla;
	padding: 11px; /* Adjust padding if needed */
	& input {
		max-height: 8px;
		font-size: 14px;
		font-family: Karla;
	}
`;

const StyledTableCellValue = styled(TableCell)`
	font-size: 14px;
	font-family: Karla;
	padding: 11px; /* Adjust padding if needed */
	color: #267fb8;
	font-weight: bold;

	& input {
		max-height: 10px;
		font-size: 14px;
		font-family: Karla;
		font-weight: bold;
	}
`;

const StyledInput = styled(TextField)({
	minWidth: "80px",
	"& input": {
		maxHeight: "8px",
		fontSize: "14px",
		fontFamily: "Karla",
	},
	marginTop: "-10px",
	marginBottom: "-10px",
	marginLeft: "-20px",
});

const EditableTable: React.FC<EditableTableProps> = ({
	data,
	setData,
	setDataChanged,
	editType,
}) => {
	const editMode = true;
	const {t} = useTranslation(); // Translation hook

	const handleInputChange = (event: any, index: number, key: string) => {
		if (key !== "total" && editType === "Maandverbruik dal en piek") {
			const newData: any = [...data];
			newData[index][key] = Number(event.target.value);
			newData[index]["total"] =
				newData[index]["non_peak"] + newData[index]["peak"];
			setData(newData);
			setTimeout(() => {
				setDataChanged(true);
			}, 3);
		} else if (key === "total" && editType === "Maandverbruik") {
			const newData = [...data];
			newData[index]["total"] = Number(event.target.value);
			newData[index]["non_peak"] = Math.round(
				(Number(event.target.value) / coefs[index]["total"]) *
					coefs[index]["non_peak"]
			);
			newData[index]["peak"] = Math.round(
				(Number(event.target.value) / coefs[index]["total"]) *
					coefs[index]["peak"]
			);
			setData(newData);
			setTimeout(() => {
				setDataChanged(true);
			}, 3);
		}
	};

	const calculateColumnTotals = () => {
		let peakTotal = 0;
		let offPeakTotal = 0;
		let totalTotal = 0;

		data.forEach((row) => {
			peakTotal += parseFloat(row.peak as any);
			offPeakTotal += parseFloat(row.non_peak as any);
			totalTotal += parseFloat(row.total as any);
		});

		return {peakTotal, offPeakTotal, totalTotal};
	};

	const columnTotals = calculateColumnTotals();

	return (
		<>
			<TableContainer component={Paper} style={{boxShadow: "none"}}>
				<StyledTable aria-label="editable table">
					<TableHead>
						<TableRow>
							<StyledTableCell>{t("month")}</StyledTableCell>
							<StyledTableCell align="right">{t("peak")}</StyledTableCell>
							<StyledTableCell align="right">{t("offPeak")}</StyledTableCell>
							<StyledTableCell align="right">{t("total")}</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row, index) => (
							<TableRow key={row.month}>
								<StyledTableCell component="th" scope="row">
									{t(`months.${row.month}`)}
								</StyledTableCell>
								{editMode ? (
									<>
										<StyledTableCell align="right">
											<StyledInput
												type="number"
												value={row.peak}
												onChange={(event) =>
													handleInputChange(event, index, "peak")
												}
												disabled={[
													"Maandverbruik",
													"Alleen totaalverbruik",
												].includes(editType)}
											/>
										</StyledTableCell>
										<StyledTableCell align="right">
											<StyledInput
												type="number"
												value={row.non_peak}
												onChange={(event) =>
													handleInputChange(event, index, "non_peak")
												}
												disabled={[
													"Maandverbruik",
													"Alleen totaalverbruik",
												].includes(editType)}
											/>
										</StyledTableCell>
										<StyledTableCell align="right">
											<StyledInput
												type="number"
												value={row.total}
												onChange={(event) =>
													handleInputChange(event, index, "total")
												}
												disabled={[
													"Maandverbruik dal en piek",
													"Alleen totaalverbruik",
												].includes(editType)}
											/>
										</StyledTableCell>
									</>
								) : (
									<>
										<StyledTableCellValue align="right">
											{row.peak}
										</StyledTableCellValue>
										<StyledTableCellValue align="right">
											{row.non_peak}
										</StyledTableCellValue>
										<StyledTableCellValue align="right">
											{row.total}
										</StyledTableCellValue>
									</>
								)}
							</TableRow>
						))}
					</TableBody>
				</StyledTable>
			</TableContainer>
			<div
				style={{
					marginTop: "20px",
					fontFamily: "karla",
					fontSize: "14px",
					fontWeight: "bold",
				}}
			>
				<span>
					{t("peakTotal")}: {columnTotals.peakTotal}
				</span>
				<span style={{marginLeft: "20px"}}>
					{t("offPeakTotal")}: {columnTotals.offPeakTotal}
				</span>
				<span style={{marginLeft: "20px"}}>
					{t("totalTotal")}: {columnTotals.totalTotal}
				</span>
			</div>
		</>
	);
};

export default EditableTable;
