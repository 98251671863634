import React from "react";
import {CircularProgress, Box} from "@mui/material";
import TableComponent from "../../components/utils/renderTable";

interface EstimateData {
	url: string;
	reference: string;
	total_price_incl_tax: string;
	state: string;
	due_date: string;
	accepted_at: string | null;
}

interface EstimateTableProps {
	searching: boolean;
	estimates: EstimateData[];
}

const EstimateTable: React.FC<EstimateTableProps> = ({
	searching,
	estimates,
}) => {
	const columns = [
		{
			id: "url",
			label: "Reference",
			accessor: (estimate: EstimateData) => (
				<a href={estimate.url} target="_blank" rel="noopener noreferrer">
					{estimate.reference}
				</a>
			),
		},
		{
			id: "amount",
			label: "Amount",
			accessor: (estimate: EstimateData) => `€${estimate.total_price_incl_tax}`,
		},
		{
			id: "status",
			label: "Status",
			accessor: (estimate: EstimateData) => estimate.state,
		},
		{
			id: "dueDate",
			label: "Due Date",
			accessor: (estimate: EstimateData) =>
				new Date(estimate.due_date).toLocaleDateString(),
		},
	];

	return (
		<Box sx={{flex: 1, flexGrow: 1, borderRadius: "5px"}}>
			{searching ? (
				<CircularProgress />
			) : (
				<TableComponent
					computations={estimates}
					title="Estimates"
					path="estimates"
					columns={columns}
					expanded_default={true}
				/>
			)}
		</Box>
	);
};

export default EstimateTable;
