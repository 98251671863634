import {
	fetchDocData,
	createDoc,
	removeDoc,
	mergeDoc,
} from "../utils/firebaseFunctions";
import {copyFolder, removeFolder} from "../utils/s3ImageOperations";

export async function addDefaultComparisonPV(
	computationType: string,
	option: any,
	updateId: string,
	selectedInteraction: string
) {
	if (option === "Standaard Buca EV vergelijking") {
		const docData = await fetchDocData(
			`${computationType}/${updateId}/computation_inputs`,
			selectedInteraction
		);
		const results = await fetchDocData(
			`${computationType}/${updateId}/results`,
			selectedInteraction
		);
		const doc1 = {...docData};
		doc1["fields"]["R4LRyZTy60wI8MHKa3wQ"] = "Klant - (100% eigen vermogen)";
		doc1["fields"]["KLrpXGk9fWGtbdgMQnwf"] = 100;
		doc1["computation_present"] = false;
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			1,
			selectedInteraction,
			doc1,
			results
		);

		const doc2 = {...docData};
		doc2["fields"]["R4LRyZTy60wI8MHKa3wQ"] = "Klant - (met vreemd vermogen)";
		doc2["fields"]["KLrpXGk9fWGtbdgMQnwf"] = 50;
		doc2["computation_present"] = false;
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			2,
			selectedInteraction,
			doc2,
			results
		);

		const doc3 = {...docData};
		doc3["fields"]["R4LRyZTy60wI8MHKa3wQ"] = "Klant - (met vreemd vermogen)";
		doc3["fields"]["KLrpXGk9fWGtbdgMQnwf"] = 20;
		doc3["computation_present"] = false;
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			3,
			selectedInteraction,
			doc3,
			results
		);
	} else if (option === "Standaard verreken advies") {
		const docData = await fetchDocData(
			`${computationType}/${updateId}/computation_inputs`,
			selectedInteraction
		);
		const results = await fetchDocData(
			`${computationType}/${updateId}/results`,
			selectedInteraction
		);

		const doc1 = {...docData};
		doc1["fields"]["TQ5rMwQ6srDuTuZCM5Cy"] = 0.1;
		doc1["fields"]["PO2qBk43f6faOOheeQz8"] = "investor";
		doc1["computation_present"] = false;
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			1,
			selectedInteraction,
			doc1,
			results,
			"Eigenaar - Laag tarief"
		);
		doc1["fields"]["PO2qBk43f6faOOheeQz8"] = "tenant";

		createAndUpdateComputationInputs(
			computationType,
			updateId,
			2,
			selectedInteraction,
			doc1,
			results,
			"Huurder - Laag tarief"
		);

		const doc2 = {...docData};
		doc2["fields"]["TQ5rMwQ6srDuTuZCM5Cy"] = 0.125;
		doc2["fields"]["PO2qBk43f6faOOheeQz8"] = "investor";
		doc2["computation_present"] = false;
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			3,
			selectedInteraction,
			doc2,
			results,
			"Eigenaar - Midden tarief"
		);
		doc2["fields"]["PO2qBk43f6faOOheeQz8"] = "tenant";
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			4,
			selectedInteraction,
			doc2,
			results,
			"Huurder - Midden tarief"
		);

		const doc3 = {...docData};

		doc2["fields"]["TQ5rMwQ6srDuTuZCM5Cy"] = 0.15;
		doc3["fields"]["PO2qBk43f6faOOheeQz8"] = "investor";
		doc3["computation_present"] = false;
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			5,
			selectedInteraction,
			doc3,
			results,
			"Eigenaar - Hoog tarief"
		);
		doc3["fields"]["PO2qBk43f6faOOheeQz8"] = "tenant";
		createAndUpdateComputationInputs(
			computationType,
			updateId,
			6,
			selectedInteraction,
			doc3,
			results,
			"Huurder - Hoog tarief"
		);
	}
}

interface InteractionData {
	id: string;
	name: string;
	rank: number;
	input_ready: boolean;
}
const findMissingRank = (data: InteractionData[]) => {
	const uniqueRanks = [...new Set(data.map((record) => record.rank))];
	uniqueRanks.sort((a, b) => a - b);

	let missingRank = null;
	for (let i = 0; i < uniqueRanks.length - 1; i++) {
		if (uniqueRanks[i] + 1 !== uniqueRanks[i + 1]) {
			missingRank = uniqueRanks[i] + 1;
			break;
		}
	}

	if (missingRank === null && uniqueRanks.length > 0) {
		missingRank = uniqueRanks[uniqueRanks.length - 1] + 1;
	}

	return missingRank;
};

export async function deleteInteraction(
	computationType: string,
	updateId: string,
	selectedInteraction: string
) {
	await removeDoc(
		`${computationType}/${updateId}/computation_inputs`,
		selectedInteraction
	);
	await removeDoc(
		`${computationType}/${updateId}/results`,
		selectedInteraction
	);
	await removeFolder(
		`/${computationType}/${updateId}/${selectedInteraction}/images`
	);
	await removeFolder(
		`/${computationType}/${updateId}/${selectedInteraction}/output`
	);
}

async function createAndUpdateComputationInputs(
	computationType: string,
	updateId: string,
	missingRank: number,
	selectedInteraction: string,
	docData: any,
	results: any,
	name: string = ""
) {
	const id = await createDoc(
		`${computationType}/${updateId}/computation_inputs`,
		{
			...docData,
			name: name === "" ? `Scenario ${missingRank}` : name,
			name_en:
				name === ""
					? `Scenario ${missingRank}`
					: name
							.replace("Eigenaar", "Owner")
							.replace("Huurder", "Tenant")
							.replace("Midden", "Middle")
							.replace("Laag", "Low")
							.replace("Hoog", "High")
							.replace("tarief", "rate"),
			rank: missingRank,
		}
	);

	if (id) {
		await mergeDoc(`${computationType}/${updateId}/results`, id.id, results);

		await copyFolder(
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/${computationType}/${updateId}/${selectedInteraction}/images`,
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/${computationType}/${updateId}/${id.id}/images`
		);

		await copyFolder(
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/${computationType}/${updateId}/${selectedInteraction}/output`,
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}/${computationType}/${updateId}/${id.id}/output`
		);
	}
}

export async function addInteraction(
	computationType: string,
	updateId: string,
	selectedInteraction: any,
	interactionData: InteractionData[]
) {
	const missingRank = findMissingRank(interactionData);
	const docData = await fetchDocData(
		`${computationType}/${updateId}/computation_inputs`,
		selectedInteraction
	);
	const results = await fetchDocData(
		`${computationType}/${updateId}/results`,
		selectedInteraction
	);
	await createAndUpdateComputationInputs(
		computationType,
		updateId,
		missingRank || 0,
		selectedInteraction,
		docData,
		results
	);
}
