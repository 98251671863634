import {
	doc,
	getDoc,
	collection,
	getDocs,
	addDoc,
	setDoc,
	where,
	query,
	deleteDoc,
	onSnapshot,
	Timestamp,
	DocumentSnapshot,
	DocumentData as FirestoreDocumentData,
	updateDoc,
	collectionGroup,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";

// Define interface for Computation

// Define alias for Firestore's DocumentData to avoid conflicts
type DocumentData = FirestoreDocumentData;

const docRef = (path: string, documentId: string) => {
	return doc(firestore, `${path}/${documentId}`);
};
// Fetch document data from Firestore
const fetchDocData = async (
	collectionPath: string,
	documentId: string
): Promise<DocumentData> => {
	try {
		const docSnapshot: DocumentSnapshot<DocumentData> = await getDoc(
			doc(firestore, `${collectionPath}/${documentId}`)
		);
		if (docSnapshot.exists()) {
			// console.log(`Document ${documentId} fetched`);
			return docSnapshot.data()!;
		} else {
			console.log(
				`Document ${documentId} in collection ${collectionPath} not found`
			);
			return {};
		}
	} catch (error) {
		console.log(
			`Document ${documentId} in collection ${collectionPath} not found`, 
			error
		);
		return {};
	}
};

// Fetch documents data from Firestore
const fetchDocsData = async (collectionPath: string): Promise<DocumentData> => {
	try {
		const docsCollection = await getDocs(
			collection(firestore, `${collectionPath}`)
		);
		const docsCollectionData = docsCollection.docs.map((doc) => ({
			id: doc.id,
			...doc.data(),
		}));
		// console.log(`Documents collection ${collectionPath} fetched`);
		return docsCollectionData;
	} catch (error) {
		console.log(`Documents collection ${collectionPath} not found`, error);
		return [];
	}
};

// Fetch documents data stream from Firestore
const fetchDocsDataStream = (
	collectionPath: string,
	setInteractionData: React.Dispatch<React.SetStateAction<DocumentData[]>>
): (() => void) => {
	try {
		const collectionRef = collection(firestore, collectionPath);
		const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
			const docsCollectionData = snapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			setInteractionData(docsCollectionData);
		});
		return unsubscribe;
	} catch (error) {
		console.error("Error fetching documents from Firebase:", error);
		return () => {}; // No-op function for cleanup
	}
};

// Fetch document data stream from Firestore
const fetchDocDataStream = (
	collectionPath: string,
	documentId: string,
	setInteractionData: React.Dispatch<React.SetStateAction<DocumentData | null>>
): (() => void) => {
	try {
		const documentRef = doc(firestore, collectionPath, documentId);
		const unsubscribe = onSnapshot(documentRef, (snapshot) => {
			if (snapshot.exists()) {
				setInteractionData(snapshot.data()!);
			} else {
				setInteractionData(null);
			}
		});
		return unsubscribe;
	} catch (error) {
		console.error("Error fetching documents from Firebase:", error);
		return () => {}; // No-op function for cleanup
	}
};

// Fetch documents snapshot from Firestore
const fetchDocsSnap = async (collectionPath: string) => {
	try {
		const docsCollection = await getDocs(
			collection(firestore, `${collectionPath}`)
		);
		// console.log(`Documents collection snapshot ${collectionPath} fetched`);
		return docsCollection;
	} catch (error) {
		console.log(`Documents collection snapshot ${collectionPath} not found`, error);
		return null;
	}
};

// delete collection
const deleteCollection = async (collectionPath: string): Promise<void> => {
	try {
	  const collectionRef = collection(firestore, collectionPath);
	  const querySnapshot = await getDocs(collectionRef);
  
	  // Delete each document in the collection
	  querySnapshot.forEach(async (doc) => {
		await deleteDoc(doc.ref);
		console.log(`Document ${doc.id} deleted from collection ${collectionPath}`);
	  });
  
	  console.log(`All documents deleted from collection ${collectionPath}`);
	} catch (error) {
	  console.error(`Error deleting documents from collection ${collectionPath}`, error);
	}
  };

// Merge document in Firestore
const mergeDoc = async (
	collectionPath: string,
	selectedId: string,
	data: DocumentData
) => {
	try {
		const docRef = doc(firestore, collectionPath, selectedId);
		// console.log(`Document ${selectedId} updated`);
		data["updated_at"] = Timestamp.fromDate(new Date());
		await setDoc(docRef, data, { merge: true });
	} catch (error) {
		console.log(`Error merging document ${selectedId}`, error);
	}
};

// Merge document in Firestore
const mergeDocWhenExists = async (
	collectionPath: string,
	selectedId: string,
	data: DocumentData
) => {
	try {
		const docRef = doc(firestore, collectionPath, selectedId);
		data["updated_at"] = Timestamp.fromDate(new Date());
		await updateDoc(docRef, data);
		// await setDoc(docRef, data, { merge: true });
	} catch (error) {
		console.log(`Error merging document ${selectedId}`, error);
	}
};



// Create document in Firestore
const createDoc = async (
	collectionPath: string,
	documentData: DocumentData
) => {
	try {
		const collectionRef = collection(firestore, collectionPath);
		console.log(`Document in ${collectionPath} created`);
		documentData["created_at"] = Timestamp.fromDate(new Date());
		return await addDoc(collectionRef, documentData);
	} catch (error) {
		console.log(`Error creating document in ${collectionPath}`);
		return null;
	}
};

// Remove document from Firestore
const removeDoc = async (collectionPath: string, documentId: string) => {
	try {
		const documentRef = doc(firestore, `${collectionPath}/${documentId}`);
		await deleteDoc(documentRef);
		console.log(`Success with deletion ${documentId}`);
	} catch (error) {
		console.log(`Error with deletion ${documentId}`);
	}
};

// Query collection in Firestore
const queryCollection = async (
	collectionPath: string,
	filters: { [key: string]: any }
) => {
	try {
		const collectionRef = collection(firestore, collectionPath);
		let q: any = collectionRef;
		// Iterate over each key-value pair in the filters object and add a where clause for each
		Object.entries(filters).forEach(([key, value]) => {
			q = query(q, where(key, "==", value));
		});
		// Execute the query
		const optionsSnapshot = await getDocs(q);
		const options = optionsSnapshot.docs
			.map((doc) => {
				const data = doc.data();
				return data ? { id: doc.id, ...data } : null;
			})
			.filter((doc) => doc !== null);

		return options;
	} catch (error) {
		console.error("Error fetching options from Firebase:", error);
		return [];
	}
};

const queryCollectionGroup = async (
	collectionPath: string,
	filters: { [key: string]: any }
) => {
	try {
		const collectionRef = collectionGroup(firestore, collectionPath);
		let q: any = collectionRef;
		// Iterate over each key-value pair in the filters object and add a where clause for each
		Object.entries(filters).forEach(([key, value]) => {
			q = query(q, where(key, "==", value));
		});
		// Execute the query
		const optionsSnapshot = await getDocs(q);
		const options = optionsSnapshot.docs
			.map((doc) => {
				const data = doc.data();
				return data ? { id: doc.id, ...data } : null;
			})
			.filter((doc) => doc !== null);

		return options;
	} catch (error) {
		console.error("Error fetching options from Firebase:", error);
		return [];
	}
};


export {
	fetchDocData,
	fetchDocsData,
	fetchDocDataStream,
	fetchDocsDataStream,
	fetchDocsSnap,
	deleteCollection,
	mergeDoc,
	mergeDocWhenExists,
	docRef,
	createDoc,
	removeDoc,
	queryCollection,
	queryCollectionGroup,
};
