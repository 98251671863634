import React, {useEffect, useState} from "react";
import {mergeDoc} from "../utils/firebaseFunctions";
import renderInputFields from "../utils/renderInputFields";
import {useTranslation} from "react-i18next";
interface Template {
	[key: string]: any;
}

interface FormData {
	[key: string]: any;
}

interface InputSpecProps {
	updateId: string;
	interactionId: string;
	interactionData: any;
	filter: string;
	templateUnfiltered: Template;
	computationType: string;
}

const InputSpec: React.FC<InputSpecProps> = ({
	updateId,
	interactionId,
	interactionData,
	filter,
	templateUnfiltered,
	computationType,
}) => {
	const {t} = useTranslation();
	const [template, setTemplate] = useState<Template>({});
	const [formData, setFormData] = useState<FormData>({});
	const [loading, setLoading] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [change, setChange] = useState<boolean>(false);
	const docId = interactionId;
	const path = `${computationType}/${updateId}/computation_inputs`;

	useEffect(() => {
		if (change === true && Object.keys(formData).length > 0) {
			mergeDoc(path, docId, {
				fields: formData,
				computation_present: false,
			});
			setChange(false);
		}
	}, [change, formData, path, docId]);

	useEffect(() => {
		const fetchAndFilterTemplate = async () => {
			try {
				const templateData = templateUnfiltered;
				let filteredRecords: Template = {};

				if (filter !== "") {
					Object.keys(templateData).forEach((key) => {
						const record = templateData[key];
						if (
							record.input_categories &&
							record.input_categories.includes(filter)
						) {
							filteredRecords[key] = record;
						}
					});
				} else {
					filteredRecords = templateData;
				}
				// Sort filtered template data
				const sortedTemplateData: Template = Object.fromEntries(
					Object.entries(filteredRecords).sort(
						([aKey, a], [bKey, b]) => a.order - b.order
					)
				);
				setTemplate(sortedTemplateData);
			} catch (error) {
				console.error("Error fetching and filtering template data:", error);
			}
		};

		fetchAndFilterTemplate();
	}, [templateUnfiltered, filter]);

	const fetchFormData = async (template: Template) => {
		try {
			const computationDataAll = await interactionData.find(
				(interaction: any) => interaction.id === interactionId
			);
			const computationDataFields = computationDataAll?.fields || {};
			setFormData(computationDataFields);
			const filteredRecords: Template = {};

			Object.keys(template).forEach((key) => {
				const record = template[key];
				if (
					record.input_categories &&
					record.input_categories.includes(filter)
				) {
					filteredRecords[key] = record;
				}
			});
			const areAllFieldsFilled = Object.entries(filteredRecords).every(
				([fieldId, fieldInfo]) => {
					if (
						!(
							fieldInfo?.conditional_field &&
							fieldInfo?.conditional_field !== "" &&
							!fieldInfo?.conditional_values.includes(
								computationDataFields[fieldInfo?.conditional_field]
							)
						)
					) {
						return (
							!!(
								computationDataFields[fieldId] !== "" &&
								computationDataFields[fieldId] !== undefined
							) || false
						);
					} else {
						return true;
					}
				}
			);
		} catch (error) {
			console.error("Error fetching computation data:", error);
			setFormData({});
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			if (loaded === false) {
				try {
					setLoading(true);
					await fetchFormData(template);
				} catch (error) {
					console.error("Error fetching template: ", error);
				} finally {
					setLoading(false);
					setLoaded(true);
				}
			}
		};

		fetchData();
	}, [interactionId, updateId, template, interactionData, loaded]);

	useEffect(() => {
		setLoaded(false);
	}, [interactionId, updateId, template]);

	return (
		<div>
			{loading ? (
				<p>Loading...</p>
			) : (
				<div>
					<div
						style={{
							position: "relative",
							maxHeight: "90vh",
							maxWidth: "300px",
						}}
					>
						{
							<>
								<div>
									{renderInputFields({
										template,
										formData,
										setFormData,
										setChange,
										t,
										filter,
									})}
								</div>
							</>
						}
					</div>
				</div>
			)}
		</div>
	);
};

export default InputSpec;
