import React, {useState} from "react";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useTranslation} from "react-i18next";

interface TableComponentProps {
	computations: any[];
	title: string;
	path: string;
	columns: {
		id: string;
		label: string;
		accessor: (comp: any) => React.ReactNode;
	}[];
	expanded_default?: boolean;
	stylingOptions?: React.CSSProperties;
}

const TableComponent: React.FC<TableComponentProps> = ({
	computations,
	title,
	columns,
	expanded_default = false,
	stylingOptions = {},
}) => {
	const {t} = useTranslation(); // Initialize translation hook
	const [expanded, setExpanded] = useState(expanded_default); // Table is collapsed by default

	const cellStyle = {
		maxWidth: "50px",
		whiteSpace: "nowrap",
		fontFamily: "karla",
		overflow: "hidden",
		textOverflow: "ellipsis",
	};

	return (
		<Accordion
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			style={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				flex: 1,
				padding: "5px",
				borderRadius: "5px",
				marginBottom: "10px",
				overflow: "auto",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				...stylingOptions,
			}}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography style={{fontFamily: "basic", fontWeight: "bold"}}>
					{title}{" "}
					<span style={{fontWeight: "bold", fontFamily: "basic"}}>
						({computations.length})
					</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell key={column.id} style={cellStyle}>
										{t(column.label)}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{computations.map((comp) => (
								<TableRow key={comp.id}>
									{columns.map((column) => (
										<TableCell key={column.id} style={cellStyle}>
											{column.accessor(comp)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</AccordionDetails>
		</Accordion>
	);
};

export default TableComponent;
