import React, {useEffect, useState} from "react";
import {
	useDrop,
	useDrag,
	DragSourceMonitor,
	DropTargetMonitor,
} from "react-dnd";

import {mergeDoc} from "../utils/firebaseFunctions";

interface MeasureProps {
	id: string;
	name: string;
	phase_id: number | null;
	index: number; // Add index to MeasureProps to maintain order
	addMeasureToPhase: (
		measureId: string,
		phase_id: number,
		targetIndex: number
	) => void;
}

interface PhaseProps {
	id: number;
	name: string;
	addMeasureToPhase: (
		measureId: string,
		phase_id: number,
		targetIndex: number
	) => void; // Add index parameter
	measures: MeasureProps[];
}
const Measure: React.FC<MeasureProps> = ({id, name, index, phase_id}) => {
	const [{isDragging}, drag] = useDrag({
		type: "MEASURE",
		item: {type: "MEASURE", id, index, phase_id}, // Include phase_id in the item
		end: (item: any, monitor: DragSourceMonitor) => {
			const dropResult: any = monitor.getDropResult();
			if (item && dropResult) {
				console.log(
					`Dropped measure ${item.id} onto phase ${dropResult.phase_id}`
				);
				if (dropResult.phase_id) {
					mergeDoc(`energy_saving_computations/ER_340/measures`, item.id, {
						phase_id: dropResult.phase_id,
						phase_index: dropResult.index,
					});
				}
			}
		},
		collect: (monitor: DragSourceMonitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [{isOver}, drop] = useDrop({
		accept: "MEASURE",
		drop: () => ({index}), // Specify the target index when dropping
		collect: (monitor: DropTargetMonitor) => ({
			isOver: monitor.isOver(),
		}),
	});

	const isActive = isOver && isDragging;

	return (
		<div
			ref={(node) => drag(drop(node))}
			style={{
				opacity: isDragging ? 0.5 : 1,
				cursor: "move",
				backgroundColor: isActive ? "lightblue" : "transparent",
			}}
		>
			{name}
		</div>
	);
};

const Phase: React.FC<PhaseProps> = ({
	id,
	name,
	addMeasureToPhase,
	measures,
}) => {
	const [{canDrop, isOver}, drop] = useDrop({
		accept: "MEASURE",
		drop: (item: any) => {
			const draggedIndex = item.index;
			const targetIndex = measures.length; // Drop at the end by default
			if (item.phase_id !== id || draggedIndex !== targetIndex) {
				addMeasureToPhase(item.id, id, targetIndex);
			}
		},
		collect: (monitor: DropTargetMonitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	const isActive = canDrop && isOver;

	return (
		<div
			ref={drop}
			style={{
				border: isActive ? "2px dashed #000" : "none",
				padding: "8px",
				marginBottom: "8px",
			}}
		>
			<h3 style={{margin: "0px 0"}}>{name}</h3>
			{measures.map((measure, index) => (
				<div key={measure.id}>
					<Measure {...measure} index={index} />
				</div>
			))}
		</div>
	);
};

interface MeasureManagementProps {
	updateId: string;
	measures: any[];
	computationType: string;
	phasesPreset?: any[];
}

const MeasureManagement: React.FC<MeasureManagementProps> = ({
	updateId,
	measures,
	computationType,
	phasesPreset = [],
}) => {
	const [phases, setPhases] = useState<PhaseProps[]>([]);

	// Define addMeasureToPhase function before using it in the useEffect hook
	const addMeasureToPhase = (
		measureId: string,
		phase_id: number,
		targetIndex: number
	) => {
		const measureToAdd = measures.find((measure) => measure.id === measureId);
		if (!measureToAdd) return;
		mergeDoc(`${computationType}/${updateId}/measures`, measureId, {
			phase_id: phase_id,
			phase_index: targetIndex,
		});
	};

	useEffect(() => {
		// Update phases based on measures
		const phaseMap = new Map<number, MeasureProps[]>();
		if (phasesPreset.length > 0) {
			phasesPreset.forEach((phase, index) => {
				phaseMap.set(phase.id, []);
			});
		}
		measures.forEach((measure, index) => {
			if (measure.phase_id) {
				const phase_id = measure.phase_id || 0; // Use 0 as default phase ID if null
				const phaseMeasures = phaseMap.get(phase_id) || [];
				phaseMeasures.push({...measure, index});
				phaseMap.set(phase_id, phaseMeasures);
			}
		});

		const updatedPhases: PhaseProps[] = Array.from(phaseMap.entries()).map(
			([phase_id, phaseMeasures]) => ({
				id: phase_id,
				name:
					phasesPreset.length > 0
						? phasesPreset.find((item) => item.id === phase_id)?.name
						: `Fase ${phase_id}`,
				measures: phaseMeasures,
				addMeasureToPhase, // Pass the function reference here
			})
		);

		setPhases(updatedPhases);
	}, [measures]); // Make sure to include addMeasureToPhase in the dependencies array

	const addPhase = () => {
		// Get an array of existing phase IDs
		const existingIds = phases.map((phase) => phase.id);

		// Find the smallest missing ID
		let newphase_id = 1;
		while (existingIds.includes(newphase_id)) {
			newphase_id++;
		}

		// Add the new phase with the smallest missing ID
		setPhases([
			...phases,
			{
				id: newphase_id,
				name: `Fase ${newphase_id}`,
				measures: [],
				addMeasureToPhase,
			},
		]);
	};

	return (
		<div>
			<div>
				{measures
					.filter((item) => !item?.phase_id)
					.map((measure, index) => (
						<div key={measure.id}>
							{/* Conditionally render Measure component if addMeasureToPhase is defined */}
							{addMeasureToPhase && (
								<Measure
									{...measure}
									index={index}
									targetIndex={index}
									phase_id={null}
									addMeasureToPhase={(measureId, phase_id, targetIndex) =>
										addMeasureToPhase(measureId, phase_id, targetIndex)
									}
								/>
							)}
						</div>
					))}
			</div>
			<div>
				{phases
					.sort((a, b) => a.id - b.id)
					.map((phase, index) => (
						<Phase
							key={phase.id}
							{...phase}
							addMeasureToPhase={(
								measureId: string,
								phase_id: number,
								targetIndex: number
							) => addMeasureToPhase(measureId, phase_id, targetIndex)}
						/>
					))}
			</div>
			{phasesPreset.length === 0 && (
				<button
					onClick={addPhase}
					style={{
						marginLeft: "20px",
						padding: "10px 20px",
						backgroundColor: "#4CAF50",
						color: "#fff",
						border: "none",
						borderRadius: "5px",
						cursor: "pointer",
					}}
				>
					Fase toevoegen
				</button>
			)}
		</div>
	);
};

export default MeasureManagement;
