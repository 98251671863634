import React, {useState, useEffect} from "react";
import {styles} from "../../components/utils/Styles";
import DisplayTable from "../../components/data-linking-specs";
import {
	fetchDocData,
	fetchDocsData,
	mergeDoc,
} from "../../components/utils/firebaseFunctions";
import Select from "react-select";
import {set} from "lodash";

interface Option {
	id: string;
	name: string;
	name_clean?: string;
}

interface Measure {
	id: string;
	name: string;
	name_clean: string;
}

interface TableData {
	costs: Option[];
	facts: Option[];
	client_inputs: Option[];
}
interface searchOption {
	label: string;
	value: string;
}

const Test: React.FC = () => {
	const [measures, setMeasures] = useState<Measure[]>([]);
	const [selectedMeasureId, setSelectedMeasureId] = useState<string>("");
	const [tableData, setTableData] = useState<TableData | null>(null);
	const [costOptions, setCostOptions] = useState<Option[]>([]);
	const [factOptions, setFactOptions] = useState<Option[]>([]);
	const [clientInputOptions, setClientInputOptions] = useState<Option[]>([]);
	const [selectedCostIds, setSelectedCostIds] = useState<string[]>([]);
	const [selectedFactIds, setSelectedFactIds] = useState<string[]>([]);
	const [selectedClientInputIds, setSelectedClientInputIds] = useState<
		string[]
	>([]);
	const [selectedOption, setSelectedOption] = useState<searchOption | null>(
		null
	);

	useEffect(() => {
		const fetchMeasures = async () => {
			try {
				const measuresData: any = await fetchDocsData("energy_saving_measures");
				setMeasures(measuresData);
			} catch (error) {
				console.error("Error fetching measures: ", error);
			}
		};

		const fetchOptions = async (
			collectionName: string,
			setOptions: React.Dispatch<React.SetStateAction<Option[]>>
		) => {
			try {
				const options: any = await fetchDocsData(collectionName);
				setOptions(options);
			} catch (error) {
				console.error(`Error fetching ${collectionName}: `, error);
			}
		};

		fetchMeasures();
		fetchOptions("costs", setCostOptions);
		fetchOptions("facts", setFactOptions);
		fetchOptions("client_inputs", setClientInputOptions);
	}, [selectedMeasureId]);

	useEffect(() => {
		if (!selectedOption) return;
		else {
			if (costOptions.find((option) => option.id === selectedOption.value)) {
				setSelectedCostIds([selectedOption.value]);
			} else if (
				factOptions.find((option) => option.id === selectedOption.value)
			) {
				setSelectedFactIds([selectedOption.value]);
			} else if (
				clientInputOptions.find((option) => option.id === selectedOption.value)
			) {
				setSelectedClientInputIds([selectedOption.value]);
			}
		}
	}, [selectedOption]);

	useEffect(() => {
		if (selectedCostIds.length === 1) {
			handleAddToMeasure("costs");
		}
	}, [selectedCostIds]);

	useEffect(() => {
		if (selectedFactIds.length === 1) {
			handleAddToMeasure("facts");
		}
	}, [selectedFactIds]);

	useEffect(() => {
		if (selectedClientInputIds.length === 1) {
			handleAddToMeasure("client_inputs");
		}
	}, [selectedClientInputIds]);

	const handleAddToMeasure = async (type: string) => {
		try {
			if (!selectedMeasureId) {
				console.error("Selecteer een maatregel.");
				return;
			}

			let ids: string[] = [];
			switch (type) {
				case "costs":
					ids = selectedCostIds;
					break;
				case "facts":
					ids = selectedFactIds;
					break;
				case "client_inputs":
					ids = selectedClientInputIds;
					break;
				default:
					console.error("Invalid type.");
					return;
			}

			const docsPromises = ids.map(async (id) => {
				const docData = await fetchDocData(type, id);
				return {
					id,
					name: docData.name,
					name_clean: docData.name_clean,
				};
			});

			const documents = await Promise.all(docsPromises);
			const {
				costs = [],
				facts = [],
				client_inputs = [],
			} = await fetchDocData("energy_saving_measures", selectedMeasureId);

			switch (type) {
				case "costs":
					await mergeDoc("energy_saving_measures", selectedMeasureId, {
						[type]: [...costs, ...documents.filter(Boolean)],
					});
					break;
				case "facts":
					await mergeDoc("energy_saving_measures", selectedMeasureId, {
						[type]: [...facts, ...documents.filter(Boolean)],
					});
					break;
				case "client_inputs":
					await mergeDoc("energy_saving_measures", selectedMeasureId, {
						[type]: [...client_inputs, ...documents.filter(Boolean)],
					});
					break;
				default:
					break;
			}

			const {
				costs: updatedCosts = [],
				facts: updatedFacts = [],
				client_inputs: updatedClientInputs = [],
			} = await fetchDocData("energy_saving_measures", selectedMeasureId);
			const updatedCostIds = updatedCosts.map((cost: any) => cost.id);
			const updatedClientInputIds = updatedClientInputs.map(
				(input: any) => input.id
			);
			const updatedFactIds = updatedFacts.map((fact: any) => fact.id);

			const filteredCostOptions = costOptions.filter(
				(cost) => !updatedCostIds.includes(cost.id)
			);
			const filteredClientInputs = clientInputOptions.filter(
				(input) => !updatedClientInputIds.includes(input.id)
			);
			const filteredFacts = factOptions.filter(
				(fact) => !updatedFactIds.includes(fact.id)
			);

			setCostOptions(filteredCostOptions);
			setClientInputOptions(filteredClientInputs);
			setFactOptions(filteredFacts);
			setTableData({
				costs: updatedCosts,
				facts: updatedFacts,
				client_inputs: updatedClientInputs,
			});
			console.log(
				`${type.slice(0, -1).toUpperCase()} added to the measure successfully!`
			);
			setSelectedOption(null);
			setSelectedCostIds([]);
			setSelectedFactIds([]);
			setSelectedClientInputIds([]);
		} catch (error) {
			console.error(
				`Error adding ${type.slice(0, -1).toUpperCase()} to the measure: `,
				error
			);
		}
	};

	useEffect(() => {
		const fetchTableData = async () => {
			if (!selectedMeasureId) return;
			try {
				const {
					costs = [],
					facts = [],
					client_inputs = [],
				} = (await fetchDocData("energy_saving_measures", selectedMeasureId)) ||
				{};
				setTableData({costs, facts, client_inputs});
			} catch (error) {
				console.error("Error fetching data: ", error);
			}
		};

		fetchTableData();
	}, [selectedMeasureId]);

	// const handleSelection = (
	// 	event: React.ChangeEvent<HTMLSelectElement>,
	// 	setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
	// ) => {
	// 	const selectedIds = Array.from(
	// 		event.target.selectedOptions,
	// 		(option) => option.value
	// 	);
	// 	setSelectedIds(selectedIds);
	// };

	return (
		<div
			style={{display: "flex", backgroundColor: "#f0f0f0", minWidth: "90vw"}}
		>
			<div>
				<div
					style={{padding: "20px", display: "flex", flexDirection: "column"}}
				>
					{/* Selectable list of measures */}
					<div style={{marginBottom: "10px"}}>
						<select
							value={selectedMeasureId}
							onChange={(e) => setSelectedMeasureId(e.target.value)}
							style={styles.input}
						>
							<option value="" disabled>
								Select a measure
							</option>
							{measures.map((measure) => (
								<option key={measure.id} value={measure.id}>
									{measure.name}
								</option>
							))}
						</select>
					</div>
					{selectedMeasureId && (
						<div style={{display: "flex"}}>
							{["costs", "facts", "client_inputs"].map((type, index) => (
								<div
									key={index}
									style={{
										marginRight: "10px",
										minWidth: "25vw",
										fontFamily: "karla",
									}}
								>
									<h3 style={{fontFamily: "basic"}}>
										{type === "client_inputs"
											? "Klantgegevens"
											: type === "costs"
											? "Kosten"
											: "Stamgegevens"}
									</h3>

									<Select
										value={selectedOption}
										options={(type === "costs"
											? costOptions
											: type === "facts"
											? factOptions
											: clientInputOptions
										).map((option) => ({
											label: option.name +" ("+option.name_clean+ ")",
											value: option.id,
										}))}
										onChange={setSelectedOption}
									/>
								</div>
							))}
						</div>
					)}
					{/* Display table */}
					{tableData && (
						<DisplayTable
							collectionName={"energy_saving_measures"}
							documentId={selectedMeasureId}
							data={tableData}
							setData={setTableData}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default Test;
